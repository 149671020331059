<div class="row">
	<div class="col-md-12">
		<div class="row">
			<div class="col-6 mt-3">
				<div class="card running pointer" (click)='goToReport("Running")'>
					<div class="clearfix">
						<h1 class="pull-left">{{data.start}}</h1>
						<img src="/assets/images/icons/dashboard-running-car.png" width="100" class="pull-right d-none d-md-block d-lg-block"/>
					</div>
					<div class="c-text">Running</div>
				</div>
			</div>
			<div class="col-6 mt-3">
				<div class="card idle pointer" (click)='goToReport("Idle")'>
					<div class="clearfix">
						<h1 class="pull-left">{{data.idle}}</h1>
						<img src="/assets/images/icons/dashboard-idle-car.png" width="100" class="pull-right d-none d-md-block d-lg-block"/>
					</div>
					<div class="c-text">Idle</div>
				</div>
			</div>
		</div>
	</div>
	<div class="col-md-12">
		<div class="row">
			<div class="col-6 mt-3">
				<div class="card stopped pointer" (click)='goToReport("Stop")'>
					<div class="clearfix">
						<h1 class="pull-left">{{data.stop}}</h1>
						<img src="/assets/images/icons/dashboard-stopped-car.png" width="100" class="pull-right d-none d-md-block d-lg-block"/>
					</div>
					<div class="c-text">Stopped</div>
				</div>
			</div>
			<div class="col-6 mt-3">
				<div class="card nodata pointer" (click)='goToReport("No Data")' >
					<div class="clearfix">
						<h1 class="pull-left">{{data.no_status}}</h1>
						<img src="/assets/images/icons/dashboard-nodata-car.png" width="100" class="pull-right d-none d-md-block d-lg-block"/>
					</div>
					<div class="c-text">No Data(This Month)</div>
				</div>
			</div>
		</div>
	</div>
	<div class="col-md-12">
			<div class="row">
				<div class="col-6 mt-3">
					<div class="card neverdata pointer" (click)='goToReport("Never Data")'>
						<div class="clearfix">
							<h1 class="pull-left">{{data.never_data}}</h1>
							<img src="/assets/images/icons/dashboard-neverdata-car.png" width="100" class="pull-right d-none d-md-block d-lg-block"/>
						</div>
						<div class="c-text">No Data(Previous Months)</div>
					</div>
				</div>
	</div>
</div>