<div class="vehicle-card">
	<div class="vehicle-card-header">
		<span class="vehicle-name">{{vehicle.vehicleDisplayName}}</span>
		<span class="vehicle-number badge badge-success">{{vehicle.vehicleNumber}}</span>
	</div>
	<div class="vehicle-card-body">
		<div>
			<small>
				{{vehicle.vehicleManfacturer+ ' - '+vehicle.vehicleModel}}
			</small>
		</div>
		<div *ngIf="vehicle.ll">
			<it-vehicle-live-data [ll]="vehicle.ll"></it-vehicle-live-data>
		</div>
		<div *ngIf="!vehicle.ll">
			<div *ngIf="vehicle.deviceId">
				
			</div>
			<div *ngIf="!vehicle.deviceId">
				
			</div>
		</div>
	</div>
</div>