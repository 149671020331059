
<div class="pac-card" id="pac-card" [hidden]="isLoading"  class="col-md-12">
	<div>
		<form 
		class="form-inline d-flex align-items-stretch mb-3 "
		[formGroup]="dayReportForm" (ngSubmit)="showReport()">
		<div class="mr-3">
			<label for="uniqueId">Vehicle</label>
			<select class="form-control form-control-sm" id="uniqueId" formControlName="uniqueId"  >
				<option *ngFor="let vehicle of vehicleList" [value]="vehicle.deviceId" >
					{{vehicle.vehicleDisplayName}}&nbsp;<span class="badge bade-default">{{vehicle.vehicleNumber}}</span>
				</option>
			</select>
			<span class="text-danger" [style.display]="'block'" [hidden]="dayReportForm.pristine ||  !(dayReportForm.controls.uniqueId.errors && dayReportForm.controls.uniqueId.errors.required)">This field is required</span>
		</div>
		<div class="mr-5">
			<label for="from">From</label>
			<p-calendar [showIcon]="true" formControlName="from" [minDate]="minFromDate" [maxDate]="maxFromDate" [monthNavigator]="true" [yearNavigator]="true" [yearRange]="yearRange" [showTime]="true" [hourFormat]="24"></p-calendar>
			<span class="text-danger" [hidden]="dayReportForm.pristine ||  !(dayReportForm.controls.from.errors && dayReportForm.controls.from.errors.inValidFromDate)">Invalid From Date</span>
		</div>
		<div class="mr-5">
			<label for="uniqueId">To</label>
			<p-calendar [showIcon]="true" formControlName="to" [maxDate]="maxToDate" [monthNavigator]="true" [yearNavigator]="true" [yearRange]="yearRange" [showTime]="true" [hourFormat]="24"></p-calendar>
			<span class="text-danger" [hidden]="dayReportForm.pristine ||  !(dayReportForm.controls.to.errors && dayReportForm.controls.to.errors.inValidToDate)">Invalid To Date</span>
		</div>
		<div class="mr-3 pt-3  mt-1">
			<button class="btn btn-sm btn-info"  [disabled]="dayReportForm.invalid" type="submit">Trip</button>
		</div>
	</form>
	
	</div>


	<div class="row">
		<div class="col-md-3">
			<div class="form-group">
				<div>Route Name</div>
				<input id="route-name" type="text" [(ngModel)]="routeName"
					class="form-control form-control-sm"
					placeholder="Enter Route Name" required>
				<div class="col-md-12 pad-0" *ngIf="!validateRouteName()">
					<span class="text-danger">* Please Provide Route Name</span>
				</div>
			</div>
			<div class="form-group">
				<div id="pac-container">
					<div id='pac-container_0'>
						<input id="pac-input_0" type="text"  [(ngModel)]="pacInput"
							class="form-control form-control-sm"
							placeholder="Enter a From location">
					</div>
				</div>
				<i *ngIf="isAdded && !(isCreateRouteFence)" class="fa fa-plus-circle add-route-icon"
					aria-hidden="true" (click)="addTextBox()">&nbsp;&nbsp;</i>
				<button *ngIf="(isAdded || !isCreateRouteFence) && place.length > 1" class="btn btn-outline-primary btn-sm" (click)="createGeo()" [disabled]="isCreateFenceLoading">Create Fence</button>
				<button *ngIf="isCreateRouteFence" class="btn btn-outline-primary btn-sm" (click)="reset()">Reset</button>
				<button *ngIf="isCreateRouteFence" class="btn btn-outline-primary btn-sm" (click)="saved()">Save</button>
			</div>
		</div>
		<div class="col-md-9">
			<div id="map" class="show-history-dirty-google-map"></div>
		</div>
	</div>
</div>

<div class="load-container" *ngIf="isLoading">
	<div class="pin">
		<div class="loader fa-spin"></div>
	</div>
</div>