<div class="row">
	<div class="col-sm-12">
		<select class="form-control form-control-sm mb-3 mobile-tab-nav" [(ngModel)]="activeTabKey">
			<option value="day-report">Day Report</option>
			<option value="stop-report">Stop Report</option>
			<option value="alerts-report">Alert Report</option>
			<option value="daily-summary-report">Day Summary Report</option>
			<option value="monthly-summary-report" class="d-none d-sm-block">Monthly Summary Report</option>
			<option value="device-status-report" class="d-none d-sm-block">Device Report</option>
			<option value="billing-report" class="d-none d-sm-block">Billing Report</option>
			<option value="share-trip-report" class="d-none d-sm-block">Shared Trip Report</option>
			<option value="device-summary-report" class="d-sm-block">Device Summary Report</option>
			<option value="device-allocation-report" class="d-none d-sm-block">Device Allocation Report</option>
			<option value="vehicle-status-report" class="d-none d-sm-block">Vehicle Status Report</option>
			<option value="driver-day-report" class="d-none d-sm-block">Driver Day Report</option>
		</select>
		<ul class="nav nav-pills mb-3 report-navigation" id="pills-tab" role="tablist">
			<li class="nav-item" (click)="activeTabKey = 'day-report'" *appShowAuthed="'DAY_REPORT'">
				<a class="nav-link"  [ngClass]="{'active':(activeTabKey == 'day-report')}"
					id="pills-home-tab" data-toggle="pill" role="tab"
					aria-controls="pills-home" aria-selected="true">Day Report</a>
			</li>
			<li class="nav-item" (click)="activeTabKey = 'attendance-report'" *appShowAuthed="'ATTENDANCE_REPORT'">
				<a class="nav-link"  [ngClass]="{'active':(activeTabKey == 'attendance-report')}"
					id="pills-home-tab" data-toggle="pill" role="tab"
					aria-controls="pills-home" aria-selected="true">Attendance Report</a>
			</li>
			<li class="nav-item" (click)="activeTabKey = 'stop-report'" *appShowAuthed="'STOPS_REPORT'">
				<a class="nav-link" id="pills-profile-tab" [ngClass]="{'active':(activeTabKey == 'stop-report')}"
					data-toggle="pill" role="tab"
					aria-controls="pills-profile" aria-selected="false">Stop Report
				</a>
			</li>
			<li class="nav-item" (click)="activeTabKey = 'alerts-report'" *appShowAuthed="'ALERTS_REPORT'">
				<a class="nav-link" id="pills-profile-tab" [ngClass]="{'active':(activeTabKey == 'alerts-report')}"
					data-toggle="pill" role="tab"
					aria-controls="pills-profile" aria-selected="false">Alert Report</a>
			</li>
			<li class="nav-item" (click)="activeTabKey = 'monthly-summary-report'" *appShowAuthed="'MONTHLY_SUMMARY_REPORT'">
				<a class="nav-link" id="pills-profile-tab" [ngClass]="{'active':(activeTabKey == 'monthly-summary-report')}"
					data-toggle="pill" role="tab"
					aria-controls="pills-profile" aria-selected="false">Monthly Summary Report</a>
			</li>
			<li class="nav-item" (click)="activeTabKey = 'device-status-report'" *appShowAuthed="'DEVICE_REPORT'">
				<a class="nav-link" id="pills-profile-tab" [ngClass]="{'active':(activeTabKey == 'device-status-report')}"
					data-toggle="pill" role="tab"
					aria-controls="pills-profile" aria-selected="false">Device  Report</a>
			</li>				
<!-- 			<li class="nav-item" (click)="activeTabKey = 'billing-report'" >
				<a class="nav-link" id="pills-profile-tab" [ngClass]="{'active':(activeTabKey == 'billing-report')}"
					data-toggle="pill" role="tab"
					aria-controls="pills-profile" aria-selected="false">Billing Report</a>
			</li> -->	
			<li class="nav-item" (click)="activeTabKey = 'share-trip-report'" *appShowAuthed="'SHARE_TRIP_REPORT'">
				<a class="nav-link" id="pills-profile-tab" [ngClass]="{'active':(activeTabKey == 'share-trip-report')}"
					data-toggle="pill" role="tab"
					aria-controls="pills-profile" aria-selected="false">Shared Trip Report</a>
			</li>	
			<li class="nav-item" (click)="activeTabKey = 'device-summary-report'" *appShowAuthed="'DEVICE_SUMMARY_REPORT'">
				<a class="nav-link" id="pills-profile-tab" [ngClass]="{'active':(activeTabKey == 'device-summary-report')}"
					data-toggle="pill" role="tab"
					aria-controls="pills-profile" aria-selected="false">Device Summary Report</a>
			</li>
			<li class="nav-item" (click)="activeTabKey = 'device-allocation-report'" *appShowAuthed="'DEVICE_ALLOCATION_REPORT'">
				<a class="nav-link" id="pills-profile-tab" [ngClass]="{'active':(activeTabKey == 'device-allocation-report')}"
					data-toggle="pill" role="tab"
					aria-controls="pills-profile" aria-selected="false">Device Allocation Report</a>
			</li>
 			<li class="nav-item" (click)="activeTabKey = 'vehicle-status-report'" *appShowAuthed="'VEHICLE_STATUS_REPORT'">
				<a class="nav-link" id="pills-profile-tab" [ngClass]="{'active':(activeTabKey == 'vehicle-status-report')}"
					data-toggle="pill" role="tab"
					aria-controls="pills-profile" aria-selected="false">Vehicle Status Report</a>
			</li> 
			<li class="dropdown" >
				<a
					class="nav-link dropdown-toggle" itDropDown> 
					<i
						class="fa fa-bars" aria-hidden="true">
					</i> More..
				</a>
				<div class="dropdown-menu options-dropdown">
					
					<a class="nav-item nav-link" (click)="activeTabKey = 'billing-report'" id="pills-profile-tab"
							[ngClass]="{'active':(activeTabKey == 'billing-report')}"
							data-toggle="pill"  aria-controls="pills-profile"
							aria-selected="false" *appShowAuthed="'BILLING_REPORT'">
							Billing Report
					</a>
					
					 <a class="nav-item nav-link" (click)="activeTabKey = 'daily-summary-report'" id="pills-profile-tab"
							[ngClass]="{'active':(activeTabKey == 'daily-summary-report')}"
							data-toggle="pill" aria-controls="pills-profile"
							aria-selected="false" *appShowAuthed="'DAILY_SUMMARY_REPORT'">
							Day Summary Report
					</a>
					
					 <a class="nav-item nav-link" (click)="activeTabKey = 'driver-day-report'" id="pills-profile-tab"
							[ngClass]="{'active':(activeTabKey == 'driver-day-report')}"
							data-toggle="pill" aria-controls="pills-profile"
							aria-selected="false" *appShowAuthed="'DRIVER_DAY_REPORT'">
							Driver Day Report
					</a>
					<a class="nav-item nav-link" (click)="activeTabKey = 'bus-report'" id="pills-profile-tab"
							[ngClass]="{'active':(activeTabKey == 'bus-report')}"
							data-toggle="pill" aria-controls="pills-profile"
							aria-selected="false" *appShowAuthed="'DAILY_BUS_REPORT'">
							Daily Bus Attendance report
					</a>
					<a class="nav-item nav-link" (click)="activeTabKey = 'daily-bus-report'" id="pills-profile-tab"
							[ngClass]="{'active':(activeTabKey == 'daily-bus-report')}"
							data-toggle="pill" aria-controls="pills-profile"
							aria-selected="false" *appShowAuthed="'BUS_REPORT'">
							Bus Attendance report
					</a>
				</div>
			</li>
		</ul>
		
	</div>
	<div class="col-sm-12">
		<div *ngIf="(activeTabKey == 'day-report')">
			<it-day-report-dashboard></it-day-report-dashboard>
		</div>
		<div *ngIf="(activeTabKey == 'attendance-report')">
			<it-attendance-report-dashboard></it-attendance-report-dashboard>
		</div>
		<div *ngIf="(activeTabKey == 'stop-report')">
			<it-stops-report-dashboard></it-stops-report-dashboard>
		</div>
		<div *ngIf="(activeTabKey == 'alerts-report')">
			<it-alerts-report></it-alerts-report>
		</div>
		<div *ngIf="(activeTabKey == 'monthly-summary-report')">
			<it-monthly-summary-report></it-monthly-summary-report>
		</div>
		<div *ngIf="(activeTabKey == 'device-status-report')">
			<it-device-status-report></it-device-status-report>
		</div>
		<div *ngIf="(activeTabKey == 'billing-report')">
			<it-billing-report></it-billing-report>
		</div>
		<div *ngIf="(activeTabKey == 'share-trip-report')">
			<it-share-trip-report></it-share-trip-report>
		</div>
		<div *ngIf="(activeTabKey == 'device-summary-report')">
			<it-device-summary-report></it-device-summary-report>
		</div>
		<div *ngIf="(activeTabKey == 'device-allocation-report')">
			<it-device-allocation-report></it-device-allocation-report>
		</div>
		<div *ngIf="(activeTabKey == 'vehicle-status-report')">
			<it-vehicle-status-report></it-vehicle-status-report>
		</div>
		<div *ngIf="(activeTabKey == 'daily-summary-report')">
			<it-daily-summary-report></it-daily-summary-report>
		</div>
		<div *ngIf="(activeTabKey == 'driver-day-report')">
			<it-driver-day-report-dashboard></it-driver-day-report-dashboard>
		</div>
		<div *ngIf="(activeTabKey == 'daily-bus-report')">
			<it-bus-report-dashboard></it-bus-report-dashboard>
		</div>
		<div *ngIf="(activeTabKey == 'bus-report')">
			<it-daily-bus-report-dashboard></it-daily-bus-report-dashboard>
		</div>
	</div>
</div>