<div class="row">
	<div class="col-md-12">
		<ul class="nav nav-pills">
			<li class="nav-item" *appShowAuthed="'VEHICLE_SHIFT'"><a
				class="nav-link" (click)="activeTab = 'transfer-devices'"
				[ngClass]="{'active':(activeTab == 'transfer-devices')}">
					Vehicle Shift </a></li>
			<li class="nav-item nomobile" *appShowAuthed="'BULK_UPLOAD'"><a
				class="nav-link" (click)="activeTab = 'upload-devices'"
				[ngClass]="{'active':(activeTab == 'upload-devices')}"> Upload
					Devices </a></li>
			<li class="nav-item" *appShowAuthed="'SHARE_VEHICLE'"><a
				class="nav-link" (click)="activeTab = 'share-vehicles'"
				[ngClass]="{'active':(activeTab == 'share-vehicles')}"> Share
					Vehicle </a></li>
			<li class="nav-item" *appShowAuthed="'SHARE_ROUTE'"><a
				class="nav-link" (click)="activeTab = 'share-route'"
				[ngClass]="{'active':(activeTab == 'share-route')}"> Share Route
			</a></li>
			<li class="nav-item" *appShowAuthed="'UNSHARE_VEHICLE'"><a
				class="nav-link" (click)="activeTab = 'unshare-vehicles'"
				[ngClass]="{'active':(activeTab == 'unshare-vehicles')}">UnShare Vehicle
			</a></li>
			<li class="nav-item" *appShowAuthed="'BULK_REGISTRATION'"><a
				class="nav-link" (click)="activeTab = 'bulk-user-registration'"
				[ngClass]="{'active':(activeTab == 'bulk-user-registration')}"> Bulk User Registration
			</a></li>
		</ul>
	</div>
	<div class="col-md-12 mt-3">
		<div class="card" [ngSwitch]="activeTab">
			<it-upload-devices *ngSwitchCase="'upload-devices'"></it-upload-devices>
			<it-transfer-device *ngSwitchCase="'transfer-devices'"></it-transfer-device>
			<it-share-vehicles *ngSwitchCase="'share-vehicles'"></it-share-vehicles>
			<it-share-route *ngSwitchCase="'share-route'"></it-share-route>
			<it-unshare-vehicles *ngSwitchCase="'unshare-vehicles'"></it-unshare-vehicles>
			<it-bulk-user-registration *ngSwitchCase="'bulk-user-registration'"></it-bulk-user-registration>
		</div>
	</div>
</div>
