<div class="load-container" *ngIf="isLoading">
	<div class="pin">
		<div class="loader fa-spin"></div>
	</div>
</div>

<div class="col-sm-12" *ngIf="!isLoading">
	<ul class="nav nav-pills justify-content-center">
		<li class="nav-item" *appShowAuthed="'FETCH_USER_PROFILE'"><a
			[ngClass]="{ 'active' : showProfileSection}" class="nav-link "
			(click)="toggleProfileWindow()" href="javascript:void(0)"> My
				Profile </a></li>
		<li class="nav-item" *appShowAuthed="'CHANGE_PASSWORD'"><a
			[ngClass]="{'active' : showUpdatePasswordSection}" class="nav-link"
			(click)="toggleChangeWindow()" href="javascript:void(0)"> Update
				Password </a></li>

	</ul>
	<ng-container *appShowAuthed="'FETCH_USER_PROFILE'">
	 <div *ngIf="showProfileSection" >
		<form *ngIf="showProfileSection" id="myProfile" name="myprofile"
			(ngSubmit)="onSubmit(myprofile)" [formGroup]="myprofile">
			<div class="col-sm-12 text-center">
				<div class="image-container mt-4">
					<img [src]="imagepath" />
				</div>
				<it-profile-image (onUpload)="updateImage($event)" class="nomobile"></it-profile-image>
			</div>
			<div class="form-group">
				<label for="name">Name</label>
				<div class="input-group input-group-sm">
					<span class="input-group-addon"> <i class="fa fa-user"></i>
					</span> <input type="text" class="form-control"
						formControlName="firstname">
				</div>
				<span
					[hidden]="myprofile.pristine ||  myprofile.controls.firstname.valid"
					class="text-danger"> <i class="fa fa-exclamation-triangle"></i>&nbsp;Please
					enter your name. You can not enter more than 25 characters for your
					name, if your want to enter a longer name you can use initials e.g.
					W H Peters
				</span>
			</div>
			<div class="form-group">
				<label for="email">Email</label>
				<div class="input-group input-group-sm">
					<span class="input-group-addon"> <i class="fa fa-envelope"></i>

					</span> <span class="form-control">{{userProfile.email}}</span>
				</div>
			</div>
			<div class="form-group">
				<label for="contact">Contact</label>

				<div class="input-group input-group-sm">
					<span class="input-group-addon"> <i class="fa fa-phone"></i>
					</span> <span class="form-control">{{userProfile.mobile}}</span>
				</div>
			</div>
			<div class="form-group">
				<label for="contact">Company Name</label>

				<div class="input-group input-group-sm">
					<span class="input-group-addon"> <i class="fa fa-building"></i>
					</span> <span class="form-control">{{userProfile.companyName}}</span>
				</div>
			</div>
			<div class="">
			
			</div>
			<button type="submit" [disabled]="myprofile.invalid || isSubmitting"
				class="pull-right btn btn-outline-primary btn-sm">
				<i class="fa fa-spin fa-circle-o-notch" [hidden]="!isSubmitting"></i>
				Update
			</button>
		</form>
	</div>
	</ng-container>
	<ng-container *appShowAuthed="'CHANGE_PASSWORD'">
	 <div *ngIf="showUpdatePasswordSection" >
			<form [formGroup]="updatePasswordForm" class="mt-3" (ngSubmit)="onSubmitpass(updatePasswordForm)">
				<div class="form-group">
					<label class="control-label">New Password</label>
					<input class="form-control" type="password" formControlName="newPassword" placeholder="New Password">
					<span class="text-danger" *ngIf="updatePasswordForm.controls.newPassword.hasError('pattern')">Password
					not satisfying policy</span>
				</div>
				<div class="form-group">
					<label class="control-label mt-2">Confirm
						Password</label>
					<input class="form-control" type="password"
						formControlName="confirmPassword" placeholder="Confirm password">
				</div>
				<div class="justify-content-end d-flex">
					<button [disabled]="updatePasswordForm.invalid" class="btn btn-outline-primary btn-sm" type="submit">
						Update
					</button>
				</div>
				
			</form>
	</div>
	</ng-container>

</div>
