<div class="card" *ngIf="!isLoading">

   <form class="form-inline d-flex align-items-stretch mb-3" [formGroup]="routeDashboardForm" (ngSubmit)="showReport(routeDashboardForm)">
        <div class="mr-3">
            <label for="vehicleId" class="form-label">Vehicle</label>
            <select class="form-control form-control-sm" id="vehicleId" formControlName="vehicleId"  (ngModelChange)="onVehicleChange($event)">
            	<option value="">Select</option>
                <option *ngFor="let vehicle of vehicleList" [value]="vehicle.vehicleId" >
                    {{vehicle.vehicleDisplayName}}&nbsp;<span class="badge bade-default">{{vehicle.vehicleNumber}}</span>
                </option>
            </select>
            <span class="text-danger" [style.display]="'block'" [hidden]="routeDashboardForm.pristine ||  !(routeDashboardForm.controls.vehicleId.errors && routeDashboardForm.controls.vehicleId.errors.required)">This field is required</span>
        </div>
        <div class="mr-3" *ngIf="!isRouteLoading">
            <label for="routeId" class="form-label">Route</label>
            <select multiple  class="form-control form-control-sm" id="routeId" formControlName="routeId" [value]="routeName" >
                <option *ngFor="let route of routeList" [value]="route.routeId" >
                    {{route.routeName}}
                </option>
            </select>
        </div>
        <div class="mr-3">
				<label  class="form-label">Shift</label> 
				<select class="form-control form-control-sm"
					formControlName="shift">
					<option value="">Select</option>
					<option value="morning">Morning</option>
                    <option value="evening">Evening</option>
                    <option value="Gen. Day Shift">Gen. Day Shift</option>
                    <option value="Gen. Night Shift">Gen. Night Shift</option>
                    <option value="A Shift">A Shift</option>
                    <option value="B Shift">B Shift</option>
                    <option value="C Shift">C Shift</option>
				</select>
		</div>
        <div class="mr-5">
            <label for="from" class="form-label">From</label>
            <p-calendar [showIcon]="true" formControlName="from" [maxDate]="maxFromDate" [monthNavigator]="true" [yearNavigator]="true" [yearRange]="yearRange"></p-calendar>
            <span class="text-danger" [hidden]="routeDashboardForm.pristine ||  !(routeDashboardForm.controls.from.errors && routeDashboardForm.controls.from.errors.inValidFromDate)">Invalid From Date</span>
        </div>
        <div class="mr-3 pt-3  mt-1">
            <button class="btn btn-sm btn-info"  [disabled]="routeDashboardForm.invalid" type="submit">Submit</button>
        </div>
    </form>

    <it-route-dashboard-list
        class="mt-2"
        (page)="pageChange($event)"
        [routeId]="attendanceReportFilter.routeId"
        [fromDate]="attendanceReportFilter.from"
        [vehicle]="attendanceReportFilter.vehicle"
        [shift]="attendanceReportFilter.shift"
        *ngIf="reportVisible">
    </it-route-dashboard-list> 

    <h5 class="text-center mt-4 no-data" *ngIf="!reportVisible">Please select a vehicle/route to view route</h5>
</div>
<div class="load-container" *ngIf="isLoading">
    <div class="pin">
        <div class="loader fa-spin"></div>
    </div>
</div>
