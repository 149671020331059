<div>
    <div *ngIf="!noDataFound">
    	<div>
	        <div *ngIf="!isLoading">
	            <table class="table table-sm mt-3 table-responsive" datatable [dtOptions]="dtOptions">
	                <thead>
	                    <tr>
	                        <th>Vehicle Name</th>
	                        <th>Vehicle Registration number</th>
	                        <th>Idle Time</th>
	                        <th>Stopped time</th>
	                        <th>Duration/ Total Time</th>
	                        <th>Average speed(km/h)</th>
	                        <th>Distance</th>
	                    </tr>
	                </thead>
	                <tbody>
	                    <tr *ngFor="let summaryReport of summaryReportListAsync ;let i=index;" [hidden]="isLoading">
	                        <td>{{summaryReport.name | emptyToDash}}</td>
	                        <td>{{summaryReport.regNumber | emptyToDash}}</td>
	                        <td>{{convertMS(summaryReport.idletime) | emptyToDash}}</td>
	                        <td>{{convertMS(summaryReport.stoptime)}}</td>
	                        <td>{{convertMS(summaryReport.totaltime) | emptyToDash}}</td>
	                        <td>{{summaryReport.avgSpeed | emptyToDash}} km/h </td>
	                        <td>{{summaryReport.distanceinkms | number : '1.2-2' | emptyToDash}} Km</td>
	                    </tr>
	                </tbody>
	            </table>
	        </div>
	        <div class="load-container" *ngIf="isLoading">
	            <div class="pin">
	                <div class="loader fa-spin"></div>
	            </div>
	        </div>
        </div>
    </div>
    <div *ngIf="noDataFound" class="mt-10">
        <h5 class="text-center no-data">No data available</h5>
    </div>
</div>