<div>
    <div *ngIf="!noDataFound">
    	<div>
	        <div *ngIf="!isLoading">
	            <table class="table table-sm mt-3 table-responsive" datatable [dtOptions]="dtOptions">
	                <thead>
	                    <tr>
	                        <th>Device Name</th>
	                        <th>IMEI Number</th>
	                        <th>Vehicle Name</th>
	                        <th>User Name</th>
	                        <th>Installation Date</th>
	                        <th>Sim Number</th>
	                        <th>Last Data Received</th>
	                    </tr>
	                </thead>
	                <tbody>
	                    <tr *ngFor="let summaryReport of summaryReportListAsync;let i=index;" [hidden]="isLoading">
	                        <td data-label="Device Name">{{summaryReport.deviceName | emptyToDash}}</td>
	                        <td data-label="Imei Number">{{summaryReport.imei | emptyToDash}}</td>
	                        <td data-label="Vehicle Name">{{summaryReport.vehicleDisplayName | emptyToDash}}</td>
   	                        <td data-label="User Name">{{summaryReport.userName | emptyToDash}}</td>
   	                        <td data-label="Installation Date">{{summaryReport.installationDate | date:'MMM,dd yyyy' | emptyToDash}}</td>
   	                        <td data-label="Sim Number">{{summaryReport.simNumber | emptyToDash}}</td>
   	                        <td data-label="Last Data Received">{{summaryReport.lastDataRecived | date:'hh:mm:ss a MMM,dd yyyy' | emptyToDash}}</td>
	                        
	                    </tr>
	                </tbody>
	            </table>
	            <!-- <pagination-controls (pageChange)="getPage($event)" id="summaryReportList"></pagination-controls> -->
	        </div>
	        <div class="load-container" *ngIf="isLoading">
	            <div class="pin">
	                <div class="loader fa-spin"></div>
	            </div>
	        </div>
        </div>
    </div>
    <div *ngIf="noDataFound" class="mt-10">
        <h5 class="text-center no-data">No data available</h5>
    </div>
</div>