<div class="">
	<div class="load-container" *ngIf="isLoading && !isError">
		<div class="pin">
			<div class="loader fa-spin"></div>
		</div>
	</div>
	<h4 class="text-center text-warning" *ngIf="isError">
		No Data <br /> <img
			src="assets/images/icons/cloud-computing-error.png" />
	</h4>
	<div *ngIf="!isLoading && !isError">
		<div class="col-md-12">
			<h5>Edit Device Details</h5>
			<hr />
			<form (ngSubmit)="saveDevice(device)" [formGroup]="device"
				class="mt-2">
				<div class="form-group">
					<label>
						Device Name
					</label>
					<small class="text-danger pull-right" [hidden]="device.pristine || !(device.controls.name.errors && device.controls.name.errors.required)">*Required</small>
					<input type="text" class="form-control form-control-sm" formControlName="name">
					<span class="form-text text-danger" *ngIf="device.controls.name.errors && device.controls.name.errors.pattern "><i class="fa fa-exclamation-triangle" aria-hidden="true"></i>&nbsp;Please enter valid name</span>
				</div>
				<div class="form-group">
					<label>
						Device Type
					</label>
					<small class="text-danger pull-right" [hidden]="device.pristine || !(device.controls.equipmentType.errors && device.controls.equipmentType.errors.required)">*Required</small>
					<select class="form-control form-control-sm"  formControlName="equipmentType">
						<option value="">Select</option>
						<option value="mobile">Mobile</option>
						<option value="device">Device</option>
						<option value="bus">Bus</option>
						<option value="truck">Truck</option>
					</select>
					
				</div>
				<div class="form-group">
					<label>
						Protocol
					</label>
					<small class="text-danger pull-right" [hidden]="device.pristine || !(device.controls.model.errors && device.controls.model.errors.required)">*Required</small>
					<select class="form-control form-control-sm"  formControlName="model">
						<option value="">Select</option>
						<option value="gt06">GT 06</option>
						<option value="teltonika">Teltonika</option>
						<option value="herovts">Hero VTS</option>
					</select>
				</div>
				<div class="form-group">
					<label>
						SIM Vendor Name
					</label>
					<small class="text-danger pull-right" [hidden]="device.pristine || !(device.controls.vendorName.errors && device.controls.vendorName.errors.required)">*Required</small>
					<select class="form-control form-control-sm"  formControlName="vendorName">
						<option value="">Select</option>
						<option value="airtel">Airtel</option>
						<option value="vodafone">Vodafone</option>
						<option value="idea">Idea</option>
						<option value="reliance">Reliance</option>
						<option value="reliancejio">Reliance Jio</option>
						<option value="telenor">Telenor</option>
						<option value="tatadocomo">TATA DoCoMo</option>
						<option value="mtnl">MTNL</option>
						<option value="bsnl">BSNL</option>
						<option value="mts">MTS</option>
					</select>
				</div>
				<div class="form-group">
					<label>
						IMEI Number
					</label>
					<small class="text-danger pull-right" [hidden]="device.pristine || !(device.controls.imei.errors && device.controls.imei.errors.required)">*Required</small>
					<input type="text" class="form-control form-control-sm"  formControlName="imei">
					<span class="form-text text-danger" *ngIf="device.controls.imei.errors && device.controls.imei.errors.pattern"><i class="fa fa-exclamation-triangle" aria-hidden="true"></i>&nbsp;Please enter valid IMEI number</span>
				</div>
				<div class="form-group">
					<label>
						Sim Number
					</label>
					<small class="text-danger pull-right" [hidden]="device.pristine || !(device.controls.simNumber.errors && device.controls.simNumber.errors.required)">*Required</small>
					<input type="text" class="form-control form-control-sm"  formControlName="simNumber">
					<span class="form-text text-danger" *ngIf="device.controls.simNumber.errors && device.controls.simNumber.errors.pattern"><i class="fa fa-exclamation-triangle" aria-hidden="true"></i>&nbsp;Please enter valid SIM number</span>
				</div>
				<div class="form-group">
					<label>
						Mobile Number
					</label>
					<small class="text-danger pull-right" [hidden]="device.pristine || !(device.controls.mobileNumber.errors && device.controls.mobileNumber.errors.required)">*Required</small>
					<div class="input-group input-group-sm">
						<span class="input-group-addon p-0 noborder">
							<select class="form-control form-control-sm country-code" [style.width.%]="100" [(ngModel)]="countryCode" formControlName="code">
								<option value="+91">+91</option>
								<option value="+1">+1</option>
								<option value="+234">+234</option>
							</select>
						</span>
						<p-inputMask formControlName="mobileNumber" [style.width.%]="100" name="mobile" class="sign-up-mobile" mask="(999)-999-9999" [unmask]="true"></p-inputMask>
					</div>
					<span class="form-text text-danger" *ngIf="device.controls.mobileNumber.errors && device.controls.mobileNumber.errors.pattern"><i class="fa fa-exclamation-triangle" aria-hidden="true"></i>&nbsp;Please enter valid Mobile Number</span>
				</div>
				<div class="form-group" *appShowAuthed="'PUSH_LAST_LOCATION'">
					<label>
						Push Last Location
					</label>
					<select class="form-control form-control-sm"  formControlName="postLastLocation">
						<option value="">Select</option>
						<option value="redBus">RedBus</option>
						<option value="vibgyor">Vibgyor</option>
					</select>
				</div>
				 <div class="form-group">
          		 	 <label for="installationDate" class="form-label">Installation Date</label>
           			 <p-calendar [showIcon]="true" formControlName="installationDate"   [minDate]="minFromDate" [maxDate]="maxFromDate" [monthNavigator]="true" [yearNavigator]="true" [yearRange]="yearRange"></p-calendar>
       		 		<span class="text-danger" [hidden]="device.pristine ||  !(device.controls.installationDate.errors && device.controls.installationDate.errors.inValidFromDate)">Invalid Installation Date</span>
       		 	</div>
				<div class="form-group">
					<label>
						Description
					</label>
					<textarea class="form-control form-control-sm"  formControlName="description">
					</textarea>
					<span class="form-text text-danger" *ngIf="device.controls.description.errors && device.controls.description.errors.pattern"><i class="fa fa-exclamation-triangle" aria-hidden="true"></i>&nbsp;Please enter valid description</span>
				</div>
				<button type="submit" class="btn btn-sm btn-info" [disabled]="isSubmitting || device.invalid || device.pristine">
					<i class="fa fa-spin fa-circle-o-notch" [hidden]="!isSubmitting"></i>
					Update
				</button>
			</form>
		</div>
	</div>
</div>
