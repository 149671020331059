<div class="container">
	<div class="row">
		<div class="col-md-6 offset-md-3">
			<div class="nosession-view">
				<div class="row">
					<div class="col-md-12">
						<h1 class="frown">
							:(
						</h1>
						<h1>
							Oops <br/>
							<small>This session is terminated due to inactivity.</small>
						</h1>	
					</div>
					<div class="col-md-12 login-text">
						You can click <a routerLink="/app/dashboard">here</a> to go to home.
					</div>
				</div>
			</div>
		</div>
	</div>
	<style>
		body{
		    background: #e6e6e6;
		}
	</style>
</div>