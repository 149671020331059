<div class="card" *ngIf="!isLoading">
	<form class="form-inline d-flex align-items-stretch mb-3 "
		[formGroup]="busReportForm" (ngSubmit)="showReport(busReportForm)">
		<!-- <div class="mr-3">
			<label for="uniqueId">Vehicle</label>
			<select class="form-control form-control-sm" id="uniqueId" formControlName="uniqueId" [value]="uniqueId" (change)="fetchVehicleRoutes($event)">
				<option *ngFor="let vehicle of vehicleList" [value]="vehicle.vehicleId" >
					{{vehicle.vehicleDisplayName}}&nbsp;<span class="badge bade-default">{{vehicle.vehicleNumber}}</span>
				</option>
			</select>
			<span class="text-danger" [style.display]="'block'" [hidden]="busReportForm.pristine ||  !(busReportForm.controls.uniqueId.errors && busReportForm.controls.uniqueId.errors.required)">This field is required</span>
		</div>
		<div class="mr-3">
            <label for="uniqueId" class="form-label">Route</label>
            <select class="form-control form-control-sm" id="vehicleNumber" formControlName="routeName" [value]="routeName" >
                <option *ngFor="let route of routeList" [value]="route.routeName" >
                    {{route.routeName}}
                </option>
            </select>
        </div> -->
		<div class="mr-5">
			<label for="from">Date</label>
			<p-calendar [showIcon]="true" formControlName="from"  [monthNavigator]="true" [yearNavigator]="true" [yearRange]="yearRange"></p-calendar>
			<span class="text-danger" [hidden]="busReportForm.pristine ||  !(busReportForm.controls.from.errors && busReportForm.controls.from.errors.inValidFromDate)">Invalid From Date</span>
		</div>
		<!-- <div class="mr-5">
			<label for="uniqueId">To</label>
			<p-calendar [showIcon]="true" formControlName="to" [maxDate]="maxToDate" [monthNavigator]="true" [yearNavigator]="true" [yearRange]="yearRange"></p-calendar>
			<span class="text-danger" [hidden]="busReportForm.pristine ||  !(busReportForm.controls.to.errors && busReportForm.controls.to.errors.inValidToDate)">Invalid To Date</span>
		</div> -->
		<div class="mr-3 pt-3  mt-1">
			<button class="btn btn-sm btn-info"  [disabled]="busReportForm.invalid" type="submit">Submit</button>
		</div>
	</form>
	<div [hidden]="!viewMap" class="map-overlay text-center">
			<button type="button" class="btn btn-primary btn-sm pull-left" *ngIf="viewMap" (click)="viewReport()">
				<i class="fa fa-chevron-left" aria-hidden="true"></i>&nbsp;<span class="nomobile">Back to Reports</span>
			</button>
			<span class="pull-right replay-controls" [hidden]="microLoader">
				<span class="btn btn-primary btn-sm nomobile" [style.width.px]="100" *ngIf="showOtherParameters">Time: {{tripData[index]['time'] | date : 'HH:mm:ss'}}</span>
				<span class="btn btn-sm onlymobile" [style.width.px]="100" *ngIf="showOtherParameters">Time: {{tripData[index]['time'] | date : 'HH:mm:ss'}}</span>
				<span class="btn btn-primary btn-sm nomobile" [style.width.px]="118" *ngIf="showOtherParameters">Speed: {{tripData[index]['speed'] | kontsToKmph | number:'1.0-2'}}&nbsp;km/hr</span>
				<span class="btn btn-sm onlymobile" [style.width.px]="118" *ngIf="showOtherParameters">Speed: {{tripData[index]['speed'] | number:'1.0-2'}}&nbsp;km/hr</span>
				<span class="btn btn-primary btn-sm nomobile" [style.width.px]="133" *ngIf="showOtherParameters">Distance: {{distance | number:'1.0-2'}}&nbsp;km</span>
				<span class="btn btn-sm onlymobile" [style.width.px]="133" *ngIf="showOtherParameters">Distance: {{distance | number:'1.0-2'}}&nbsp;km</span>
				<button class="btn btn-primary btn-sm" (click)="setSpeed(1)" [ngClass]="{'active':(carSpeed == 1)}" [disabled]="!playing">1x</button>
				<button class="btn btn-primary btn-sm" (click)="setSpeed(4)" [ngClass]="{'active':(carSpeed == 4)}" [disabled]="!playing">4x</button>
				<button class="btn btn-primary btn-sm mr-2" (click)="setSpeed(8)" [ngClass]="{'active':(carSpeed == 8)}" [disabled]="!playing">8x</button>
				<button class="btn btn-sm btn-outline-primary" (click)="playTrip()" [disabled]="playing">
					<i class="fa fa-play-circle" aria-hidden="true"></i> <span class="nomobile">Play</span>
				</button>
				<button class="btn btn-sm btn-outline-warning" (click)="pause()"  [disabled]="!playing">
					<i class="fa fa-pause-circle" aria-hidden="true"></i> <span class="nomobile">Pause</span>
				</button>
				<button class="btn btn-sm btn-outline-danger" (click)="stop()" [disabled]="! (playing || paused)">
					<i class="fa fa-stop-circle" aria-hidden="true"></i> <span class="nomobile">Stop</span>
				</button>
			</span>
			<span class="load-wrapper" *ngIf="microLoader">
				<div class="load-container">
					<div class="pin">
						<div class="loader fa-spin"></div>
					</div>
				</div>
			</span>
			<div id="map" class="show-history-dirty-google-map"></div>
		</div>
	<div>
		<it-bus-report-list class="mt-2" (page)="pageChange($event)"
			(currentReport)="currentReport($event)"
			 [fromDate]="busReportFilter.from"
			*ngIf="reportVisible"> </it-bus-report-list> 

		<h5 class="text-center mt-4 no-data" *ngIf="!reportVisible">Please select a route to view report</h5>

	</div>
</div>
<div class="load-container" *ngIf="isLoading">
	<div class="pin">
		<div class="loader fa-spin"></div>
	</div>
</div>
