<div class="modal show" >
	<div class="backdrop" (click)="onClose()">
	</div>
	<div [class]=" 'modal-dialog modal-'+(size||'') ">
		<div class="modal-content">
			<div class="modal-header">
				<ng-content select="[it-modal-header]"></ng-content>
			</div>
			<div class="modal-body">
				<ng-content select="[it-modal-body]"></ng-content>
			</div>
			<div class="modal-footer">
				<ng-content select="[it-modal-footer]"></ng-content>
			</div>
		</div>
	</div>
</div>