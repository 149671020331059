<div class="card" *appShowAuthed="'MY_VEHICLES'">
	<div *ngIf="isLoading">Loading</div>
	<div *ngIf="!isLoading">
		<form class="vehicle-search-form">
			<div class="form-group">
				<div class="input-group input-group-sm">
					<input type="text" placeholder="Search" class="form-control">
					<div class="input-group-btn">
						<button class="btn btn-default">
							<i class="fa fa-search"></i>
						</button>
					</div>
					<div class="input-group-btn">
						<a class="btn btn-default"> <i class="fa fa-times"></i>
						</a>
					</div>
				</div>
			</div>
		</form>
		<div *ngFor="let vehicle of vehicles">
			<it-dashboard-vehicle-list-item [vehicle]="vehicle"
				class="mt-2 clearfix"></it-dashboard-vehicle-list-item>
		</div>
	</div>
</div>
