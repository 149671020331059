<div class="card" *ngIf="!isLoading">
	<table class="table table-sm table-responsive" datatable>
		<thead>
			<tr>
				<th>ID</th>
				<th>Route Name</th>
				<th class="cw-50">Edit</th>
				<th class="cw-50">Remove</th>
			</tr>
		</thead>
		<tbody>
			<tr *ngFor="let route of routeList">
				<td>{{route.routeId}}</td>
				<td>{{route.routeName}}</td>
				<td><a class="btn" (click)="editRoute(route.routeId)"> <img
						src="assets/images/icons/edit.png" width="25" />
				</a></td>
				<td><a class="btn" (click)="removeFence(route.routeId)"> <img
						src="assets/images/icons/garbage.png" width="25" />
				</a></td>
			</tr>
		</tbody>
	</table>
</div>
<div *ngIf="isLoading">
	<div class="load-container">
		<div class="pin">
			<div class="loader fa-spin"></div>
		</div>
	</div>
</div>