<h4>
	Vehicle Tracker <span class="pull-right"> <!-- 
		<button class="btn btn-secondary btn-sm"
			(click)="showFilterForm = !showFilterForm">
			<span *ngIf="!showFilterForm">More</span> <span
				*ngIf="showFilterForm">Less</span>
		</button>
		 -->
	</span>
</h4>
<div class="row" *ngIf="!isLoading">
	<div class="ease-in-out"
		[ngClass]="{'col-md-12':!showFilterForm,'col-md-9':showFilterForm}"
		*ngIf="showMap">
		<div class="bg-info text-white device-stats col-md-12 clearfix">
			<div class="row">
				<div class="col-md-4 text-left">
					<span> <i class="fa fa-battery-three-quarters"
						aria-hidden="true"></i> {{(lastKnownLocation.battery || 0) |
						number:'1.2-2' }} %
					</span> <br /> <small>Last Updated :
						{{lastKnownLocation.timestamp | date:'medium'}}</small>
				</div>
				<div class="col-md-4 text-center pt-1">
					<div class="load-container load-sm load-white " *ngIf="smallLoader">
						<div class="pin">
							<div class="loader fa-spin"></div>
						</div>
					</div>
				</div>
				<div class="col-md-4 text-right">
					<span> <i class="fa fa-tachometer" aria-hidden="true"></i>
						{{((getCurrentStatus(lastKnownLocation) != 'Idle')?
						lastKnownLocation.speedKPH:0) | number:'1.2-2'}} km/h
					</span> <br /> <small class="pull-right">Current Status
						:{{getCurrentStatus(lastKnownLocation)}}</small>
				</div>
			</div>
		</div>
		<div class="mt-2">
			<it-google-map [mapCenter]="mapCenter"
				[vehicles]="[lastKnownLocation]" [paths]="locationHistory"
				*ngIf="showMap"></it-google-map>

		</div>
		<div class="bg-info text-white device-stats col-md-12 clearfix mt-2">
			<strong *ngIf="lastAddress">Last seen near : </strong>
			{{lastAddress}}
		</div>

	</div>
	<!-- 
	<div class="col-md-3 ease-in-out" [ngClass]="{'width-zero':!showFilterForm}">
				<div class="card">
					<h4>Vehicle History Tracking</h4>
					<ul class="nav nav-tabs mt-2" id="myTab" role="tablist">
						<li class="nav-item" (click)="activeForm = 'duration'">
							<a class="nav-link" data-toggle="tab" [ngClass]="{'active':(activeForm == 'duration')}" href="javscript:void(0)" role="tab" aria-controls="home">Range</a>
						</li>
						<li class="nav-item" (click)="activeForm = 'range'">
							<a class="nav-link" data-toggle="tab" [ngClass]="{'active':(activeForm == 'range')}" href="javscript:void(0)" role="tab" aria-controls="home">Duration</a>
						</li>
					</ul>
					<div class="row">
						<div class="col-md-12" *ngIf="activeForm == 'duration'">
							<form novalidate [formGroup]="rangeFilterForm" (ngSubmit)="filterByDuration(rangeFilterForm)">
								<div class="form-group mt-2">
									<label>From</label>
									<small class="text-danger pull-right" [hidden]="rangeFilterForm.pristine || rangeFilterForm.controls.fromDate.valid">*Required</small>
									<br/>
									<p-calendar formControlName="fromDate" required [showTime]="true" [readonlyInput]="true"  [showIcon]="true" hourFormat="12"></p-calendar>
								</div>
								<div class="form-group">
									<label>To</label>
									<small class="text-danger pull-right" [hidden]="rangeFilterForm.pristine || rangeFilterForm.controls.toDate.valid">*Required</small>
									<br/>
									<p-calendar formControlName="toDate" required [showTime]="true" [readonlyInput]="true"  [showIcon]="true" hourFormat="12"></p-calendar>
								</div>
								<button class="btn btn-outline-info btn-sm"  [disabled]="smallLoader">Search</button>
								<a class="btn btn-outline-warning btn-sm pull-right" (click)="resetForm()"  >Reset</a>
								<p class="mt-2 text-secondary">
									<small>*You can not search data for more than 3 days at a time. If more data is needed reports can be downloaded.</small>
								</p>
							</form>
						</div>
						<div class="col-md-12" *ngIf="activeForm == 'range'">
							<form novalidate [formGroup]="durationFilterForm" (ngSubmit)="filterByRange(durationFilterForm)">
								<div class="form-group mt-2">
									<label>Data for</label>
									<small class="text-danger pull-right" [hidden]="durationFilterForm.pristine || durationFilterForm.controls.unit.valid">*Required</small>
									<select class="form-control form-control-sm" formControlName="unit" name="unit">
										<option value="d">Days</option>
										<option value="h">Hours</option>
									</select>
								</div>
								<div class="form-group mt-2">
									<label>Duration</label>
									<small class="text-danger pull-right" [hidden]="durationFilterForm.pristine || durationFilterForm.controls.value.valid">*Required</small>
									<input type="number" required class="form-control form-control-sm" formControlName="value" name="value">
								</div>
								<button class="btn btn-outline-info btn-sm" [disabled]="smallLoader">Search</button>
								<a class="btn btn-outline-warning btn-sm pull-right" (click)="resetForm()"  >Reset</a>
								<p class="mt-2 text-secondary">
									<small>*You can not search data for more than 3 days at a time. If more data is needed reports can be downloaded.</small>
								</p>
							</form>
						</div>
					</div>
				</div>
			</div>
			-->
</div>
<div *ngIf="!isError">
	<div class="load-container" *ngIf="!showMap">
		<div class="pin">
			<div class="loader fa-spin"></div>
		</div>
	</div>
</div>
<h3 class="text-center text-warning" *ngIf="isError">
	No Data <br /> <img
		src="assets/images/icons/cloud-computing-error.png" />
</h3>