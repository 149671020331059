<div class="card" *ngIf="!isLoading">
	<form class="form-inline d-flex align-items-stretch mb-4"
		[formGroup]="vehicleStatusReportForm" (ngSubmit)="showReport(vehicleStatusReportForm)">
			<div class="mr-3">
			<label for="uniqueId">Vehicle Status</label>
			<select class="form-control form-control-sm" id="uniqueId" formControlName="status" [style.width.px]="300" >
				<option *ngFor="let name of vehicleStatusKeys" [value]="vehicleStatus[name]">
					{{name}}
				</option>
			</select>
		</div>
		<div class="mr-5">
			<label for="from">Search</label>
			<input type="text" id="imei" class="form-control form-control-sm"  formControlName="imei"/>
		</div>
		<div class="mr-3 pt-3  mt-1">
			<button class="btn btn-sm btn-info"  [disabled]="vehicleStatusReportForm.invalid" type="submit">Submit</button>
		</div>
	</form>

   <it-vehicle-status-report-list
        *ngIf="reportVisible"
        (page)="pageChange($event)"
        [status]="vehicleStatusReportFilter.status"
        [imei]="vehicleStatusReportFilter.imei">
    </it-vehicle-status-report-list>
    <h5 class="text-center mt-4 no-data" *ngIf="!reportVisible">Please select or insert data to view report</h5>
</div>

<div class="load-container" *ngIf="isLoading">
    <div class="pin">
        <div class="loader fa-spin"></div>
    </div>
</div>
