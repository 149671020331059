<div class="col-md-12">
	<div class="row">
		<div class="col-md-6 col-sm-12">
			<div class="clearfix" *ngIf="isLoading || isSchoolLoading">
				<div class="load-container">
					<div class="pin">
						<div class="loader fa-spin"></div>
					</div>
				</div>
			</div>
			<div class="clearfix" *ngIf="!isLoading && !isSchoolLoading">
				<div class="row">
					<div class="col-sm-12">
						<div class="form-group">
							<label>Select Vehicle</label> <select
								class="form-control form-control-sm"
								(change)="fetchVehicleRoutes($event)">
								<option [selected]="true" [value]="-1">Select</option>
								<option *ngFor="let vehicle of vehicles"
									[value]="vehicle.vehicleId">{{vehicle.vehicleDisplayName
									+' - '+ vehicle.vehicleNumber}}</option>
							</select>
						</div>
					</div>
				</div>
				<span *ngIf="!isRouteExist" class="text-danger">* No Routes
					Found</span>
				<div class="clearfix" *ngIf="isRouteLoading">
					<div class="load-container">
						<div class="pin">
							<div class="loader fa-spin"></div>
						</div>
					</div>
				</div>
				<div class="row" *ngIf="isRoutesValid">
					<div class="col-sm-12">
						<div class="form-group">
							<label>Select Route</label> <select
								class="form-control form-control-sm"
								(change)="fetchStoppageTime($event)">
								<option [selected]="true" [value]="-1">Select</option>
								<option *ngFor="let route of routes" [value]="route.routeId">{{route.routeName}}</option>
							</select>
						</div>
					</div>
				</div>
				<span *ngIf="!isStoppageExist" class="text-danger">* No
					Stoppages Found</span>
				<div class="row" *ngIf="isStoppagesValid">
					<div class="col-sm-12">
						<div class="form-group">
							<label>Select Stoppage</label> <select
								class="form-control form-control-sm"
								(change)="getStoppageId($event)">
								<option [selected]="true" [value]="-1" stoppageName="">Select</option>
								<option *ngFor="let stoppage of stoppingPoints"
									[value]="stoppage.stoppageId" [title]="stoppage.name">{{stoppage.name}}</option>
							</select>
						</div>
					</div>
				</div>

			</div>
		</div>
		<div class="col-md-6 col-sm-12">
			<div class="clearfix" *ngIf="!isLoading && !isSchoolLoading">
				<div class="row">
					<div class="col-sm-12">
						<div class="form-group user-height">
							<label>Select School</label> <select
								class="form-control form-control-sm"
								(change)="fetchSchoolUsers($event)">
								<option [selected]="true" [value]="-1" schoolName="">Select</option>
								<option *ngFor="let school of schoolList"
									[value]="school.schoolId" [title]="school.schoolName">{{school.schoolName}}</option>
							</select>
						</div>
					</div>
				</div>
			</div>
			<div class="clearfix" *ngIf="isUserLoading">
				<div class="load-container">
					<div class="pin">
						<div class="loader fa-spin"></div>
					</div>
				</div>
			</div>
			<div class="clearfix" *ngIf="this.schoolId != -1 && this.stoppageId != -1 && !this.isUserLoading">
				<div class="row">
					<div class="col-sm-12">
						<div class="form-group user-height">
							<label>Select User(s)</label>
							<angular2-multiselect [data]="dropdownList"
								[(ngModel)]="selectedItems" [settings]="dropdownSettings"
								(onSelect)="onItemSelect($event)"
								(onDeSelect)="OnItemDeSelect($event)"
								[selectedItems]="selectedItems"
								(onDeSelectAll)="onDeSelectAll($event)"
								(onSelectAll)="onSelectAll($event)"></angular2-multiselect>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="col-md-6 col-sm-12">
			<div class="clearfix" *ngIf="!isLoading">
				<div class="row mt-3">
					<div class="col-md-12 d-flex justify-content-end">
						<button class="btn btn-sm btn-info"
							[disabled]="!isVehicleValid || !isRoutesValid || !isStoppagesValid"
							(click)="shareVehicleRoute()">
							<i class="fa fa-spin fa-circle-o-notch" [hidden]="!isSubmitting"></i>
							Share
						</button>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
