<div class="">
	<div class="load-container" *ngIf="isLoading && !isError">
		<div class="pin">
			<div class="loader fa-spin"></div>
		</div>
	</div>
	<h4 class="text-center text-warning" *ngIf="isError">
		No Data <br /> <img
			src="assets/images/icons/cloud-computing-error.png" />
	</h4>
	<div *ngIf="!isLoading && !isError">
		<div class="col-md-12">
			<h5>Edit Device Details</h5>
			<hr />
			<form (ngSubmit)="saveDevice(driver)" [formGroup]="driver"
				class="mt-2">
				<div class="form-group">
					<label>
						Driver Name
					</label>
					<small class="text-danger pull-right" [hidden]="driver.pristine || !(driver.controls.name.errors && driver.controls.name.errors.required)">*Required</small>
					<input type="text" class="form-control form-control-sm" formControlName="name">
					<span class="form-text text-danger" *ngIf="driver.controls.name.errors && driver.controls.name.errors.pattern "><i class="fa fa-exclamation-triangle" aria-hidden="true"></i>&nbsp;Please enter valid name</span>
				</div>
				<div class="form-group">
					<label>
						RFID Number
					</label>
					<small class="text-danger pull-right" [hidden]="driver.pristine || !(driver.controls.rfid.errors && driver.controls.rfid.errors.required)">*Required</small>
					<input type="text" class="form-control form-control-sm"  disabled = "disabled" formControlName="rfid">
					<span class="form-text text-danger" *ngIf="driver.controls.rfid.errors && driver.controls.rfid.errors.pattern"><i class="fa fa-exclamation-triangle" aria-hidden="true"></i>&nbsp;Please enter valid IMEI number</span>
					
				</div>
				<div class="form-group">
					<label>
						Mobile Number
					</label>
					<small class="text-danger pull-right" [hidden]="driver.pristine || !(driver.controls.mobile.errors && driver.controls.mobile.errors.required)">*Required</small>
					<div class="input-group input-group-sm">
						<span class="input-group-addon p-0 noborder">
							<select class="form-control form-control-sm country-code" [style.width.%]="100" [ngModelOptions]="{standalone:true}" [(ngModel)]="countryCode">
								<option value="+91">+91</option>
							</select>
						</span>
						<p-inputMask formControlName="mobile" [style.width.%]="100" name="mobile" class="sign-up-mobile" mask="(999)-999-9999" [unmask]="true"></p-inputMask>
					</div>
					<span class="form-text text-danger" *ngIf="driver.controls.mobile.errors && driver.controls.mobile.errors.pattern"><i class="fa fa-exclamation-triangle" aria-hidden="true"></i>&nbsp;Please enter valid Mobile Number</span>
				</div>
				<button type="submit" class="btn btn-sm btn-info" [disabled]="isSubmitting || driver.invalid || driver.pristine">
					<i class="fa fa-spin fa-circle-o-notch" [hidden]="!isSubmitting"></i>
					Update
				</button>
			</form>
		</div>
	</div>
</div>
