<div>
    <div *ngIf="!noDataFound ">
        <div *ngIf="!viewMap">
            <div *ngIf="!isLoading">
                <table class="table table-sm mt-3 table-responsive" datatable [dtOptions]="dtOptions">
                    <thead>
                        <tr>
                        	<th>Vehicle Name</th>
                            <th>Start Point</th>
                            <th>End Point</th>
                            <th [style.width.px]="64">Start Time</th>
                            <th [style.width.px]="64">End Time</th>
                            <th>Idle Time</th>
                            <th>Duration</th>
                            <th>Average Speed(km/h)</th>
                            <th>Distance</th>
                            <th>Preview on Map</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let dayReport of dayReportListSync ;let i=index;">
                        	<td>{{ dayReport.vehicleName }}</td>
                            <td>{{ ( (dayReport.startAddress && dayReport.startAddress.length)?dayReport.startAddress:(dayReport.tripStartLat+', '+ dayReport.tripStartLong) )}}</td>
                            <td>{{ ( (dayReport.endAddress && dayReport.endAddress.length)?dayReport.endAddress: (dayReport.tripEndLat+', '+dayReport.tripEndLong ) )}}</td>
                            <td  [style.width.px]="64">{{ dayReport.tripStartTime | date:'hh:mm:ss a MMM,dd yyyy' | emptyToDash}}</td>
                            <td  [style.width.px]="64">{{ dayReport.tripEndTime | date:'hh:mm:ss a MMM,dd yyyy' | emptyToDash}}</td>
                            <td>{{ dayReport.formattedIdleTime | emptyToDash}}</td>
                            <td>{{ dayReport.formattedDuration | emptyToDash}}</td>
                            <td>{{ (dayReport.avgSpeed||0) | number:'1.0-2'}} km/h</td>
                            <td>{{ (dayReport.formattedDistance||0) |  number:'1.0-2'}}</td>
                            <td>
                                <a class="btn btn-secondary btn-sm ml-1" (click)="setMap(dayReport)"> 
                                	<i class="fa fa-map-marker" aria-hidden="true"></i>
                                </a>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="load-container" *ngIf="isLoading">
                <div class="pin">
                    <div class="loader fa-spin"></div>
                </div>
            </div>
        </div>
    </div>
    <div *ngIf="noDataFound">
        <h5 class="text-center mt-4 no-data">No Data available</h5>
    </div>
</div>