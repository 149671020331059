<div class="clearfix">
	<div class="row" *ngIf="!isLoading">
		<div class="col-md-6 col-12 mt-2 d-none d-sm-block" *appShowAuthed="'BILLING_EXPECTED_EARNINGS'">
			<h5>Billing Expected Earnings</h5>
			<table class="table table-striped table-responsive text-center table-earnings">
				<thead>
					<tr>
						<th>#</th>
						<th>Clients</th>
						<th>Total Devices</th>
						<th>Active Devices<br/>(Billed)<br /> <small>(a)</small></th>
						<th class="money-td">Tariff<br /> <small>(b)</small></th>
						<th class="money-td">Bill Amount* <br /> <small>(a x b)</small></th>
					</tr>
				</thead>
				<tbody>
					<tr *ngFor="let client of billingSummary.earning;let i = index;">
						<td>{{(i+1)}}</td>
						<td>{{client.client.firstName || client.client.firstname}}</td>
						<td>{{client.totalDevice}}</td>
						<td>{{client.activeDevice}}</td>
						<td class="money-td"><i class="fa fa-inr" aria-hidden="true"></i> {{(client.tariff || 0) | number:'1.2-2'}}</td>
						<td class="money-td"><i class="fa fa-inr" aria-hidden="true"></i> {{(client.bill || 0) | number:'1.2-2'}}</td>
					</tr>
				</tbody>
				<tfoot>
					<tr>
						<td colspan="2"><strong>Total Revenue Generated*</strong></td>
						<td>{{totalBill.totalDevice}}</td>
						<td>{{totalBill.activeDevice}}</td>
						<td></td>
						<td><i class="fa fa-inr" aria-hidden="true"></i> {{totalBill.bill}}</td>
					</tr>
				</tfoot>
			</table>
		</div>
		<div class="col-md-6 col-12 mt-2" *appShowAuthed="'BILLING_EXPECTED_EXPENSES'">
			<div class="row">
				<div class="col-md-12">
					<h5>Billing Expected Expenses*</h5>
					<div class="bg-formula pt-3 pb-2">
						<div class="row">
							<div class="col text-center d-none d-sm-block ">
								<h3>{{billingSummary.expense.totalactivedevice}}</h3>
								<small>Devices*</small>
							</div>
							<div class="col text-center d-none d-sm-block ">
								<h3>X</h3>
							</div>
							<div class="col text-center d-none d-sm-block ">
								<h3>
									<i class="fa fa-inr" aria-hidden="true"></i>
									{{billingSummary.expense.tariff}}
								</h3>
								<small>Tariff*</small>
							</div>
							<div class="col text-center d-none d-sm-block ">
								<h3>=</h3>
							</div>
							<div class="col text-center">
								<h3>
									<i class="fa fa-inr" aria-hidden="true"></i> {{billingSummary.expense.totalbill}}
								</h3>
								<small>Bill*</small>
							</div>
						</div>
					</div>
				</div>
				<div class="col-md-12 mt-4">
					<h5>
						Profit* <small><i class="fa"
							[ngClass]="{'fa-thumbs-up text-success': (billingSummary.expense.profit > 0 ),'fa-thumbs-down text-danger': (billingSummary.expense.profit <= 0 )}"
							aria-hidden="true"></i></small>
					</h5>
					<div class="bg-formula pt-3 pb-2">
						<div class="row">
							<div class="col text-center d-none d-sm-block ">
								<h3>
									<i class="fa fa-inr" aria-hidden="true"></i>
									{{billingSummary.expense.totalRevenue}}
								</h3>
								<small>Revenue Generated*</small>
							</div>
							<div class="col text-center d-none d-sm-block ">
								<h3>-</h3>
							</div>
							<div class="col text-center d-none d-sm-block ">
								<h3>
									<i class="fa fa-inr" aria-hidden="true"></i>
									{{billingSummary.expense.totalbill}}
								</h3>
								<small>Expected Expenses*</small>
							</div>
							<div class="col text-center d-none d-sm-block ">
								<h3>=</h3>
							</div>
							<div class="col text-center">
								<h3>
									<i class="fa fa-inr" aria-hidden="true"></i>
									{{billingSummary.expense.profit}}
								</h3>
								<small>Profit*</small>
							</div>
						</div>
					</div>
				</div>
				<div class="col-md-12 mt-2">
					<p>*This is a month-end forecast of bill as per your usage.
						Actual billing amount may differ (GST not included).</p>
				</div>
			</div>

		</div>
	</div>
	<div class="load-container" *ngIf="isLoading">
		<div class="pin">
			<div class="loader fa-spin"></div>
		</div>
	</div>
</div>