<div class="col-sm-12">
	<a class="p-2 pull-right" (click)="addGroup()">
		<i class="fa fa-plus-circle"></i>
		Add Group
	</a>
	<div *ngIf="!isLoading">
		<input type="text" class="form-control search-input"
			placeholder="search" [(ngModel)]="filterInput">
		<ul class="list-group mt-2">
			<li class="list-group-item" *ngFor="let group of groupList | filterBy : ['groupName'] : filterInput">
				<strong>{{group.groupName}}</strong> 
				<small class="badge badge-info ml-1">
					{{group.contacts.length}}
				</small>
				<span class="pull-right">
					<a class="p-2" (click)="editGroup(group.contactGroupId)">
						<i class="fa fa-pencil"></i>
					</a>
					<a class="p-2" (click)="removeGroup(group)">
						<i class="fa fa-trash"></i>
					</a>
				</span>
				
			</li>
		</ul>
		
	</div>
	<div *ngIf="isLoading">
		<div class="load-container">
			<div class="pin">
				<div class="loader fa-spin"></div>
			</div>
		</div>
	</div>
</div>
