<div class="row" *ngIf="!isLoading">
	<div class="col-12">
		<div class="row">
			<div class="col-6">Route Geofence</div>
			<div class="col-6 text-right">
			    <button  class = 'btn btn-sm btn-primary'  (click)="createGeofence()"><span>ADD</span></button>
			</div>
		</div>
	</div>
</div>
<div class="row" *ngIf="isLoading">
	<div class="col-12 text-center">
		<div class="load-container">
			<div class="pin">
				<div class="loader fa-spin"></div>
			</div>
		</div>
	</div>
</div>