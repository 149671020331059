<div *ngIf="!isError">
	<p-growl [(value)]="messages" [life]="10000"></p-growl>
	<div *ngIf="!isLoading">
		<router-outlet></router-outlet>
	</div>
	<div *ngIf="isLoading">
		<div class="app-load">
			<div class="load-container">
				<div class="pin">
					<div class="loader fa-spin"></div>
				</div>
			</div>
			<span class="load-text">
				almost done
			</span>
		</div>
		<style>
			.app-load{
			    top: 0px;
			    bottom: 0px;
			    height: 100%;
			    width: 100%;
			    margin-top: auto;
			    margin-bottom: auto;
			    position: fixed;
			    background: #373a3c;
	    	}
	    	.pin{
	    		border: 2px solid #ffffff !important;
	    	}
	    	.pin .loader{
	    		border: 2px solid #ffffff !important;
	   		    border-top-color: transparent !important;
	    		border-bottom-color: transparent !important;
	    	}
	    	.load-text{
	   		    text-align: center;
			    color: #fff;
			    padding-top: 2rem;
			    width: 100%;
			    display: block;
	    		font-size: 1.1rem;
	    	}
		</style>
	</div>
</div>
<div *ngIf="isError" class="text-center col-sm-12 error-div">
	<h1>
		STOP
		<br/>
		<span>
			Unauthorized Access
		</span>
	</h1>
</div>
<link rel="stylesheet" type="text/css" [href]="tenantCssUrl"
	*ngIf="loadTenantCss" />
