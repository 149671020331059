<div *ngIf="isTenant && !isMobileNumberAvailble" class="login-wrapper">
	<nav class="navbar navbar-expand-lg navbar-light bg-light navbar-login">
		<span class="company-brand-logo-image"></span>
		<a class="navbar-brand">{{tenant.tenant.companyName}}</a>
		<ul class="navbar-nav">
			<li class="navbar-item active" (click)="toggleLoginDiv()">
				<a class="nav-link login-link">Log In</a>
			</li>
		</ul>
	</nav>
	<div class="login-div animated fadeIn onlymobile" [hidden]="!showLoginDiv">
		<div class="row col-md-6">
			<div class="col-12">
				 <img [src]="'assets/tenant/'+tenant.tenant.key+'/images/image.png'" [style.width.px]="100" alt="" class="img-fluid onlymobile"/>
			</div>
			<div class="col-12 mt-3">
				<form novalidate [formGroup]="login" (ngSubmit)="loginUser(login)">
					<div class="form-group">
						<label>School Code</label>
							<input formControlName="selectedSchoolName"
								type="Text" placeholder="School Code"
								class="form-control form-control-sm" /> <small
								class="form-text text-danger"
								[hidden]="login.pristine || !(login.controls.selectedSchoolName.errors && login.controls.selectedSchoolName.errors.required)"><i
								class="fa fa-warning"></i> Please enter School Code</small>
						<small class="form-text text-danger" [hidden]="login.pristine || !(login.controls.selectedSchoolName.errors && login.controls.selectedSchoolName.errors.required)"><i class="fa fa-warning"></i> Please Select School Name</small>
					</div>
					<div class="form-group">
						<label>Admission Id</label>
						<input formControlName="admissionId" type="Text" placeholder="Admission Id" class="form-control form-control-sm" />
						<small class="form-text text-danger" [hidden]="login.pristine || !(login.controls.admissionId.errors && login.controls.admissionId.errors.required)"><i class="fa fa-warning"></i> Please enter Admission Id</small>
					</div>
					<div class="row">
						<div class="col cancel-col">
							<button [disabled]="isSubmitting" type="button" class="btn btn-sm btn-secondary btn-block cancel-btn" (click)="closeLoginDiv()">Cancel</button>
						</div>
						<div class="col">
							<button [disabled]="isSubmitting || login.invalid || !isMobileValid" class="btn btn-sm btn-info btn-block btn-login">
								<i class="fa fa-spin fa-circle-o-notch" [hidden]="!isSubmitting"></i>
								<span class="nomobile">Submit</span>
								<i class="fa fa-arrow-right onlymobile" [hidden]="isSubmitting"></i>
							</button>
						</div>
					</div>
					<div class="mt-3 text-center login-footer">
						<small [innerHTML]="tenant.tenant.footerCopyRight"></small>
					</div>
				</form>
			</div>
		</div>
		<style>
			.ui-inputtext{
			    border-radius: 0px 4px 4px 0px;
				border-left: 0px;
			}
		</style>
	</div>
	 <img [src]="'assets/tenant/'+tenant.tenant.key+'/images/login-image.png'" alt="" class="login-page-image"/>
	 <div [innerHtml]='myTemplate'></div>
</div>

		<div>
			<div class="card col-md-6" *ngIf="isMobileNumberAvailble">
				<it-school-otp  [mobile]="mobile" [tenant] = "tenant"></it-school-otp>
			</div>
		</div>
