<div  class="login-wrapper">
		<div class="row">
			<div class="col-12">
				 <img [src]="'assets/tenant/'+tenant.tenant.key+'/images/image.png'" [style.width.px]="100" alt="" class="img-fluid onlymobile"/>
			</div>
			<div class="col-12 mt-3">
				<form novalidate [formGroup]="otpSubmission" (ngSubmit)="submitOtp(otpSubmission)">
					<div class="form-group">
						<label>Enter OTP</label>
						<input formControlName="otp" type="Text" placeholder="OTP" class="form-control form-control-sm" />
						<small class="form-text text-danger" [hidden]="otpSubmission.pristine || !(otpSubmission.controls.otp.errors && otpSubmission.controls.otp.errors.required)"><i class="fa fa-warning"></i> Please enter the OTP</small>
					</div>
					<div class="row">
						<div class="col">
							<button [disabled]="isSubmitting || otpSubmission.invalid" class="btn btn-sm btn-info btn-block btn-login">
								<i class="fa fa-spin fa-circle-o-notch" [hidden]="!isSubmitting"></i>
								<span class="nomobile">Submit</span>
								<i class="fa fa-arrow-right onlymobile" [hidden]="isSubmitting"></i>
							</button>
						</div>
					</div>

				</form>
			</div>
		</div>
		<style>
			.ui-inputtext{
			    border-radius: 0px 4px 4px 0px;
				border-left: 0px;
			}
		</style>
</div>
