<div class="row">
	<div class="col-md-12">
		<h5>Users</h5>
		<div class="card">
			<div class="clearfix" *ngIf="!isLoading">
				<table class="table table-sm table-responsive">
					<thead>
						<tr>
							<th>Full Name</th>
							<th>Mobile Number</th>
							<th>Email Id</th>
							<th>Company Name</th>
							<th>Tariff</th>
							<th width="200" class="text-right">Options</th>
						</tr>
					</thead>
					<tbody>
						<tr *ngFor="let user of users">
							<td class="td-desc pointer" *appShowAuthed="'HREF_STUDENT'" (click)='goToStudent(user)'>{{user.profile.firstName || user.profile.firstname}}</td>
							<td class="td-desc" *appShowAuthed="'NO_HREF_USER'">{{user.profile.firstName || user.profile.firstname}}</td>
							<td>{{user.mobile}}</td>
							<td>{{user.email}}</td>
							<td class="td-desc">{{user.companyName}}</td>
							<td>
								<i class="fa fa-inr" aria-hidden="true"></i>
								{{(user.tariff || 0) | number:'1.2-2'}}
							</td>
							<td class="text-right">
								<button class="btn btn-sm btn-outline-primary" (click)="editUser(user)">
									<i class="fa fa-pencil" aria-hidden="true"></i>
									Edit
								</button>
							</td>
						</tr>
					</tbody>
				</table>
				<div class="clearfix text-center border-dashed" *ngIf="users.length == 0">
					<h5 class="m-0 p-0">No users added</h5>
				</div>
			</div>
			<div class="clearfix" *ngIf="isLoading">
				<div class="load-container" >
					<div class="pin">
						<div class="loader fa-spin"></div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>