<div class="login" #login>
	<div class="login-bg" #loginBg></div>
	<div class="login-router">
		<div class="login-components">
			<router-outlet></router-outlet>
		</div>
	</div>
	
	<div class="row  copyright-text">
	<!-- 	<small class="col-sm-12 col-md-4 ml-auto mr-auto"><a href="http://www.ituple.com" target="_blank">&copy; iTuple Technologies Pvt. Ltd. <i class="fa fa-external-link"></i> </a></small>  -->
	</div>
	
</div>
