<div class="col-md-12 pb-5">
	<h5>Add User</h5>
	<hr />
	<div class="clearfix" *ngIf="isLoading">
		<div class="load-container" >
			<div class="pin">
				<div class="loader fa-spin"></div>
			</div>
		</div>
	</div>
	<div class="clearfix" *ngIf="!isLoading">
		<form novalidate [formGroup]="addUserForm" (ngSubmit)="saveUser(addUserForm)">
			<div class="row">
				<div class="col-md-12 sub-section-header">
					<strong>User Information</strong>
				</div>
				<div class="col-md-12">
					<div class="form-group">
						<label>Full Name</label>
						<small class="pull-right text-danger" [hidden]="addUserForm | validateRequired:'firstName'">*Required</small>
						<input formControlName="firstName" type="text" class="form-control form-control-sm">
						<span class="form-text text-danger" [hidden]="addUserForm | validateRegex:'firstName'">
							<i class="fa fa-exclamation-triangle" aria-hidden="true"></i>
							Please enter valid name.
						</span>
					</div>
				</div>
				<div class="col-md-12">
					<div class="form-group">
						<label>Mobile Number</label>
						<small class="pull-right text-danger" [hidden]="addUserForm | validateRequired:'mobile'">*Required</small>
						<div class="input-group input-group-sm">
							<span class="input-group-addon p-0 noborder">
								<select formControlName="countryCode" class="form-control form-control-sm country-code" [style.width.%]="100">
									<option value="+91">+91</option>
									<option value="+1">+1</option>
									<option value="+234">+234</option>
								</select>
							</span>
							<p-inputMask formControlName="mobile" class="sign-up-mobile" mask="(999)-999-9999" [style.width.%]="100" [unmask]="true" (keyup)="setUserName()" ></p-inputMask>
						</div>
						<span class="form-text text-danger" [hidden]="addUserForm | validateRegex:'mobile'">
							<i class="fa fa-exclamation-triangle" aria-hidden="true"></i>
							Please enter valid mobile number.
						</span>
					</div>
				</div>
				<div class="col-md-12">
					<div class="form-group" *appShowAuthed="'REGISTER_WITH_PASSWORD'">
						<label>New Password</label>
						<small class="pull-right text-danger" [hidden]="addUserForm | validateRequired:'password'">*Required</small>
						<input formControlName="password" autocomplete="off" type="password" class="form-control form-control-sm">
						<span class="form-text text-danger" [hidden]="addUserForm | validateRegex:'password'">
							<i class="fa fa-exclamation-triangle" aria-hidden="true"></i>
							Please enter secure password, click <a class='a' (click)="showPolicy = true">here</a> for password policy.
						</span>
					</div>
				</div>
				<div class="col-md-12" *appShowAuthed="'REGISTER_WITH_PASSWORD'">
					<div class="form-group">
						<label>Confirm Password</label>
						<small class="pull-right text-danger" [hidden]="addUserForm | validateRequired:'confirmPassword'">*Required</small>
						<input type="password" formControlName="confirmPassword" class="form-control form-control-sm">
						<span class="form-text text-danger" [hidden]="addUserForm | validateRegex:'confirmPassword'">
							<i class="fa fa-exclamation-triangle" aria-hidden="true"></i>
							New password and confirm password must match.
						</span>
					</div>
				</div>
				<div class="col-md-12">
					<div class="form-group">
						<label>User Name</label>
						<small class="pull-right text-danger" [hidden]="addUserForm | validateRequired:'userName'">*Required</small>
						<input formControlName="userName" type="text" class="form-control form-control-sm">
						<span class="form-text text-danger" [hidden]="addUserForm | validateRegex:'userName'">
							<i class="fa fa-exclamation-triangle" aria-hidden="true"></i>
							Please enter valid user name.
						</span>
					</div>
				</div>
				<div class="col-md-12">
					<div class="form-group">
						<label>Email ID</label>
						<small class="pull-right text-danger" [hidden]="addUserForm | validateRequired:'email'">*Required</small>
						<input type="email" formControlName="email" class="form-control form-control-sm">
						<span class="form-text text-danger" [hidden]="addUserForm | validateRegex:'email'">
							<i class="fa fa-exclamation-triangle" aria-hidden="true"></i>
							Please enter valid email id.
						</span>
					</div>
				</div>
				<div class="col-md-12">
					<div class="form-group">
						<label>Group</label>
						<small class="pull-right text-danger" [hidden]="addUserForm | validateRequired:'groupId'">*Required</small>
						<select formControlName="groupId" class="form-control form-control-sm">
							<option *ngFor="let group of groups" [value]="group.groupId">{{group.groupName}}</option>
						</select>
					</div>
				</div>
				<div class="col-md-12 mt-2 sub-section-header">
					<strong>User Company Details</strong>
				</div>
				<div class="col-md-12">
					<div class="form-group">
						<label>Company</label>
						<small class="pull-right text-danger" [hidden]="addUserForm | validateRequired:'companyId'">*Required</small>
						<select formControlName="companyId" class="form-control form-control-sm">
							<option *ngFor="let company of companies" [value]="company.companyId">{{company.companyName}}</option>
						</select>
					</div>
				</div>
				<div class="col-md-12" *ngIf="addUserForm.value.companyId == -1">
					<div class="form-group">
						<label>Company Name</label>
						<small class="pull-right text-danger" [hidden]="addUserForm | validateRequired:'companyName'">*Required</small>
						<input formControlName="companyName" type="text" class="form-control form-control-sm">
						<span class="form-text text-danger" [hidden]="addUserForm | validateRegex:'companyName'">
							<i class="fa fa-exclamation-triangle" aria-hidden="true"></i>
							Please enter valid company name.
						</span>
					</div>
				</div>
				<div class="col-md-12 mt-2 sub-section-header">
					<strong>Device Rate</strong>
				</div>
				<div class="col-md-12">
					<div class="form-group">
						<label>Tariff</label>
						<small class="pull-right text-danger" [hidden]="addUserForm | validateRequired:'tarrif'">*Required</small>
						<div class="input-group">
							<div class="input-group-prepend">
								<div class="input-group-text">
									<i class="fa fa-inr" aria-hidden="true"></i>
								</div>
							</div>
							<input formControlName="tarrif" type="number" class="form-control form-control-sm" >
						</div>
						<span class="form-text text-danger" [hidden]="addUserForm | validateRegex:'tarrif'">
							<i class="fa fa-exclamation-triangle" aria-hidden="true"></i>
							Please enter valid tariff.
						</span>
					</div>
				</div>
				<div class="col-md-12 d-flex justify-content-start">
					<button class="btn btn-info btn-sm" [disabled]="addUserForm.invalid || isSubmitting">
						<i class="fa fa-spin fa-circle-o-notch" *ngIf="isSubmitting"></i>
						Add
					</button>
				</div>
			</div>
		</form>
	</div>
	<it-modal *ngIf="showPolicy">
		<div it-modal-header>
			<h5 class="m-0">Password Policy</h5>
		</div>
		<div it-modal-body>
			Your password must contain
			<ul>
				<!-- <li>At least one special character (!,@,#,$,*,&,_).</li>
				<li>At least one uppercase character. </li>
				<li>At least one lowercase character. </li>
				<li>At least one number. </li> -->
				<li>Must contain at least 8 characters and can not be more than 24 characters long.</li>
			</ul>
		</div>
		<div it-modal-footer>
			<button class="btn btn-sm btn-danger" type="button" (click)="showPolicy = false">Close</button>
		</div>
	</it-modal>
</div>
