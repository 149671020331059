<div *ngIf="isTenant" class="login-wrapper">
	<nav class="navbar navbar-expand-lg navbar-light bg-light navbar-login">
		<span class="company-brand-logo-image"></span>
		<a class="navbar-brand">{{tenant.tenant.companyName}}</a>
		<ul class="navbar-nav">
			<li class="navbar-item active" (click)="toggleLoginDiv()">
				<a class="nav-link login-link">Log In</a>
			</li>
		</ul>
	</nav>
	<div class="login-div animated fadeIn" [hidden]="!showLoginDiv">
		<div class="row">
			<div class="col-12">
			 <img [src]="'assets/tenant/'+tenant.tenant.key+'/images/image.png'" [style.width.px]="100" alt="" class="img-fluid onlymobile"/>
				<h5 class="nomobile">{{tenant.tenant.companyName + ' | '}}Log In</h5>
				<hr />
			</div>
			<div class="col-12 mt-1">
				<form novalidate [formGroup]="login" (ngSubmit)="loginUser(login)">
				<div class="form-group">
						<label class="nomobile">Login Using</label>
						<div class="input-group">
							<input formControlName="loginUsing" type="radio" value='mobile' class="form-control form-control-sm" (change)="changeLoginUsing($event)"/>Mobile
							<input formControlName="loginUsing" type="radio" value='username' class="form-control form-control-sm"  (change)="changeLoginUsing($event)"/>UserName
							<input formControlName="loginUsing" type="radio" value='email' class="form-control form-control-sm"  (change)="changeLoginUsing($event)"/>Email
						</div>
					</div>
					<div class="form-group">
						<label>User ID</label>
						<div class="input-group">
							<span class="input-group-prepend" *ngIf="isMobile">
								<select class="input-group-text p5px"  formControlName="code">
									<option value="+91">+91</option>
									<option value="+1">+1</option>
									<option value="+234">+234</option>
								</select>
							</span>
							<!-- <div class="input-group-prepend">
								<div class="input-group-text p5px">+91</div>
							</div> -->
							<input type="text" class="form-control form-control-sm" formControlName="userId" (keyup)="validate($event)">
						</div>
						<small class="form-text text-danger" [hidden]="login.pristine || !(login.controls.userId.errors && login.controls.userId.errors.required)"><i class="fa fa-warning"></i> Please enter your user id</small>
						<small class="form-text text-danger" [hidden]="!isMobile || isMobileValid"><i class="fa fa-warning"></i> Please enter correct mobile number</small>
						<small class="form-text text-danger" [hidden]="loginUsing != 'email' || isEmailValid"><i class="fa fa-warning"></i> Please enter correct email address</small>
					</div>
					<div class="form-group">
						<label>Password</label>
						<input formControlName="password" type="password" placeholder="password" class="form-control form-control-sm" />
						<small class="form-text text-danger" [hidden]="login.pristine || !(login.controls.password.errors && login.controls.password.errors.required)"><i class="fa fa-warning"></i> Please enter your password</small>
					</div>
					<div class="form-group">

						<div class="form-check">
							<input  formControlName="staySignedIn" class="form-check-input ml-0" type="checkbox" id="autoSizingCheck">
							<label class="form-check-label" for="autoSizingCheck">
								Stay signed in
					        </label>
						</div>
					</div>
					<div class="row">
						<div class="col cancel-col">
							<button [disabled]="isSubmitting" type="button" class="btn btn-sm btn-secondary btn-block cancel-btn" (click)="closeLoginDiv()">Cancel</button>
						</div>
						<div class="col">
							<button [disabled]="isSubmitting || login.invalid || !isMobileValid" class="btn btn-sm btn-info btn-block btn-login">
								<i class="fa fa-spin fa-circle-o-notch" [hidden]="!isSubmitting"></i>
								<span class="nomobile">Log In</span>
								<i class="fa fa-arrow-right onlymobile" [hidden]="isSubmitting"></i>
							</button>
						</div>
					</div>
					<div class="mt-3 text-center login-footer">
						<small [innerHTML]="tenant.tenant.footerCopyRight"></small>
					</div>
				</form>
			</div>
		</div>
		<style>
			.ui-inputtext{
			    border-radius: 0px 4px 4px 0px;
				border-left: 0px;
			}
		</style>
	</div>
	<it-change-old-password [userId]="userId" [resetUsing]="resetUsing" (onClose)="onChangeClosed()" *ngIf="changePassword"></it-change-old-password>
	 <img [src]="'assets/tenant/'+tenant.tenant.key+'/images/login-image.png'" alt="" class="login-page-image"/>
	 <div [innerHtml]='myTemplate'></div>
</div>
