<div>
    <div *ngIf="!noDataFound ">
        <div *ngIf="!viewMap && !isShowGraphLoader">
            <div *ngIf="!isLoading">
            <div class="nomobile">
                <table class="table table-sm mt-3 table-responsive" datatable [dtOptions]="dtOptions">
                    <thead>
                        <tr>
                        	<th>Bus Route</th>
                            <th>Shift</th>
                            <th>Bus No</th>
                            <th [style.width.px]="100">Vehicle Name</th>
                            <th [style.width.px]="64">Sch 1st Stop</th>
                            <th>Sch St. Time</th>
                            <th>Location at Sch St. Time</th>
                            <th>Actual St. Time</th>
                            <th>Sch Arrival Time</th>
                            <th>Location at Sch Arrival Time</th>
                            <th>Actual Arrival Time</th>
                            <th>Total card Swipes</th>
                            <th>Total Distance (KM)</th>
                            <th>Trip Duration</th>
                            <th>Running History</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let busReport of busReportListSync ;let i=index;">
                        	<td data-label="Route Name">{{busReport.busRoute}}</td>
                            <td data-label="Bus No">{{busReport.shift}}</td>
                            <td data-label="Transporter's Name">{{busReport.vehicleNumber}}</td>
                            <td data-label="Destination" [style.width.px]="64">{{busReport.vehicleName}}</td>
                            <td data-label="Shift" [style.width.px]="64">{{busReport.firstStoppageName}}</td>
                            <td data-label="Company Arrival Time">{{busReport.scheduledStartTime == 0 ? 'NA' : (busReport.scheduledStartTime | date :'shortTime')}}</td>
                            <td data-label="First Stoppage Start Time">{{busReport.locationAtStartTime ? busReport.locationAtStartTime : 'Device Not Reachable'}}</td>
                            <td data-label="Trip Start Time">{{busReport.actualStartTime == 0 ? 'Route Deviation' : (busReport.actualStartTime | date :'shortTime')}}</td>
                            <td data-label="Company Arrival Time">{{busReport.scheduledEndTime == 0 ? 'NA' : (busReport.scheduledEndTime | date :'shortTime')}}</td>
                            <td data-label="Trip End Time">{{busReport.locationAtEndTime ? busReport.locationAtEndTime : 'Device Not Reachable'}}</td>
                            <td data-label="Trip Start Time">{{busReport.actualEndTime == 0 ? 'Route Deviation' : (busReport.actualEndTime | date :'shortTime')}}</td>
                            <td data-label="User Count">{{busReport.userCount || 0}}</td>
                            <td data-label="Distance">{{ (busReport.distance || 0) |  number:'1.0-2'}}</td>
                            <td data-label="Duration">{{ getDuration(busReport.actualEndTime,busReport.actualStartTime)}}</td>
                            <td data-label="Map">
                                <a class="btn btn-secondary btn-sm ml-1" (click)="setMap(busReport)"> 
                                	<i class="fa fa-map-marker" aria-hidden="true"></i>
                                </a>
                            </td>
                           <!--  <td data-label="Graph">NA</td> -->
                          </tr>
                    </tbody>
                </table>
                </div>
            </div>
            <div class="load-container" *ngIf="isLoading">
                <div class="pin">
                    <div class="loader fa-spin"></div>
                </div>
            </div>
        </div>
    </div>
    <div *ngIf="noDataFound">
        <h5 class="text-center mt-4 no-data">No Data available</h5>
    </div>
</div>