<div class="col-sm-12">
	<div class="load-container" *ngIf="isLoading && !isError">
		<div class="pin">
			<div class="loader fa-spin"></div>
		</div>
	</div>
	<h4 class="text-center text-warning" *ngIf="isError">
		No Data <br /> <img
			src="assets/images/icons/cloud-computing-error.png" />
	</h4>
	<div *ngIf="!isLoading && !isError">
		<h5>
		Edit Contact 
		<small [style.font-size.px]="12">
			<a class="p-2 pull-right a" (click)="goBack()">
				<i class="fa fa-chevron-left"></i>
				Back
			</a>
		</small>
	</h5>
	<hr />
	<form novalidate [formGroup]="contactForm" (ngSubmit)="saveContact(contactForm)">
		<div class="row">
			<div class="col-md-12">
				<div class="form-group">
					<label>
						Name
					</label>
					<small class="pull-right text-danger" [hidden]="contactForm.pristine || contactForm.controls.name.valid">
						*Required
					</small>
					<input class="form-control form-control-sm" name="name" formControlName="name">
				</div>
			</div>
			<div class="col-md-12">
				<div class="form-group">
					<label>
						Phone Number
					</label>
					<small class="pull-right text-danger" [hidden]="contactForm.pristine || contactForm.controls.phoneNumber.valid">
						*Required
					</small>
					<div class="input-group input-group-sm">
						<span class="input-group-addon p-0">
							<select class="form-control form-control-sm country-code" [style.width.%]="100" [ngModelOptions]="{standalone:true}" [(ngModel)]="countryCode">
								<option value="+91">+91</option>
								<option value="+1">+1</option>
							</select>
						</span>
						<p-inputMask formControlName="phoneNumber" [style.width.%]="100" name="mobile" class="sign-up-mobile" mask="(999)-999-9999" [unmask]="true"></p-inputMask>
					</div>
					
				</div>
			</div>
			<div class="col-md-12">
				<div class="form-group">
					<label>
						Email
					</label>
					<small class="pull-right text-danger" [hidden]="contactForm.pristine || contactForm.controls.email">
						*Required
					</small>
					<input class="form-control form-control-sm" name="email" formControlName="email">
				</div>
			</div>
			<div class="col-md-12">
				<button type="submit" class="btn btn-sm btn-info" [disabled]="isSubmitting">
					<i class="fa fa-spin fa-circle-o-notch" *ngIf="isSubmitting"></i>
					Update Contact
				</button>
			</div>
		</div>
	</form>
	</div>
</div>

