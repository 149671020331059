<div>
    <div *ngIf="!noDataFound ">
        <div *ngIf="!viewMap && !isShowGraphLoader">
            <div *ngIf="!isLoading">
            <div class="nomobile">
                <table class="table table-sm mt-3 table-responsive" datatable [dtOptions]="dtOptions">
                    <thead>
                        <tr>
                        	<th *appShowAuthed="'DAY_REPORT_DRIVER_NAME'">Driver Name</th>
                            <th>Start Point</th>
                            <th>End Point</th>
                            <th [style.width.px]="64">Start Time</th>
                            <th [style.width.px]="64">End Time</th>
                            <th>Idle Time</th>
                            <th>Duration</th>
                            <th>Average Speed(km/h)</th>
                            <th>Distance</th>
                            <th>Preview on Map</th>
                            <th>Graph</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let dayReport of dayReportListSync ;let i=index;">
                        <td *appShowAuthed="'DAY_REPORT_DRIVER_NAME'">{{dayReport.driverName}}</td>
                            <td data-label="Start Point">{{ ( (dayReport.startAddress && dayReport.startAddress.length)?dayReport.startAddress:(dayReport.tripStartLat+', '+ dayReport.tripStartLong) )}}</td>
                            <td data-label="End Point">{{ ( (dayReport.endAddress && dayReport.endAddress.length)?dayReport.endAddress: (dayReport.tripEndLat+', '+dayReport.tripEndLong ) )}}</td>
                            <td data-label="Start Time" [style.width.px]="64">{{ dayReport.tripStartTime | date:'hh:mm:ss a MMM,dd yyyy' | emptyToDash}}</td>
                            <td data-label="End Time" [style.width.px]="64">{{ dayReport.tripEndTime | date:'hh:mm:ss a MMM,dd yyyy' | emptyToDash}}</td>
                            <td data-label="Idle Time">{{ dayReport.formattedIdleTime | emptyToDash}}</td>
                            <td data-label="Duration">{{ dayReport.formattedDuration | emptyToDash}}</td>
                            <td data-label="Avg. Speed">{{ (dayReport.avgSpeed||0) | number:'1.0-2'}} km/h</td>
                            <td data-label="Distance">{{ (dayReport.formattedDistance||0) |  number:'1.0-2'}}</td>
                            <td data-label="Map">
                                <a class="btn btn-secondary btn-sm ml-1" (click)="setMap(dayReport)"> 
                                	<i class="fa fa-map-marker" aria-hidden="true"></i>
                                </a>
                            </td>
                            <td data-label="Graph">
                            <a class="btn btn-secondary btn-sm ml-1" (click)="showGraphTrigger(dayReport)">
                                	<i class="fa fa-bar-chart" aria-hidden="true"></i>
                                </a>
                            </td>
                        </tr>
                    </tbody>
                </table>
                </div>
                <div class="container custom-card onlymobile" *ngFor="let dayReport of dayReportListSync">
                	     <div class="fare-estimate__input-connector"></div>
				        <div class="ml-2 mt-2">{{dayReport.startAddress}}&nbsp;({{dayReport.tripStartTime | date:'hh:mm:ss a MMM,dd yyyy' | emptyToDash}})</div>
				        <div class="ml-2 mt-2">
				        	<i class="fa fa-road" aria-hidden="true">&nbsp;{{dayReport.formattedDistance}} km</i>
				        	 <a class="pull-right" (click)="setMap(dayReport)"> 
				        		<i class="fa fa-map-marker" aria-hidden="true"></i>	
				        	</a>
				        </div>
				        <div class="ml-2 mt-2">
				        <i class="fa fa-clock-o" aria-hidden="true">&nbsp;&nbsp;{{dayReport.formattedDuration}}</i>
				         <a class="pull-right" (click)="showGraphTrigger(dayReport)"> 
				        		<i class="fa fa-bar-chart" aria-hidden="true"></i>	
				         </a>
				        </div>
				        <div class="ml-2 text-left">{{dayReport.endAddress}}&nbsp;({{dayReport.tripEndTime |date:'hh:mm:ss a MMM,dd yyyy' | emptyToDash}})</div>
                </div>
            </div>
            <div class="load-container" *ngIf="isLoading">
                <div class="pin">
                    <div class="loader fa-spin"></div>
                </div>
            </div>
        </div>
    </div>
    <div *ngIf="noDataFound">
        <h5 class="text-center mt-4 no-data">No Data available</h5>
    </div>
</div>