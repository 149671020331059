<div *ngIf="isLoading && !isError" class="text-center">
	<div class="load-container">
		<div class="pin">
			<div class="loader fa-spin"></div>
		</div>
	</div>
	<div *ngIf="viewMode == 0">
		<br/>
		Getting your location
		<br/>
		<small>(You must enable location service on your device to use this feature)</small>
		<br/>
		<span *ngIf="defaultLocations.length > 0">or</span>
		<br/>
		<span *ngIf="defaultLocations.length > 0">select one of the following locations to begin with</span>
		<br/>
		<span *ngFor="let location of defaultLocations;let i = index " ><a href="javascript:void(0)" (click)="selectDefaultLocation(location)"> {{location.label}} </a><span *ngIf="i != (defaultLocations.length - 1)"> | </span></span>
	</div>
</div>
<div *ngIf="!isLoading && !isError">

	<h5><span *ngIf="viewMode == 0">Add</span><span *ngIf="viewMode == 1">Edit</span>Geofence</h5>
	<span *ngIf="hideMapOnMobile" class="only-on-mobile">
		<button class="btn btn-danger btn-sm button-close-mobile-map"
			(click)="hideMapMobile()">
			<i class="fa fa-window-close" aria-hidden="true"></i> Hide Map
		</button>
	</span> <span *ngIf="!hideMapOnMobile" class="only-on-mobile">
		<button class="btn btn-secondary btn-sm" (click)="hideMapMobile()">
			<i class="fa fa-map" aria-hidden="true"></i> Show Map
		</button>
	</span>
	<div class="card">
		<div class="row">
			<div class="col-md-4"
				[ngClass]="{'mobile-show':!hideMapOnMobile}">
				<div class="btn-group btn-group-sm ml-auto" role="group"
					aria-label="">
					<form novalidate [formGroup]="geoFenceForm" class="form-horizontal mb-3" (ngSubmit)="saveGeoFence(geoFenceForm)">
						<div class="row">
							<div class="col-12">
								<div class="form-group">
						<label>Name</label>
						<small class="text-danger pull-right" [hidden]="geoFenceForm.pristine || !(geoFenceForm.controls.name.errors && geoFenceForm.controls.name.errors.required)">*Required</small>
						<input type="text" class="form-control form-control-sm" formControlName="name" name="name">
						<span class="text-danger" [hidden]="geoFenceForm.pristine || !(geoFenceForm.controls.name.errors && geoFenceForm.controls.name.errors.pattern)">
										<i class="fa fa-warning"></i> Please enter valid name.
									</span>
								</div>
							</div>
							<div class="col-12">
								<div class="form-group">
						<label>Type</label>
						<small class="text-danger pull-right" [hidden]="geoFenceForm.pristine || !(geoFenceForm.controls.geofenceType.errors && geoFenceForm.controls.geofenceType.errors.required)">*Required</small>
						<select class="form-control form-control-sm" formControlName="geofenceType" name="geofenceType">
										<option value="alert">Alert</option>
										<option value="parking">Parking</option>
									</select>
								</div>
							</div>
							<div class="col-12">
								<div class="form-group">
									<label>Description</label>
						<textarea rows="3" class="form-control form-control-sm" formControlName="description" name="description">
						</textarea>
						<span class="text-danger" [hidden]="geoFenceForm.pristine || geoFenceForm.controls.description.valid">
							<i class="fa fa-warning"></i> Fence name should not exceed more than 250 characters.
									</span>
								</div>
							</div>
							<div class="col-12">
								<div class="form-group">
						<label>Fence</label>
						<span class="form-control form-control-sm">
							<span *ngIf="geoFenceList.length == 0"> - </span>
							<span *ngFor="let fence of geoFenceList; let i = index"> 
								{{fence.type}}
								<span *ngIf="i != (geoFenceList.length - 1)">, </span>
										</span>
									</span>
						<span class="text-danger" *ngIf="!isFenceValid(geoFenceForm)">
							<i class="fa fa-warning"></i> Please draw a shape on map (Multiple shapes not allowed)
									</span>
								</div>
							</div>
							<div class="col-12">
					<button type="submit" [disabled]="isSubmitting || geoFenceForm.invalid" class="btn btn-sm btn-info">
						<i class="fa fa-spin fa-circle-o-notch" [hidden]="!isSubmitting"></i>
						Save
								</button>
							</div>
						</div>
					</form>
				</div>
			</div>
			<div class="application-map-wrapper pl-0 pr-0 col-md-8"
				[ngClass]="{'mobile-show':hideMapOnMobile}">
				<it-google-map [mapCenter]="mapCenter" [vehicles]="[]" [paths]="[]"
					[geoMode]="true" (geoUpdated)="geoFenceUpdated($event)"
					[geoOverlay]="googleOverlay"></it-google-map>
			</div>
		</div>
	</div>
		<!-- 	<div class="row mt-5" *ngIf="viewMode">
				<div class="col-md-12">
					<h5>Geofence Device Mapping</h5>
					<div class="card">
						<it-geofence-device-mapping></it-geofence-device-mapping>
					</div>
				</div>
				
			</div>
			 -->
	
</div>
<h3 class="text-center text-warning" *ngIf="isError">
	No Data / Internal Server Error <br /> <img src="assets/images/icons/cloud-computing-error.png" />
</h3>
