<div class="col-md-12 pb-5">
	<h5>Update User</h5>
	<hr />
	<div class="clearfix" *ngIf="!isLoading">
		<div class="clearfix text-center" *ngIf="isError">
			<img src="/assets/images/icons/prohibition.png"/>
			<br />
			<div class="clearfix text-center text-danger mt-5">
				<h5 class="text-center">Internal Server Error</h5>
			</div>
		</div>
		<div class="clearfix" *ngIf="!isError">
			<form novalidate [formGroup]="userForm" (ngSubmit)="updateUser(userForm)">
				<div class="row">
					<div class="col-md-12 sub-section-header">
						<strong>User Information</strong>
					</div>
					<div class="col-md-12">
						<div class="form-group">
							<label>Full Name</label>
							<span class="form-control form-control-sm">{{user.profile.firstname || user.profile.firstName}}</span>
							
						</div>
					</div>
					<div class="col-md-12">
						<div class="form-group">
							<label>Mobile Number</label>
							<span class="form-control form-control-sm">{{user.mobile}}</span>
						</div>
					</div>
					<div class="col-md-12">
						<div class="form-group">
							<label>Email ID</label>
							<span class="form-control form-control-sm">{{ user.email }}</span>
						</div>
					</div>
					<div class="col-md-12 mt-2 sub-section-header">
						<strong>User Company Details</strong>
					</div>
					<div class="col-md-12">
						<div class="form-group">
							<label>Company</label>
							<span class="form-control form-control-sm">{{(user.companyName && user.companyName.length?user.companyName:'NA')}}</span>
						</div>
					</div>
					<div class="col-md-12 mt-2 sub-section-header">
						<strong>Device Rate</strong>
					</div>
					<div class="col-md-12">
						<div class="form-group">
							<label>Tariff</label>
							<small class="pull-right text-danger" [hidden]="userForm | validateRequired:'tarrif'">*Required</small>
							<div class="input-group">
								<div class="input-group-prepend">
									<div class="input-group-text">
										<i class="fa fa-inr" aria-hidden="true"></i>
									</div>
								</div>
								<input formControlName="tarrif" type="number" class="form-control form-control-sm" >
							</div>
							<span class="form-text text-danger" [hidden]="userForm | validateRegex:'tarrif'">
								<i class="fa fa-exclamation-triangle" aria-hidden="true"></i>
								Please enter valid tariff.
							</span>
						</div>
					</div>
					<div class="col-md-12 d-flex justify-content-start">
						<button class="btn btn-info btn-sm" [disabled]="userForm.invalid || isSubmitting">
							<i class="fa fa-spin fa-circle-o-notch" [hidden]="!isSubmitting"></i>
							Update
						</button>
					</div>
				</div>
			</form>
		</div>
		
	</div>
	<div class="clearfix" *ngIf="isLoading">
		<div class="load-container" >
			<div class="pin">
				<div class="loader fa-spin"></div>
			</div>
		</div>
	</div>
</div>