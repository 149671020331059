<div class="col-sm-12">
	<a class="p-2 pull-right" (click)="addContact()">
		<i class="fa fa-plus-circle"></i>
		Add Contact
	</a>
	<div *ngIf="!isLoading">
		<input type="text" class="form-control search-input"
			placeholder="search" [(ngModel)]="filterInput">
		<ul class="list-group mt-2">
			<li class="list-group-item" *ngFor="let contact of contactList | filterBy : ['email','phoneNumber','name'] : filterInput">
				<strong>{{contact.name}}</strong>
				<span class="pull-right">
					<a class="p-2" (click)="editContact(contact.contactId)">
						<i class="fa fa-pencil"></i>
					</a>
					<a class="p-2" (click)="removeContact(contact)">
						<i class="fa fa-trash"></i>
					</a>
				</span>
				<br /> 
				<small>
					<a href="mailto:{{contact.email}}">{{contact.email}}</a>&nbsp;/&nbsp;
					<a href="tel:{{contact.phoneNumber}}">{{contact.phoneNumber}}</a>
				</small>
				
			</li>
		</ul>
	</div>
	<div *ngIf="isLoading">
		<div class="load-container">
			<div class="pin">
				<div class="loader fa-spin"></div>
			</div>
		</div>
	</div>
</div>