<div class="col-sm-12">
	<h5>Share Location</h5>
	<div class="row">
		<div class="col-sm-12" *ngIf="!isLoading">
			<form novalidate [formGroup]="sharingForm"  (ngSubmit)="shareCar(sharingForm)">
				<div class="form-group">
					<label>
						From
					</label>
					<small class="pull-right text-danger" [hidden]="sharingForm.pristine || !(sharingForm.controls.from.errors && sharingForm.controls.from.errors.required)">Required</small>
					<p-calendar [style.width.%]="100" [minDate]="minFromDate" formControlName="from" [showIcon]="true" [showTime]="true"></p-calendar>
				</div>
				<div class="form-group">
					<label>
						To
					</label>
					<small class="pull-right text-danger" [hidden]="sharingForm.pristine || !(sharingForm.controls.to.errors && sharingForm.controls.to.errors.required)">Required</small>
					<p-calendar [style.width.%]="100" [minDate]="sharingForm.controls.from.value" formControlName="to" [showIcon]="true" [showTime]="true"></p-calendar>
				</div>
				<div>
					<button class="btn btn-sm btn-info" type="submit" [disabled]="sharingForm.invalid">
						Generate Link
					</button>
				</div>
			</form>
		</div>
	</div>
</div>