<div>
    <div *ngIf="!noDataFound ">
            <div *ngIf="!isLoading">
                <table class="table table-sm mt-3 table-responsive" datatable [dtOptions]="dtOptions">
                    <thead>
                        <tr>
	                        <th>Vehicle Name</th>
	                        <th>User Name </th>
	                        <th>IMEI Number </th>
	                        <th>SIM Number</th>
	                        <th>Mobile Number </th>
	                        <th>Last Packet Received</th>
	                        <th>Status  </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let vehicleStatusReport of vehicleStatusReportListSync ;let i=index;">
	                        <td>{{vehicleStatusReport.vehicleName | emptyToDash}} </td>
	                        <td>{{vehicleStatusReport.userName | emptyToDash}}</td>
	                         <td class="pointer" (click)='goToReport(vehicleStatusReport)'>{{vehicleStatusReport.imei | emptyToDash}}</td>
	                        <td>{{vehicleStatusReport.simNumber | emptyToDash}}</td>
	                        <td>{{vehicleStatusReport.mobileNumber | emptyToDash}}</td>
	                        <td>{{vehicleStatusReport.lastDate | date:'hh:mm:ss a MMM,dd yyyy' | emptyToDash}}</td>
	                        <td>{{vehicleStatusReport.status | emptyToDash}} </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="load-container" *ngIf="isLoading">
                <div class="pin">
                    <div class="loader fa-spin"></div>
                </div>
            </div>
    </div>
    <div *ngIf="noDataFound">
        <h5 class="text-center mt-4 no-data">No Data available</h5>
    </div>
</div>