<h5>Students</h5>
<div class="card">
	<div >
		<div class="clearfix">
			<form class="form-inline pull-right pt-2" (ngSubmit)="search()">
				 <div class="form-group mr-1">
				 	<input type="text" [ngModelOptions]="{standalone: true}" [(ngModel)]="pageRequest.searchText" class="form-control form-control-sm" id="searchText" placeholder="Search">
				 </div>
				 <button type="submit" class="btn btn-secondary btn-sm mr-1" >Search</button>
				 <a class="btn btn-secondary btn-sm" (click)="clearSearch()">Clear</a>
			</form>
		</div>
		 <table class="table table-sm  table-responsive mt-3">
		 	<thead>
		 		<tr>
		 			<th>Parent Name</th>
		 			<th>Student Name</th>
		 			<th>Student RollNumber</th>
		 			<th>Admission Number</th>
		 			<th>Student Class</th>
		 			<th>Parent Contact Number</th>
					<th>School Name</th>
					<th>Parent Email</th>
					<th>Hid Card Id</th>
		 			<th >Options</th>
		 		</tr>
		 	</thead>
		 	<tbody>
		 		<tr [hidden]="isLoading" *ngFor="let student of asyncStudent | async | paginate : {id:'studentList',itemsPerPage:pageRequest.pageSize,currentPage: pages.page, totalItems: pages.totalRecords }">
		 			<td class="pointer" (click)='goToUserManagment(student)'>{{student.parentName }}</td>
		 			<td>{{student.studentName }}</td>
		 			<td>{{(student.studentRollNumber || '')}}</td>
		 			<td>{{(student.admissionNumber || '')}}</td>
		 			<td>{{(student.studentClass || '')}}</td>
		 			<td>{{student.parentContactNumber}}</td>
		 			<td>{{student.schoolName  | emptyToDash}}</td>
		 			<td>{{student.parentEmail}}</td>
		 			<td>{{student.hidCardId}}</td>
	 				<td>
		 				<button type="button" class="btn btn-sm btn-outline-primary mr-1"  (click)="editStudent(student)">
		 					<i class="fa fa-pencil" aria-hidden="true" ></i> Edit
		 				</button>
		 				
		 			</td>
		 		</tr>
		 	</tbody>
		 </table>
		 <div *ngIf="!(pages.records && pages.records.length > 0 )" [hidden]="isLoading" class="border-dashed text-center">
		 	No Student found
		 </div>
	</div>
	<div class="load-container mb-2" *ngIf="isLoading">
		<div class="pin">
			<div class="loader fa-spin"></div>
		</div>
	</div>
	<pagination-controls (pageChange)="getPage($event)" id="studentList"></pagination-controls>

</div>
