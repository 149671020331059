<a class="p-2" (click)="goBack()">
	<i class="fa fa-chevron-left"></i>
	Back
</a>
<div class="mt-2" *ngIf="!isLoading && !isError">
	<div class="col-md-12 mt-3">
		<h5 class="pt-2">Add Group</h5>
		<div class="mt-2">
			<form novalidate [formGroup]="addGroupForm"
				(ngSubmit)="saveGroup(addGroupForm)">
				<div class="row">
					<div class="col-md-12">
						<div class="form-group">
							<label for="name">Group Name</label> <small
								class="pull-right text-danger"
								[hidden]="addGroupForm.controls.groupName.valid || addGroupForm.pristine">*Required</small>
							<input required class="form-control form-control-sm"
								id="name" formControlName="groupName" name="groupName">
						</div>
					</div>
					<div class="col-md-12">
						<div class="form-group">
							<label for="name">Group Description</label> <small
								class="pull-right text-danger"
								[hidden]="addGroupForm.controls.groupDescription.valid || addGroupForm.pristine">*Required</small>
							<input required class="form-control form-control-sm"
								id="name" formControlName="groupDescription" name="groupDescription">
						</div>
					</div>
					<div class="col-md-12">
						<div class="form-group">
							<label for="name" class='contact'>Contacts</label> <small
								class="pull-right text-danger"
								[hidden]="addGroupForm.controls.contacts.valid || addGroupForm.pristine">*Required</small>
								<br>
							<p-multiSelect class="multiselect-box" [options]="contacts"   formControlName="contacts"></p-multiSelect>
						</div>
					</div>
					<div class="col-md-12">
						<button type="submit" [disabled]="isSubmitting" class="btn btn-sm btn-info">
							<i class="fa fa-spin fa-circle-o-notch" [hidden]="!isSubmitting"></i>
							Save
						</button>
					</div>
				</div>
			</form>
		</div>
	</div>
</div>
<div class="mt-2" *ngIf="isLoading && !isError">
	<div class="load-container">
		<div class="pin">
			<div class="loader fa-spin"></div>
		</div>
	</div>
</div>
<h3 class="text-center text-warning" *ngIf="isError">
	No Data <br /> <img
		src="assets/images/icons/cloud-computing-error.png" />
</h3>
