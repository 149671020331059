<div>
    <div *ngIf="!noDataFound">
    	<div>
	        <div *ngIf="!isLoading">
	            <table class="table table-sm mt-3 table-responsive" datatable [dtOptions]="dtOptions">
	                <thead>
	                    <tr>
	                        <th>User Name</th>
	                        <th>Coordinator</th>
	                        <th>Device Name</th>
	                        <th>Device IMEI Number</th>
	                        <th>Date Allocation</th>
	                    </tr>
	                </thead>
	                <tbody>
	                    <tr *ngFor="let summaryReport of summaryReportListAsync;let i=index;" [hidden]="isLoading">
	                        <td>{{summaryReport.userName | emptyToDash}}</td>
	                        <td>{{summaryReport.parentUserName | emptyToDash}}</td>
	                        <td>{{summaryReport.deviceName | emptyToDash}}</td>
   	                        <td>{{summaryReport.deviceImeiNnumber | emptyToDash}}</td>
   	                        <td>{{summaryReport.dateAllocation | date:'hh:mm:ss a MMM,dd yyyy' | emptyToDash}}</td>
	                    </tr>
	                </tbody>
	            </table>
	            <!-- <pagination-controls (pageChange)="getPage($event)" id="summaryReportList"></pagination-controls> -->
	        </div>
	        <div class="load-container" *ngIf="isLoading">
	            <div class="pin">
	                <div class="loader fa-spin"></div>
	            </div>
	        </div>
        </div>
    </div>
    <div *ngIf="noDataFound" class="mt-10">
        <h5 class="text-center no-data">No data available</h5>
    </div>
</div>