
<div class="col-md-12">
	<h5>Add Vehicle</h5>
	<hr />
	<div *ngIf="!isLoading" class="mt-2">
		<form novalidate (ngSubmit)="saveVehicle(vehicleForm)" [formGroup]="vehicleForm">
			<div class="row">
				<div class="col-sm-12">
					<div class="form-group">
						<label>Vehicle Name</label>
						<small class="pull-right text-danger" [hidden]="!(vehicleForm.controls.vehicleDisplayName.errors && vehicleForm.controls.vehicleDisplayName.errors.required)">*Required</small>
						<input type="text" class="form-control form-control-sm" formControlName="vehicleDisplayName"/>
						<span class="text-danger" [hidden]="!(vehicleForm.controls.vehicleDisplayName.errors && vehicleForm.controls.vehicleDisplayName.errors.pattern)">
							<i class="fa fa-exclamation-triangle" aria-hidden="true"></i>&nbsp;Please enter a valid name
						</span>
					</div>
				</div>
				<div class="col-sm-12">
					<div class="form-group">
						<label>Vehicle Type</label>
						<small class="pull-right text-danger" [hidden]="!(vehicleForm.controls.vehicleType.errors && vehicleForm.controls.vehicleType.errors.required)">*Required</small>
						<select class="form-control form-control-sm" formControlName="vehicleType" (change)="vehicleTypeChanged()">
							<option value="car" selected>Car</option>
							<option value="bus" selected>Bus</option>
						</select>
					</div>
				</div>
				<div class="col-sm-12">
					<div class="form-group">
						<label>Model</label>
						<small class="pull-right text-danger" [hidden]="!(vehicleForm.controls.vehicleModel.errors && vehicleForm.controls.vehicleModel.errors.required)">*Required</small>
						<select class="form-control form-control-sm" formControlName="vehicleModel">
							<option *ngFor="let model of modelList" [value]="model.value">{{model.label}}</option>
						</select>
					</div>
				</div>
				<div class="col-sm-12">
					<div class="form-group">
						<label>Registration Number</label>
						<small class="pull-right text-danger" [hidden]="!(vehicleForm.controls.vehicleNumber.errors && vehicleForm.controls.vehicleNumber.errors.required)">*Required</small>
						<input type="text" class="form-control form-control-sm" formControlName="vehicleNumber"/>
						<span class="text-danger" [hidden]="!(vehicleForm.controls.vehicleNumber.errors && vehicleForm.controls.vehicleNumber.errors.pattern)">
							<i class="fa fa-exclamation-triangle" aria-hidden="true"></i>&nbsp;Please enter a valid registration number
						</span>
					</div>
				</div>
				<div class="col-sm-12">
					<div class="form-group">
						<label>Device</label>
						<small class="pull-right text-danger" [hidden]="!(vehicleForm.controls.deviceId.errors && vehicleForm.controls.deviceId.errors.required)">*Required</small>
						<select class="form-control form-control-sm" formControlName="deviceId">
							<option value="">Select</option>
							<option *ngFor="let device of deviceList" [value]="device.deviceId">{{device.name + ' - ' + device.imei}}</option>
						</select>
					</div>
				</div>
				<div class="col-sm-12" [hidden]="true">
					<div class="form-group">
						<label>Description</label>
						<small class="pull-right text-danger" [hidden]="!(vehicleForm.controls.vehicleDescription.errors && vehicleForm.controls.vehicleDescription.errors.required)">*Required</small>
						<textarea class="form-control form-control-sm" formControlName="vehicleDescription"></textarea>
						<span class="text-danger" [hidden]="!(vehicleForm.controls.vehicleDescription.errors && vehicleForm.controls.vehicleDescription.errors.pattern)">
							<i class="fa fa-exclamation-triangle" aria-hidden="true"></i>&nbsp;Please enter a valid description
						</span>
					</div>
				</div>
				<div class="col-sm-12">
					<button class="btn btn-sm btn-info" [disabled]="isSubmitting || !vehicleForm.valid">
						<i class="fa fa-spin fa-circle-o-notch" [hidden]="!isSubmitting"></i>
						Add
					</button>
				</div>
			</div>
		</form>
	</div>
	<div class="clearfix" *ngIf="isLoading">
		<div class="load-container" >
			<div class="pin">
				<div class="loader fa-spin"></div>
			</div>
		</div>
	</div>
</div>