<div class="row">
	<div class="col-md-8">
		<div class="row">
			<div class="col-sm-12">

				<div class="form-group">
					<label>Select Group</label> <select
						class="form-control form-control-sm" (change)="getGroup($event)"
						[(ngModel)]="groupId">
						<option [selected]="true" [value]="-1">Select</option>
						<option *ngFor="let group of groups" [value]="group.groupId">{{group.groupName}}</option>
					</select>
				</div>
			</div>
		</div>
		<span *ngIf="!isValid" class="text-danger">* No Group Selected!</span>
		<div class="row">
			<div class="col-md-12">
				<div class="row">
					<div class="col-md-12">
						Click <a class="a" (click)="downloadTemplate()">here</a> to
						download template <i class="fa fa-spin fa-circle-o-notch"
							*ngIf="isLoadingTemplate"></i>
					</div>
					<div class="col-md-12 mt-2">
						<it-upload (onUpload)="onUpload($event)" [accept]="'.xlsx'"
							(onError)="onUploadError($event)"></it-upload>
					</div>
				</div>
			</div>
		</div>

		<div class="col-md-12 mt-3" *ngIf="!showRecords">
			<h5>
				User Upload History <span class="pull-right">
					<button class="btn btn-sm btn-outline-primary"
						(click)="fetchUploadHistory()">
						<i class="fa fa-refresh"></i>
					</button>
				</span>
			</h5>
			<table class="table table-sm table-striped">
				<thead>
					<tr>
						<th>File Name</th>
						<th>Total Records</th>
						<th>Total Success</th>
						<th>Total Errors</th>
						<th>Upload Status</th>
						<th>Pending Status</th>
						<th>Upload Date</th>
					</tr>
				</thead>
				<tbody>
					<tr [hidden]="isLoading" *ngFor="let item of asyncResult">
						<td>{{item.fileName}}</td>
						<td><a class="a" (click)="fetchRecords(item.bulkId, 'all')">{{item.totalRecords}}</a></td>
						<td class="text-success"><a (click)="fetchRecords(item.bulkId, 'success')">{{item.totalSuccess}}</a></td>
						<td class="text-danger"><a (click)="fetchRecords(item.bulkId, 'failed')">{{item.totalFailed}}</a></td>
						<td>{{item.uploadStatus}}</td>
						<td>{{item.processStatus}}</td>
						<td>{{item.uploadDate | date:'hh:mm:ss a MMM,dd yyyy'}}</td>
					</tr>
				</tbody>
			</table>
			<div class="load-container mb-2" *ngIf="isLoading">
				<div class="pin">
					<div class="loader fa-spin"></div>
				</div>
			</div>
		</div>
		<div *ngIf="showRecords">
			<it-bulk-user-upload-history [bulkId]="bulkId" [recordType]="recordType" (showRecords) = "changeState($event)"></it-bulk-user-upload-history>
		</div>
		
	</div>
</div>
