<div class="row">
	<div class="col-md-7 col-sm-12">
		<div class="row">
			<div class="col-12 d-none d-sm-block">
				<!-- placeholder for act as -->
				<it-act-as></it-act-as>
			</div>
			<div class="col-12 mt-4"  *appShowAuthed="'VEHICLE_STATUS'">
				<it-live-status-cards *ngIf="!isLoading" [data]="data"></it-live-status-cards>
			</div>
		</div>

	</div>
	<div class="col-md-3 offset-md-1 pl-2 pr-2 d-none d-md-block">
		<it-live-status-chart *ngIf="!isLoading && !noChart" [running]="data.start" [stopped]="data.stop" [idle]="data.idle" [noData]="data.no_status" [neverData]="data.never_data"></it-live-status-chart>
		<div class="clearfix no-data-for-pie-chart" *ngIf="noChart">
			<h2 class="m-0 text-danger"><i class="fa fa-ban" aria-hidden="true"></i></h2>
			The chart contains no data
		</div>
	</div>
	<div class="col-md-12" *ngIf="isLoading">
		<div class="load-container">
			<div class="pin">
				<div class="loader fa-spin"></div>
			</div>
		</div>
	</div>
</div>
