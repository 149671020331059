 <div class="toolbar-container mb-0" #toolbarDiv>
	<nav class="navbar navbar-toggleable-sm navbar-expand-sm" role="navigation">
		<span class="navbar-brand" href="javascript:void(0)">
			<!-- 
			<span class="side-nav-toggle" [ngClass]="{'open':this._nav.IS_SIDE_NAV_VISIBLE}" (click)="toggleNav()">
				<i class="fa" [ngClass]="{'fa-times pr-1px':this._nav.IS_SIDE_NAV_VISIBLE,'fa-bars':!this._nav.IS_SIDE_NAV_VISIBLE}"></i>
			</span>
			 -->
			
			<span class="brand-logo-image nomobile"></span>
			<span class="brand mr-2 nomobile">
				{{(tenant?tenant.tenant.name:'')}}
			</span>
			<span (click)="toggleProfile()" class="clearfix">
				<span class="user-profile-image onlymobile">
					<img [src]="profile.imageencoded" alt="">
				</span>
				<span class="user-profile-text">
					<span class="brand user-profile-hello onlymobile">
						Hello
					</span>
					<br />
					<span class="user-profile-name onlymobile">
						{{profile.profile.firstname}}
					</span>
				</span>
			</span>
		</span>
		<button class="navbar-toggler main-nav-toggle" type="button" data-toggle="collapse" data-target="#mainnav" aria-controls="mainnav" aria-expanded="false" aria-label="Toggle navigation">
			<span class="navbar-toggler-icon">
				<i class="fa fa-bars" aria-hidden="true"></i>
				<span class="notificationCount" *appShowAuthed="'INAPP_NOTIFICATION'">{{count}}</span>
			</span>
		</button>
		<span class="mr-auto ml-auto">
		<!-- 	<it-live-stats></it-live-stats> -->
		</span>
		<div class="collapse navbar-collapse justify-content-end" id="mainnav">
			<ul class="navbar-nav">
				<li class="nav-item" *appShowAuthed="'HOME'">
					<a class="nav-link d-none d-sm-block" [routerLink]=" '/app/dashboard' | urlModifier" [routerLinkActiveOptions]="{exact:false}" routerLinkActive="active">
						<i class="fa fa-home" aria-hidden="true"></i>
						Home
					</a>
				</li>
				<li class="nav-item" *appShowAuthed="'MY_VEHICLES'">
					<a class="nav-link" [routerLink]=" '/app/vehicles' | urlModifier" [routerLinkActiveOptions]="{exact:false}" routerLinkActive="active">
						<i class="fa fa-car" aria-hidden="true"></i>
						My Vehicles
					</a>
				</li>
				<li class="nav-item" *appShowAuthed="'REPORTS'">
					<a class="nav-link" [routerLink]=" '/app/reports' | urlModifier" [routerLinkActiveOptions]="{exact:false}" routerLinkActive="active">
						<i class="fa fa-area-chart" aria-hidden="true"></i>
						Reports
					</a>
				</li>
				<li class="nav-item dropdown" *appShowAuthed="'ADMIN'">
					<a class="nav-link dropdown-toggle" itDropDown>
						<i class="fa fa-cogs" aria-hidden="true"></i>
						Admin
					</a>
					<div class="dropdown-menu options-dropdown">
						 <a class="dropdown-item"  [routerLink]=" '/app/devices' | urlModifier" [routerLinkActiveOptions]="{exact:false}" routerLinkActive="active">
						 	<i class="fa fa-microchip" aria-hidden="true"></i>
							Devices
						 </a>
						 <a class="dropdown-item"  [routerLink]=" '/app/drivers' | urlModifier" [routerLinkActiveOptions]="{exact:false}" routerLinkActive="active" *appShowAuthed="'ADD_DRIVER'">
						 	<i class="fa fa-road" aria-hidden="true"></i>
							Drivers
						 </a>
						<a class="dropdown-item" [routerLink]=" '/app/fence' | urlModifier" [routerLinkActiveOptions]="{exact:false}" routerLinkActive="active">
							<i class="fa fa-map-o" aria-hidden="true"></i>
							Fences
						</a>
						<a class="dropdown-item d-none d-sm-block" [routerLink]=" '/app/uploads' | urlModifier" [routerLinkActiveOptions]="{exact:false}" routerLinkActive="active">
							<i class="fa fa-cloud-upload" aria-hidden="true"></i>
							Bulk Operations
						</a>
					    <a *appShowAuthed="'ROUTE_DASHBOARD'" class="dropdown-item d-none d-sm-block" [routerLink]=" '/app/routedashboard' | urlModifier" [routerLinkActiveOptions]="{exact:false}" routerLinkActive="active">
							<i class="fa fa fa-hand-paper" aria-hidden="true"></i>
							Route Dashboard
						</a>
						<a class="dropdown-item" [routerLink]=" '/app/users' | urlModifier" [routerLinkActiveOptions]="{exact:false}" routerLinkActive="active">
							<i class="fa fa-users" aria-hidden="true"></i>
							User Management
						</a>
						<a class="dropdown-item" [routerLink]=" '/app/routecreation' | urlModifier" [routerLinkActiveOptions]="{exact:false}" routerLinkActive="active" *appShowAuthed="'STOPPAGE'">
							<i class="fas fa-route"  aria-hidden="true"></i>
							Route Creation
						</a>
						<a class="dropdown-item" [routerLink]=" '/app/stoppagetime' | urlModifier" [routerLinkActiveOptions]="{exact:false}" routerLinkActive="active" *appShowAuthed="'STOPPAGE'">
							<i class="fas fa-stopwatch"  aria-hidden="true"></i>
							Stoppage
						</a>
						<a class="dropdown-item" [routerLink]=" '/app/student' | urlModifier" [routerLinkActiveOptions]="{exact:false}" routerLinkActive="active" *appShowAuthed="'STUDENT'">
							<i class="fas fa-child"  aria-hidden="true"></i>
							Student
						</a>
					</div>
				</li>
				<li class="nav-item vertical-seperator d-none d-sm-block" >
					<a class="nav-link" >
					
					</a>
				</li>
				<li class="nav-item nomobile" (click)="toggleProfile()">
					<a class="nav-link" >
						<i class="fa fa-user" aria-hidden="true"></i>
						{{userName || 'My Profile'}}
					</a>
				</li>
				<li class="nav-item" *appShowAuthed="'INAPP_NOTIFICATION'">
					<a class="nav-link notification" [routerLink]=" '/app/notification' | urlModifier" [routerLinkActiveOptions]="{exact:false}" routerLinkActive="active">
						<i class="fas fa-envelope fontSize" aria-hidden="true"></i>
						Alerts
						 <span class="badge">{{count}}</span>
					</a>
				</li>
				<li class="nav-item dropdown" *appShowAuthed="'HELP'">
					<a class="nav-link dropdown-toggle" itDropDown>
						<i class="fa fa-phone-square" aria-hidden="true"></i>
						Help
					</a>
					<div class="dropdown-menu options-dropdown">
						 <a class="dropdown-item"  >
							 {{profile.parentProfile.parentName}} ({{profile.parentMobileNumber}})
						 </a>
					</div>
				</li>
				<li class="nav-item" (click)="callLogout()">
					<a class="nav-link">
						<i class="fa fa-power-off" aria-hidden="true"></i>
						Logout
					</a>
				</li>
			</ul>
		</div>
	</nav>
	<div class="profile-splash animated fadeIn" *ngIf="isProfile" (click)="toggleProfile()"></div>
	<div class="profile-container animated bounceInRight" *ngIf="isProfile">
		<div class="clearfix" *ngIf="!isClosedProfile" >
			<a (click)="toggleProfile()" class="btn btn-outline-dark pull-right mr-2 close-my-profile-btn"><i class="fa fa-times "></i></a>
		</div>
		<it-my-profile (closeProfile)="toggleProfile()"></it-my-profile>
	</div>
</div>