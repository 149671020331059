<div>
    <div>
    	<button class="btn btn-sm btn-outline-primary"
						(click)="toggleValue()">
						<i class="fa fa-arrow-left"></i>
					</button>
        <div>
            <div *ngIf="!isLoading">
				<div class="nomobile">
                <table class="table table-sm mt-3 table-responsive" datatable [dtOptions]="dtOptions">
                    <thead>
                    	<tr>
                    		<th></th> 
                    		<th colspan="4">UserData</th>
                    		<th colspan="6">ApplicationData</th>
                    	</tr>
                        <tr>
                        	<th>Sno.</th>
                            <th>mobile</th>
                            <th>userName</th>
                            <th>email</th>
                            <th>studentClass</th>
                            <th>addmissionNumber</th>
                            <th>studentName</th>
                            <th>hidCardId</th>
                            <th>schoolCode</th>
                            <th>studentRollNumber</th>
                            <th>comment</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let record of allRecords ;let i=index;">
                       		<td data-label="Sno.">{{i}}</td>
                            <td data-label="mobile">{{record.userBean.mobile}}</td>
                            <td data-label="userName">{{record.userBean.userName}}</td>
                            <td data-label="email">{{record.userBean.email}}</td>
                            <td data-label="studentClass">{{record.userBean.applicationData.studentClass}}</td>
                            <td data-label="addmissionNumber">{{record.userBean.applicationData.addmissionNumber}}</td>
                            <td data-label="studentName">{{record.userBean.applicationData.studentName}}</td>
                            <td data-label="hidCardId">{{record.userBean.applicationData.hidCardId}}</td>
                            <td data-label="schoolCode">{{record.userBean.applicationData.schoolCode}}</td>
                            <td data-label="studentRollNumber">{{record.userBean.applicationData.studentRollNumber}}</td>
                            <td data-label="studentRollNumber">{{record.comment}}</td>
                        </tr>
                    </tbody>
                </table>
                </div>
            </div>
        </div>
    </div>
</div>