<div *ngIf="!isLoading">
	<div class="act-as-component">
		<div class="act-as-header clearfix">
			<h5 class="pull-left">Logged in as <span *ngIf="user && user.profile">{{user.profile.firstname}}</span>&nbsp;<span *ngIf="isActingAs && rootUser && rootUser.profile">by {{rootUser.profile.firstname}}&nbsp;</span></h5>
			<small class="baseline">
				<a href="javascript:void(0)" (click)="showActAs()">change</a>
			</small>
		</div>
		<div class="act-as-form">
			<div class="act-as-backdrop" *ngIf="actAsFormVisible">
				<div class="card">
					<div *ngIf="!usersLoading">
						<h6>View Application As</h6>
						<small class="text-muted">You must enable pop up blocker to use this feature</small>
						<form class="mt-3" (ngSubmit)="beginActAs(selectedUser)">
							<div class="row">
								<div class="col-12">
									<div class="form-group">
									 	<!--<label for="selectactasinput" class="thin-font">View application as</label>-->
										<select class="form-control form-control-sm" (ngModelChange)="validate()" [(ngModel)]="selectedUser" [ngModelOptions]="{standalone: true}">
											<option *ngFor="let user of myUsers" [value]="user.userId">
													{{user.profile.firstName || user.profile.firstname}} - <span class="badge badge-default">{{user.mobile}}</span>
											</option>
										</select>
									</div>
								</div>
								<div class="col-12">
									<span class="pull-right">
										<button [disabled]="loadingUserToken" type="button" class="btn btn-danger btn-sm" (click)="hideActAs()">Cancel</button>
										<button [disabled]="!isActAsValid || loadingUserToken " class="btn btn-sm btn-info ">
											<i class="fa fa-circle-o-notch fa-spin" aria-hidden="true" *ngIf="loadingUserToken"></i> Submit
										</button>
									</span>
								</div>
							</div>
						
						</form>
					</div>
					<div *ngIf="usersLoading" class="text-center">
						Loading<br /><i class="fa fa-circle-o-notch fa-spin" aria-hidden="true"></i>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
<div *ngIf="isLoading" class="text-center">
	<i class="fa fa-circle-o-notch fa-spin" aria-hidden="true"></i>
</div>