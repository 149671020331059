<!--
<it-sos-alert></it-sos-alert>
 -->
<div class="row" *ngIf="!isLoading">
	<div class="col-12">
		<div class="row">
			<div class="col-6">Device Disconnect</div>
			<div class="col-6 text-right">
				<input type="checkbox" [value]="template.notifyDeviceDisconnect"
					itSwitch (onItChange)="updateDeviceDisconnect($event)">
			</div>
		</div>
		<div class="row mt-2">
			<div class="col-6">SOS</div>
			<div class="col-6 text-right">
				<input type="checkbox" [value]="template.notifySos" itSwitch
					(onItChange)="updateSos($event)">
			</div>
		</div>
		<div class="row mt-2">
			<div class="col-6">Speed (KM/h)</div>
			<div class="col-6 text-right">
				<input [hidden]="!template.notifySpeed" type="number"
					[(ngModel)]="template.speedLimit"
					(ngModelChange)="checkSpeedValidity($event)"
					class="form-control form-control-sm input-speed" /> <input
					type="checkbox" [value]="template.notifySpeed" itSwitch
					(onItChange)="updateSpeed($event)"> <br
					[hidden]="isSpeedValid" /> <small [hidden]="isSpeedValid"
					class="text-danger">*Please enter a number between 50KM/h
					and 150KM/h</small>
			</div>
		</div>
		<div class="row mt-2">
			<div class="col-6">Geofence</div>
			<div class="col-6 text-right">
				<input type="checkbox" [value]="template.notifyGeofence" itSwitch
					(onItChange)="updateGeofence($event)">
			</div>
			<div class="col-12 mt-2" [hidden]="!template.notifyGeofence">
				<it-device-geofence [deviceId]="deviceId"></it-device-geofence>
			</div>
		</div>
		<div class="row mt-2" *appShowAuthed="'IGNITION_ALERT'">
			<div class="col-6">Ignition</div>
			<div class="col-6 text-right">
				<input type="checkbox" [value]="template.notifyIgnition" itSwitch
					(onItChange)="updateIgnition($event)">
			</div>
		</div>
		<div class="row mt-2" *appShowAuthed="'ETA_ALERT'">
			<div class="col-6">ETA</div>
			<div class="col-6 text-right">
				<input type="checkbox" [value]="template.notifyEta" itSwitch
					(onItChange)="updateEta($event)">
			</div>
		</div>
		<div class="row mt-2" *appShowAuthed="'LOCATION_ALERT'">
			<div class="col-6">Location Update</div>
			<div class="col-6 text-right">
				<input [hidden]="!template.notifyLocation" type="number"
					[(ngModel)]="template.locationUpdateLimit"
					(ngModelChange)="checkLocationLimitValidity($event)"
					class="form-control form-control-sm input-speed" /> <input
					type="checkbox" [value]="template.notifyLocation" itSwitch
					(onItChange)="updateLocation($event)"> <br
					[hidden]="isLocationUpdateLimitValid" /> <small
					[hidden]="isLocationUpdateLimitValid" class="text-danger">*Please
					enter a number between 10 mins and 60 mins</small>
			</div>
		</div>
		<div class="row mt-2">
			<div class="col-6">Get alerts on</div>
			<div class="col-6 text-right">
				<select class="form-control form-control-sm"
					[(ngModel)]="template.notificationType"
					(ngModelChange)="checkSpeedValidity($event)">
					<option value="NO_ALERT">No Alert</option>
					<option value="ALL">All</option>
					<option value="SMS">SMS</option>
					<option value="EMAIL">E-Mail</option>
					<option value="PUSH">Push</option>
					<option value="SMS_AND_EMAIL">SMS & E-Mail</option>
					<option value="EMAIL_AND_PUSH">E-Mail & Push</option>
					<option value="PUSH_AND_SMS">Push & SMS</option>
				</select>
			</div>
		</div>
		<div class="row mt-2">
			<div class="col-12">
				<button class="btn btn-info btn-sm"
					[disabled]="isSubmitting || alertNotChanged || !isSpeedValid"
					(click)="updateAlerts()">
					<i class="fa fa-spin fa-circle-o-notch" [hidden]="!isSubmitting"></i>&nbsp;Update
				</button>
			</div>
		</div>
	</div>
</div>
<div class="row" *ngIf="isLoading">
	<div class="col-12 text-center">
		<div class="load-container">
			<div class="pin">
				<div class="loader fa-spin"></div>
			</div>
		</div>
	</div>
</div>
