<div class="container">
	<div class="row">
		<div class="col-md-12 mb-3">
			<h1>One More Step</h1>
			<h5>Please select a plan to continue with us</h5>
		</div>
		<div class="col-md-4" *ngFor="let plan of plans">
			<div class="generic_content clearfix">
				<div class="generic_head_price clearfix">
					<div class="generic_head_content clearfix">
						<div class="head_bg"></div>
						<div class="head">
							<span>{{plan.name}}</span>
						</div>
					</div>
					<div class="generic_price_tag clearfix">
						<span class="price"> <span class="sign"><svg
									class="svg-inline--fa fa-rupee-sign fa-w-10" aria-hidden="true"
									data-fa-processed="" data-prefix="fas" data-icon="rupee-sign"
									role="img" xmlns="http://www.w3.org/2000/svg"
									viewBox="0 0 320 512">
										<path fill="currentColor"
										d="M308 96c6.627 0 12-5.373 12-12V44c0-6.627-5.373-12-12-12H12C5.373 32 0 37.373 0 44v44.748c0 6.627 5.373 12 12 12h85.28c27.308 0 48.261 9.958 60.97 27.252H12c-6.627 0-12 5.373-12 12v40c0 6.627 5.373 12 12 12h158.757c-6.217 36.086-32.961 58.632-74.757 58.632H12c-6.627 0-12 5.373-12 12v53.012c0 3.349 1.4 6.546 3.861 8.818l165.052 152.356a12.001 12.001 0 0 0 8.139 3.182h82.562c10.924 0 16.166-13.408 8.139-20.818L116.871 319.906c76.499-2.34 131.144-53.395 138.318-127.906H308c6.627 0 12-5.373 12-12v-40c0-6.627-5.373-12-12-12h-58.69c-3.486-11.541-8.28-22.246-14.252-32H308z"></path></svg>
								<!-- <i class="fas fa-rupee-sign"></i> --></span> <span class="currency">{{plan.price}}</span>
							<span class="cent">.00</span> <span class="month">/Quarterly</span>
							<br />
							<small>*Taxes Extra</small>
						</span>
					</div>
				</div>
	
				<div class="generic_feature_list">
					<ul>
						
					</ul>
				</div>
	
				<div class="generic_price_btn clearfix">
					<a class="" [routerLink]="['/','payments','begin',plan.key]">Select Plan</a>
				</div>
			</div>
		</div>
	
	</div>
</div>