<div class="pac-card" id="pac-card"
	class="col-md-12  mb-1 mt-2 text-left">
	<div id="pac-container" >
		<input id="pac-input" type="text" placeholder="Enter a location" class="col-12">
	</div>
	<div>
		<p-gmap #gmap
			[style]="{'width':'100%','height':height?height+'px':'450px'}"
			[options]="{center:{lat:mapCenter.latitude,lng:mapCenter.longitude},zoom: (mapCenter.zoom || 12),fullscreenControl:true,mapTypeControl: true,streetViewControl: false}"
			[overlays]="overlays" (onMapReady)="setMap($event)"> </p-gmap>
	</div>
</div>
