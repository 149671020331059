<div>
    <div *ngIf="!noDataFound">
    	<div *ngIf="!viewMap">
	        <div *ngIf="!isLoading">
	            <table class="table table-sm mt-3 table-responsive"  datatable [dtOptions]="dtOptions">
	                <thead>
	                    <tr>
	                        <th>S No.</th>
	                        <th>Location</th>
	                        <th  [style.width.px]="64">Date</th>
	                        <th  [style.width.px]="64">Time</th>
	                        <th>Duration</th>
	                    </tr>
	                </thead>
	                <tbody>
	                    <tr *ngFor="let stopsReport of stopsReportListSync;let i=index;" [hidden]="isLoading">
	                        <td data-label="S NO.">{{i + 1}}</td>
	                        <td data-label="Location">{{ (stopsReport.endAddress && stopsReport.endAddress.length)? stopsReport.endAddress:(stopsReport.trip_end_lat+', '+stopsReport.trip_end_long) }}</td>
	                        <td data-label="Date" [style.width.px]="64">{{stopsReport.trip_end_time | date:'MMM,dd yyyy'}}</td>
	                        <td data-label="Time" [style.width.px]="64">{{stopsReport.trip_end_time | date:'hh:mm:ss a'}}</td>
	                        <td data-label="Duration">{{stopsReport.stop_duration}}</td>
	                    </tr>
	                </tbody>
	            </table>
	        </div>
	        <div class="load-container" *ngIf="isLoading">
	            <div class="pin">
	                <div class="loader fa-spin"></div>
	            </div>
	        </div>
	    </div>
    </div>
    <div *ngIf="noDataFound" class="mt-10">
        <h5 class="text-center mt-4 no-data">No Data available</h5>
    </div>
</div>