<nav
	class="navbar navbar-expand-lg navbar-light bg-light justify-content-between"
	#nav>
	<span class="navbar-brand mb-0 h5"><i
		class="fa fa-map-marker pr-2"></i> {{tenant.tenant.name}}</span>
</nav>
<span class="page-spinner" *ngIf="microloader"> <i
	class="fa fa-spin fa-circle-o-notch"></i>
</span>
<!-- -->
<div *ngIf="!isLoading">
	<it-vehicle-google-map [mapCenter]="mapCenter" [height]="mapHeight"
		[markers]="[]" [path]="[]" *ngIf="!showErr">
	</it-vehicle-google-map>
	<div *ngIf="showErr">
		<h1 class="p-5 text-center">Trip sharing request is not available now.</h1>
	</div>
</div>