<div>
    <div *ngIf="!noDataFound">
    	<div>
	        <div *ngIf="!isLoading">
	            <table class="table table-sm mt-3 table-responsive" datatable [dtOptions]="dtOptions">
	                <thead>
	                    <tr>
	                        <th>Vehicle Name</th>
	                        <th>From Date</th>
	                        <th>To Date</th>
	                        <th>Created Date</th>
	                        <th>Shared Link</th>
	                        <th>Status</th>
	                    </tr>
	                </thead>
	              <tbody>
	                    <tr *ngFor="let shareTripReport of shareTripReportListAsync;let i=index;" [hidden]="isLoading">
	                        <td>{{shareTripReport.vehicleDisplayName | emptyToDash}}</td>
	                        <td>{{shareTripReport.startDate | date:'hh:mm:ss a dd,MMM yyyy' | emptyToDash}}</td>
	                        <td>{{shareTripReport.endDate | date:'hh:mm:ss a dd,MMM yyyy' | emptyToDash}}</td>
	                        <td>{{shareTripReport.createdTime | date:'hh:mm:ss a dd,MMM yyyy' | emptyToDash}}</td>
	                        <td>{{shareTripReport.url |slice:0:20| emptyToDash}} </td>
	                        <td>{{shareTripReport.status | emptyToDash}}</td>
	                    </tr>
	                </tbody>
	            </table>
	            <!-- <pagination-controls (pageChange)="getPage($event)" id="shareTripReportList"></pagination-controls> -->
	        </div>
	        <div class="load-container" *ngIf="isLoading">
	            <div class="pin">
	                <div class="loader fa-spin"></div>
	            </div>
	        </div>
        </div>
    </div>
    <div *ngIf="noDataFound" class="mt-10">
        <h5 class="text-center no-data">No data available</h5>
    </div>
</div>