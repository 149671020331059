<it-modal>
	<div it-modal-header>
		<h5 class="p-0 m-0">Reset Password</h5>
	</div>
	<div it-modal-body>
		<div class="clearfix" *ngIf="!isLoading">
			<div class="row">
				<div class="col-sm-6">
					<form novalidate [formGroup]="form" (ngSubmit)="changePassword(form)">
						<div class="form-group">
							<label>User ID</label>
							<input type="text" formControlName="userId" class="form-control form-control-sm">
						</div>
						<div class="form-group">
							<label>Old Password</label>
							<span class="text-danger pull-right" [hidden]="form.pristine || !(form.controls.oldPassword.errors && form.controls.oldPassword.errors.required )">*Required</span>
							<input type="password" formControlName="oldPassword" class="form-control form-control-sm">

						</div>
						<div class="form-group">
							<label>New Password</label>
							<span class="text-danger pull-right" [hidden]="form.pristine || !(form.controls.password.errors && form.controls.password.errors.required )">*Required</span>
							<input type="password" formControlName="password" class="form-control form-control-sm">
							<span class="form-text text-danger" [hidden]="form.pristine || !(form.controls.password.errors && form.controls.password.errors.pattern )">
								Please enter secure password
							</span>
						</div>
						<div class="form-group">
							<label>Confirm Password</label>
							<span class="text-danger pull-right" [hidden]="form.pristine || !(form.controls.confirmPassword.errors && form.controls.confirmPassword.errors.required )">*Required</span>
							<input type="password" formControlName="confirmPassword" class="form-control form-control-sm">
							<span class="form-text text-danger" [hidden]="form.pristine || !(form.controls.confirmPassword.errors && form.controls.confirmPassword.errors.pattern )">
								Password and confirm password must match
							</span>
						</div>
						<div class="row">
							<div class="col-sm-4 pr-0">
								<button (click)="closeModal()" class="btn btn-secondary btn-sm btn-block" type="button">Cancel</button>
							</div>
							<div class="col-sm-8">
								<button class="btn btn-info btn-sm btn-block" type="submit" [disabled]="form.invalid">
									<i class="fa fa-spin fa-circle-o-notch" *ngIf="isSubmitting"></i>&nbsp;
									Reset
								</button>
							</div>
						</div>
					</form>
				</div>
				<div class="col-sm-6 pt-1">
					<strong>Your password must contain</strong>
					<ul class="mt-2 pl-3">
						<!-- <li>At least one special character (!,@,#,$,*,&,_).</li>
						<li>At least one uppercase character. </li>
						<li>At least one lowercase character. </li>
						<li>At least one number. </li> -->
						<li>Must contain at least 8 characters and can not be more than 24 characters long.</li>
					</ul>
				</div>
			</div>
		</div>
		<div class="clearfix" *ngIf="isLoading">
			<div class="load-container">
				<div class="pin">
					<div class="loader fa-spin"></div>
				</div>
			</div>
		</div>

	</div>
</it-modal>
