<div class="pac-card" id="pac-card" class="col-md-12">
	<div class="row">
		<div class="col-md-3">
			<div id="pac-container">
				<div id="pac-container" class="row">
					<input id="pac-input-from" type="text"
						placeholder="Enter a From location" [style.width.px]="295">
				</div>
				<div id="pac-container" class="row">
					<input id="pac-input-to" type="text"
						placeholder="Enter a To location" [style.width.px]="295">
				</div>
				<div id="pac-container" class="row">
					<button class="btn btn-outline-primary btn-sm" (click)="createPath()" [disabled]="!isSearch()">Get Direction</button>
					<button class="btn btn-outline-primary btn-sm" (click)="createGeo()" [disabled]="!isEnableFence()">Create Fence</button>
					<button class="btn btn-outline-primary btn-sm" (click)="saveGeo()" [disabled]="!isSave()">Save</button>
				</div>
			</div>
			<div id="directionsList"
				class="panel panel-primary"></div>
		</div>
		<div class="col-md-9">
			<div id="map" class="show-history-dirty-google-map"></div>
		</div>
	</div>
</div>
