<div class="card" *ngIf="!isLoading">
	<div class="row">
		<div class="col-md-12">
			<div class="input-group">
				<input placeholder="IMEI Number" [(ngModel)]="searchImeiNumber"
					type="text" class="form-control" /> <span
					class="input-group-addon cursor"> <i class="fa fa-search">
						<button [disabled]="validImei()" (click)="fetchDetailsForImei()"
							class="btn btnBackgroundSearch">Search</button>
				</i>
				</span>
			</div>
		</div>
	</div>

	<div class="row" *ngIf="!isLoading && dataFound">
		<div class="col-md-6 mt-2">
			<div class="row">
				<label class="control-label col-md-6">IMEI Number</label> <input
					disabled="disabled" class="col-md-6 form control"
					[(ngModel)]="dataObj.imei">
			</div>
		</div>
		<div class="col-md-6 mt-2">
			<div class="row">
				<label class="control-label col-md-6">Direction</label> <input
					disabled="disabled" class="col-md-6 form control"
					[(ngModel)]="dataObj.course">
			</div>
		</div>
		<div class="col-md-6 mt-2">
			<div class="row">
				<label class="control-label col-md-6">Data Actual Time</label> <span
					class="outputSpan col-md-6 form control">{{dataObj.dataActualTime
					| date:'hh:mm:ss a MMM,dd yyyy' | emptyToDash}}</span>

			</div>
		</div>
		<div class="col-md-6 mt-2">
			<div class="row">
				<label class="control-label col-md-6">Data Received Time</label> <span
					class="outputSpan col-md-6 form control">{{dataObj.dataReceivedTime
					| date:'hh:mm:ss a MMM,dd yyyy' | emptyToDash}}</span>

			</div>
		</div>
		<div class="col-md-6 mt-2">
			<div class="row">
				<label class="control-label col-md-6">Ignition</label> <input
					disabled="disabled" class="col-md-6 form control"
					[(ngModel)]="dataObj.ignition">
			</div>
		</div>
		<div class="col-md-6 mt-2">
			<div class="row">
				<label class="control-label col-md-6">Latitude</label> <input
					disabled="disabled" class="col-md-6 form control"
					[(ngModel)]="dataObj.latitude">
			</div>
		</div>
		<div class="col-md-6 mt-2">
			<div class="row">
				<label class="control-label col-md-6">Longitude</label> <input
					disabled="disabled" class="col-md-6 form control"
					[(ngModel)]="dataObj.longitude">
			</div>
		</div>
		<div class="col-md-6 mt-2">
			<div class="row">
				<label class="control-label col-md-6">Power</label> <input
					disabled="disabled" class="col-md-6 form control"
					[(ngModel)]="dataObj.power">
			</div>
		</div>

		<div class="col-md-6 mt-2">
			<div class="row">
				<label class="control-label col-md-6">Satellite</label> <input
					disabled="disabled" class="col-md-6 form control"
					[(ngModel)]="dataObj.satellite">
			</div>
		</div>
		<div class="col-md-6 mt-2">
			<div class="row">
			<label class="control-label col-md-6">Speed (Km/hr)</label>
			<span class="outputSpan col-md-6 form control">
			{{( (dataObj.speed|| '00') | kontsToKmph ) | number : '1.2-2'}}</span>
			</div>
		</div>
		<div class="col-md-6 mt-2">
			<div class="row">
				<label class="control-label col-md-6">Status</label> <span
					*ngIf="dataObj.valid == 'true'"
					class="outputSpan col-md-6 form control">Valid</span> <span
					*ngIf="dataObj.valid == 'false'"
					class="outputSpan col-md-6 form control">Inalid</span>
			</div>
		</div>
		<div class="col-md-6 mt-2">
			<div class="row">
				<label class="control-label col-md-6">Protocol</label> <input
					disabled="disabled" class="col-md-6 form control"
					[(ngModel)]="dataObj.protocol">
			</div>
		</div>
		<div class="col-md-6 mt-2">
			<div class="row">
				<label class="control-label col-md-6">Received Data</label>
				<textarea class="col-md-6 form control"
					style="background-color: rgb(235, 235, 228); color: #000;"
					[(ngModel)]='dataObj.receivedData' rows="10" cols="120"
					disabled="disabled">                             
                </textarea>
			</div>
		</div>

	</div>
	<div *ngIf="!dataFound">
		<h5 class="text-center mt-4 no-data">No Data available</h5>
	</div>
</div>
<div class="load-container" [hidden]="!isLoading">
	<div class="pin">
		<div class="loader fa-spin"></div>
	</div>
</div>