<div>
    <div *ngIf="!noDataFound ">
        <div *ngIf="!viewMap && !isShowGraphLoader">
            <div *ngIf="!isLoading">
            <div class="nomobile">
                <table class="table table-sm mt-3 table-responsive" datatable [dtOptions]="dtOptions">
                    <thead>
                        <tr>
                        	<th>Bus Route</th>
                            <th>Bus No</th>
                            <th>Vehicle Name</th>
                            <th [style.width.px]="100">First Stoppage Name</th>
                            <th [style.width.px]="64">Shift</th>
                            <th>Company Arrival Time</th>
                            <th>First Stoppage Start Time</th>
                            <th>Trip Start Time</th>
                            <th>Trip End Time</th>
                            <th>Nos of User</th>
                            <th>Speed(km/h)</th>
                            <th>Total Km</th>
                            <th>Duration</th>
                            <th>Trip Duration</th>
                            <th>AC/Heater Status</th>
                            <th>Running History</th>
                            <!-- <th>Live Status</th> -->
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let busReport of busReportListSync ;let i=index;">
                        	<td data-label="Route Name">{{busReport.name}}</td>
                            <td data-label="Bus No">{{busReport.vehicleNumber}}</td>
                            <td data-label="Transporter's Name">{{busReport.vehicleName}}</td>
                            <td data-label="Destination" [style.width.px]="64">{{busReport.destinationAddress}}</td>
                            <td data-label="Shift" [style.width.px]="64">{{busReport.shift}}</td>
                            <td data-label="Company Arrival Time">{{busReport.companyArrivalTime == 0 ? 'NA' : (busReport.companyArrivalTime | date :'shortTime')}}</td>
                            <td data-label="First Stoppage Start Time">{{busReport.firstStoppageStartTime == 0 ? 'NA' :  (busReport.firstStoppageStartTime | date :'shortTime')}}</td>
                            <td data-label="Trip Start Time">{{busReport.tripStartTime == 0 ? 'NA' : (busReport.tripStartTime | date :'shortTime')}}</td>
                            <td data-label="Trip End Time">{{busReport.tripEndTime == 0 ? 'NA' :  (busReport.tripEndTime | date :'shortTime')}}</td>
                            <td data-label="User Count">{{busReport.userCount}}</td>
                            <td data-label="Avg. Speed">{{(busReport.speed|| 0) |  number:'1.0-2'}} km/h</td>
                            <td data-label="Distance">{{ (busReport.distance || 0) |  number:'1.0-2'}}</td>
                            <td data-label="Duration">{{ getDuration(busReport.companyArrivalTime,busReport.firstStoppageStartTime)}}</td>
                            <td data-label="TripDuration">{{ busReport.duration}}</td>
                             <td data-label="Duration">NA</td>
                            <td data-label="Map">
                                <a class="btn btn-secondary btn-sm ml-1" (click)="setMap(busReport)"> 
                                	<i class="fa fa-map-marker" aria-hidden="true"></i>
                                </a>
                            </td>
                           <!--  <td data-label="Graph">NA</td> -->
                          </tr>
                    </tbody>
                </table>
                </div>
            </div>
            <div class="load-container" *ngIf="isLoading">
                <div class="pin">
                    <div class="loader fa-spin"></div>
                </div>
            </div>
        </div>
    </div>
    <div *ngIf="noDataFound">
        <h5 class="text-center mt-4 no-data">No Data available</h5>
    </div>
</div>