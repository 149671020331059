<div class="col-12">
	<h5>{{title}}</h5>
	<hr />
	<div class="mt-2" *ngIf="!isLoading && !isError">
		<form (ngSubmit)="saveDevice(driver)" [formGroup]="driver">
			<div class="form-group">
				<label>
					Driver Name
				</label>
				<small class="text-danger pull-right" [hidden]="driver.pristine || !(driver.controls.name.errors && driver.controls.name.errors.required)">*Required</small>
				<input type="text" class="form-control form-control-sm" formControlName="name">
				<span class="form-text text-danger" *ngIf="driver.controls.name.errors && driver.controls.name.errors.pattern "><i class="fa fa-exclamation-triangle" aria-hidden="true"></i>&nbsp;Please enter valid name</span>
			</div>
			<div class="form-group">
				<label>
					RFID Number
				</label>
				<small class="text-danger pull-right" [hidden]="driver.pristine || !(driver.controls.rfid.errors && driver.controls.rfid.errors.required)">*Required</small>
				<input type="text" class="form-control form-control-sm"  formControlName="rfid">
				<span class="form-text text-danger" *ngIf="driver.controls.rfid.errors && driver.controls.rfid.errors.pattern"><i class="fa fa-exclamation-triangle" aria-hidden="true"></i>&nbsp;Please enter valid IMEI number</span>
			</div>
			<div class="form-group">
				<label>
					Mobile Number
				</label>
				<small class="text-danger pull-right" [hidden]="driver.pristine || !(driver.controls.mobileNumber.errors && driver.controls.mobileNumber.errors.required)">*Required</small>
				<div class="input-group input-group-sm">
					<span class="input-group-addon p-0 noborder">
						<select class="form-control form-control-sm country-code" [style.width.%]="100" [ngModelOptions]="{standalone:true}" [(ngModel)]="countryCode">
							<option value="+91">+91</option>
						</select>
					</span>
					<p-inputMask formControlName="mobileNumber" [style.width.%]="100" name="mobile" class="sign-up-mobile" mask="(999)-999-9999" [unmask]="true"></p-inputMask>
				</div>
				<span class="form-text text-danger" *ngIf="driver.controls.mobileNumber.errors && driver.controls.mobileNumber.errors.pattern"><i class="fa fa-exclamation-triangle" aria-hidden="true"></i>&nbsp;Please enter valid Mobile Number</span>
			</div>
			<button type="submit" class="btn btn-sm btn-info" [disabled]="isSubmitting || driver.invalid">
				<i class="fa fa-spin fa-circle-o-notch" [hidden]="!isSubmitting"></i>
				Add Driver
			</button>
		</form>
	</div>
	<div class="mt-2" *ngIf="isLoading && !isError">
		<div class="load-container">
			<div class="pin">
				<div class="loader fa-spin"></div>
			</div>
		</div>
	</div>
	<h3 class="text-center text-warning" *ngIf="isError">
		No Data <br /> <img
			src="assets/images/icons/cloud-computing-error.png" />
	</h3>
</div>
