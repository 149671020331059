<div class="col-md-12">
	<div class="row">
		<div class="col-md-6 col-sm-12">
			<div class="clearfix" *ngIf="isLoading">
				<div class="load-container" >
					<div class="pin">
						<div class="loader fa-spin"></div>
					</div>
				</div>
			</div>
			<div class="clearfix" *ngIf="!isLoading">
				<form novalidate [formGroup]="transferVehicleForm" (ngSubmit)="transferVehicles()">
					<div class="row">
						<div class="col-sm-5">
							<div class="form-group">
								<label>From</label>
								<small class="text-danger pull-right" [hidden]="transferVehicleForm | validateRequired:'fromUser'">*Required</small>
								<select class="form-control form-control-sm" formControlName="fromUser">
									<option [selected]="true" [value]="''">Select</option>
									<option *ngFor="let user of userList" [value]="user.userId" [disabled]="user.userId == transferVehicleForm.value.toUser">{{(user.profile.firstName || user.profile.firstname ) +' - '+ user.mobile}}</option>
								</select>
							</div>
						</div>
						<div class="col-sm-2"></div>
						<div class="col-sm-5">
							<div class="form-group">
								<label>To</label>
								<small class="text-danger pull-right" [hidden]="transferVehicleForm | validateRequired:'toUser'">*Required</small>
								<select class="form-control form-control-sm" formControlName="toUser">
									<option [selected]="true" [value]="''">Select</option>
									<option *ngFor="let user of userList" [value]="user.userId" [disabled]="user.userId == transferVehicleForm.controls.fromUser.value">{{(user.profile.firstName || user.profile.firstname ) +' - '+ user.mobile}}</option>
								</select>	
							</div>
						</div>
					</div>
					<div class="row">
						<div class="col-sm-5 d-flex justify-content-left">
							<button [hidden]="transferVehicleForm.controls['fromUser'].disabled " [disabled]="!(transferVehicleForm.value['fromUser'] && transferVehicleForm.value['fromUser'].length > 0)" type="button" class="btn btn-sm btn-outline-info" (click)="loadVehicleForUser(transferVehicleForm.value.fromUser)">
								
								Show Vehicles
							</button>
							<button [hidden]="!transferVehicleForm.controls['fromUser'].disabled " [disabled]="isLoadingVehicleList" type="button" class="btn btn-sm btn-outline-warning" (click)="clearForm()">
								<i class="fa fa-spin fa-circle-o-notch" [hidden]="!isLoadingVehicleList"></i>&nbsp;
								Change User
							</button>
						</div>
					</div>
					<div class="row mt-3">
						<div class="col-sm-5">
							<div class="row">
								<div class="col-12" *ngIf="noVehicleAdded">
									<span class="text-danger">No Vehicles for this user</span>
								</div>
								<div class="col-12">
									<select class="form-control form-control-sm vehicle-selector" multiple formControlName="fromVehicles">
										<option *ngFor="let vehicle of fromVehicleList" [value]="vehicle.vehicleId">{{vehicle.vehicleDisplayName}} - {{vehicle.vehicleNumber}}</option>
									</select>
								</div>
							</div>
						</div>
						<div class="col-sm-2 d-flex flex-column justify-content-center">
							<button type="button" class="btn btn-sm btn-secondary" (click)="shiftRight()" [disabled]="!(fromVehicleList && fromVehicleList.length > 0)">
								<i class="fa fa-chevron-circle-right" aria-hidden="true"></i>
							</button>
							<br />
							<button type="button" class="btn btn-sm btn-secondary" (click)="shiftLeft()" [disabled]="!(toVehicleList && toVehicleList.length > 0)">
								<i class="fa fa-chevron-circle-left" aria-hidden="true"></i>
							</button>
						</div>
						<div class="col-sm-5">
							<select class="form-control form-control-sm  vehicle-selector" multiple  formControlName="toVehicles">
								<option *ngFor="let vehicle of toVehicleList" [value]="vehicle.vehicleId">{{vehicle.vehicleDisplayName}} - {{vehicle.vehicleNumber}}</option>
							</select>
						</div>
					</div>
					<div class="row mt-3">
						<div class="col-md-12 d-flex justify-content-end">
							<button class="btn btn-sm btn-info" [disabled]="!(transferVehicleForm | validateTransferDeviceForm : toVehicleList) || isSubmitting">
								<i class="fa fa-spin fa-circle-o-notch" [hidden]="!isSubmitting"></i>
								Transfer
							</button>
						</div>
					</div>
				</form>
			</div>
			
		</div>
	</div>
</div>