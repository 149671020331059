<div class=" ">
	<div class="">
		<div *ngIf="!isLoading">
			<h1 class="mb-4 text-center mt-3" *ngIf="isTenant">{{tenant.tenant.name}}</h1>
			<form novalidate class="mt-3" [formGroup]="sendotponemail"
				(ngSubmit)="sendOTPOnEmail(sendotponemail)">
				<div class="form-group">
					<label>Email</label> <input type="text" required
						placeholder="someone@example.com"
						class="form-control form-control-sm" formControlName="emailId"
						name="emailId">
				</div>
				<button class="btn btn-sm btn-success btn-block mt-1"
					[disabled]="isSubmitting">Reset Password</button>
				<p class="mt-3">
					Go back to <a routerLink="/identity/login">Login</a>
				</p>
				<p class="mt-3">
					<a routerLink="/identity/forget-password">Already have OTP?</a>
				</p>
			</form>
		</div>
		<div *ngIf="isLoading">
			<div class="load-container">
				<div class="pin">
					<div class="loader fa-spin"></div>
				</div>
			</div>
		</div>
	</div>
</div>
