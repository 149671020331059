<!-- Stepper -->
<div *ngFor="let recipient of keys" class="card pr-5">
	<div class="steps-form-2">
		<div>{{resultMap.get(recipient)['data'][0]['routeName']}}</div>
		<div class="steps-row-2 setup-panel-2 d-flex justify-content-between mt-2">
			<div class="steps-step-2">
				<a href="javascript:void(0)"
				(click)='goToLiveTracking(recipient)'
					class="btn btn-amber btn-rectangle-2 waves-effect ml-0"
					tooltipPosition="top">{{recipient}}</a>
			</div>
			<div class="steps-step-2"
				*ngFor="let obj of resultMap.get(recipient)['data']">
				<a href="javascript:void(0)"
				[ngClass]="{'bg-warning':(obj.status == 'warning'),'bg-danger':(obj.status == 'after'),'bg-success':(obj.status == 'before')}"
					class="btn btn-amber btn-circle-2 waves-effect ml-0" [pTooltip]="obj.title" [escape] = false tooltipPosition="top"></a>
			</div>
		</div>
	</div>
</div>
