<div class="row">
	<div *ngFor="let col of cols.children" [class]="getClass(col)">
		<div *ngIf="isRow(col)">
			<it-generate-template [confg]="col"></it-generate-template>
		</div>
		<div *ngIf="!isRow(col)">
			<it-generate-view [key]="col.widget"></it-generate-view>
		</div>
	</div>
</div>
