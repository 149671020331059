<div class="row" *ngIf="!isAutoGeofenceLoading">
	<div class="col-12">
		<div class="row">
			<div class="col-6">Auto Parking</div>
			<div class="col-6 text-right">
				<input type="checkbox" [value]="isAutoParking" itSwitch
					(onItChange)="updateAutoParking($event)">
			</div>
		</div>
	</div>
</div>
<div class="row" *ngIf="isAutoGeofenceLoading">
	<div class="col-12 text-center">
		<div class="load-container">
			<div class="pin">
				<div class="loader fa-spin"></div>
			</div>
		</div>
	</div>
</div>