<div class="col-12">
	<h5>{{title}}</h5>
	<hr />
	<div class="mt-2" *ngIf="!isLoading">
		<form (ngSubmit)="saveStudent(student)" [formGroup]="student">
			<div class="form-group">
				<label>
					Parent Name
				</label>
				<small class="text-danger pull-right" [hidden]="student.pristine || !(student.controls.parentName.errors && student.controls.parentName.errors.required)">*Required</small>
				<input type="text" class="form-control form-control-sm" formControlName="parentName">
				<span class="form-text text-danger" *ngIf="student.controls.parentName.errors && student.controls.parentName.errors.pattern "><i class="fa fa-exclamation-triangle" aria-hidden="true"></i>&nbsp;Please enter valid name</span>
			</div>
			<div class="form-group">
				<label>
					Admission Number
				</label>
				<small class="text-danger pull-right" [hidden]="student.pristine || !(student.controls.admissionNumber.errors && student.controls.admissionNumber.errors.required)">*Required</small>
				<input type="text" class="form-control form-control-sm"  formControlName="admissionNumber">
				<span class="form-text text-danger" *ngIf="student.controls.admissionNumber.errors && student.controls.admissionNumber.errors.pattern"><i class="fa fa-exclamation-triangle" aria-hidden="true"></i>&nbsp;Please enter valid admission number</span>
			</div>
			<div class="form-group">
						<label>School Name</label>
						<div class="input-group">
							 <select required class="form-control" formControlName="selectedSchoolName">
							 <option value = "" selected="selected">Please Select School</option>
									<option *ngFor="let schoolNamelist of this.schoolList"
										[value]="schoolNamelist.schoolId">{{schoolNamelist.schoolName}}
									</option>
							</select>
						</div>
						<small class="form-text text-danger" [hidden]="student.pristine || !(student.controls.selectedSchoolName.errors && student.controls.selectedSchoolName.errors.required)"><i class="fa fa-warning"></i> Please Select School Name</small>
			</div>
			<div class="form-group">
						<label>Group</label>
						<small class="form-text text-danger" [hidden]="student.groupId || !(student.controls.groupId.errors && student.controls.groupId.errors.required)"><i class="fa fa-warning"></i> Please Select School Name</small>
						<select formControlName="groupId" class="form-control form-control-sm">
							<option *ngFor="let group of groups" [value]="group.groupId">{{group.groupName}}</option>
						</select>
			</div>
			<div class="ub-section-header">
					<strong>User Company Details</strong>
			</div>
			<div class="form-group">
						<label>Company</label>
						<small class="form-text text-danger" [hidden]="student.companyId || !(student.controls.companyId.errors && student.controls.companyId.errors.required)"><i class="fa fa-warning"></i> Please Select company name</small>
						<select formControlName="companyId" class="form-control form-control-sm">
							<option *ngFor="let company of companies" [value]="company.companyId">{{company.companyName}}</option>
						</select>
			</div>
			<div *ngIf="student.value.companyId == -1">
					<div class="form-group">
						<label>Company Name</label>
						<small class="form-text text-danger" [hidden]="student.companyName || !(student.controls.companyName.errors && student.controls.companyName.errors.required)"><i class="fa fa-warning"></i> Please Select company name</small>
						<input formControlName="companyName" type="text" class="form-control form-control-sm">
						<span class="form-text text-danger" *ngIf="student.controls.companyName.errors && student.controls.companyName.errors.pattern"><i class="fa fa-exclamation-triangle" aria-hidden="true"></i>&nbsp;Please enter valid admission number</span>
					</div>
			</div>
			<div class="form-group">
				<label>
					Parent Contact Number
				</label>
				<small class="text-danger pull-right" [hidden]="student.pristine || !(student.controls.parentContactNumber.errors && student.controls.parentContactNumber.errors.required)">*Required</small>
				<div class="input-group input-group-sm">
					<span class="input-group-addon p-0 noborder">
						<select class="form-control form-control-sm country-code" [style.width.%]="100" [ngModelOptions]="{standalone:true}" [(ngModel)]="countryCode">
							<option value="+91">+91</option>
						</select>
					</span>
					<p-inputMask formControlName="parentContactNumber" [style.width.%]="100" name="mobile" class="sign-up-mobile" mask="(999)-999-9999" [unmask]="true"></p-inputMask>
				</div>
				<span class="form-text text-danger" *ngIf="student.controls.parentContactNumber.errors && student.controls.parentContactNumber.errors.pattern"><i class="fa fa-exclamation-triangle" aria-hidden="true"></i>&nbsp;Please enter valid Mobile Number</span>
			</div>
			<div class="form-group">
				<label>
					Student Name
				</label>
				<small class="text-danger pull-right" [hidden]="student.pristine || !(student.controls.studentName.errors && student.controls.studentName.errors.required)">*Required</small>
				<input type="text" class="form-control form-control-sm"  formControlName="studentName">
				<span class="form-text text-danger" *ngIf="student.controls.studentName.errors && student.controls.studentName.errors.pattern"><i class="fa fa-exclamation-triangle" aria-hidden="true"></i>&nbsp;Please enter valid number</span>
			</div>
			<div class="form-group">
				<label>
					Student RollNumber
				</label>
				<small class="text-danger pull-right" [hidden]="student.pristine || !(student.controls.studentRollNumber.errors && student.controls.studentRollNumber.errors.required)">*Required</small>
				<input type="text" class="form-control form-control-sm"  formControlName="studentRollNumber">
				<span class="form-text text-danger" *ngIf="student.controls.studentRollNumber.errors && student.controls.studentRollNumber.errors.pattern"><i class="fa fa-exclamation-triangle" aria-hidden="true"></i>&nbsp;Please enter valid roll number</span>
			</div>
			<div class="form-group">
				<label>
					Student Class
				</label>
				<small class="text-danger pull-right" [hidden]="student.pristine || !(student.controls.studentClass.errors && student.controls.studentClass.errors.required)">*Required</small>
				<input type="text" class="form-control form-control-sm"  formControlName="studentClass">
				<span class="form-text text-danger" *ngIf="student.controls.studentClass.errors && student.controls.studentClass.errors.pattern"><i class="fa fa-exclamation-triangle" aria-hidden="true"></i>&nbsp;Please enter valid class</span>
			</div>
			
			<div class="form-group">
				<label>
					Student Address
				</label>
				<small class="text-danger pull-right" [hidden]="student.pristine || !(student.controls.studentAddress.errors && student.controls.studentAddress.errors.required)">*Required</small>
				<input type="text" class="form-control form-control-sm"  formControlName="studentAddress">
				<span class="form-text text-danger" *ngIf="student.controls.studentAddress.errors && student.controls.studentAddress.errors.pattern"><i class="fa fa-exclamation-triangle" aria-hidden="true"></i>&nbsp;Please enter valid student address</span>
			</div>
			<div class="form-group">
						<label>Parent Email</label>
						<small class="text-danger pull-right" [hidden]="student.pristine || !(student.controls.parentEmail.errors && student.controls.parentEmail.errors.required)">*Required</small>
						<input type="email" formControlName="parentEmail" class="form-control form-control-sm">
						<span class="form-text text-danger" *ngIf="student.controls.parentEmail.errors && student.controls.parentEmail.errors.pattern"><i class="fa fa-exclamation-triangle" aria-hidden="true"></i>&nbsp;Please enter valid email id</span>

			</div>
			<div class="form-group">
				<label>
					Hid Card Id
				</label>
				<small class="text-danger pull-right" [hidden]="student.pristine || !(student.controls.hidCardId.errors && student.controls.hidCardId.errors.required)">*Required</small>
				<input type="text" class="form-control form-control-sm"  formControlName="hidCardId">
				<span class="form-text text-danger" *ngIf="student.controls.hidCardId.errors && student.controls.hidCardId.errors.pattern"><i class="fa fa-exclamation-triangle" aria-hidden="true"></i>&nbsp;Please enter valid hid Card Id</span>
			</div>
			<div class="form-group">
				<label>
					Enrollment Date
				</label>
				<small class="text-danger pull-right" [hidden]="student.pristine || !(student.controls.enrollmentDate.errors && student.controls.enrollmentDate.errors.required)">*Required</small>
				<p-calendar [showIcon]="true" formControlName="enrollmentDate"  [minDate]="minFromDate" [maxDate]="maxFromDate" [monthNavigator]="true" [yearNavigator]="true" [yearRange]="yearRange"></p-calendar>
				<span class="form-text text-danger" *ngIf="student.controls.enrollmentDate.errors && student.controls.enrollmentDate.errors.pattern"><i class="fa fa-exclamation-triangle" aria-hidden="true"></i>&nbsp;Please enter valid Enrollment Date</span>
			</div>
			<button type="submit" class="btn btn-sm btn-info" [disabled]="isSubmitting || student.invalid">
				<i class="fa fa-spin fa-circle-o-notch" [hidden]="!isSubmitting"></i>
				Add Student
			</button>
		</form>
	</div>
	<div class="mt-2" *ngIf="isLoading">
		<div class="load-container">
			<div class="pin">
				<div class="loader fa-spin"></div>
			</div>
		</div>
	</div>
	<!-- <h3 class="text-center text-warning" *ngIf="isError">
		No Data <br /> <img
			src="assets/images/icons/cloud-computing-error.png" />
	</h3> -->
</div>
