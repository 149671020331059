<div class="row">
    <div *ngFor="let elem of formTemplate" [class]="getWidth(elem.width)">
        <div class="form-group">
            <label>{{elem.label}}</label>
            <small class="text-danger pull-right" [hidden]="parentGroup.controls[elem.key].valid || parentGroup.controls[elem.key].untouched">
                *Required
            </small>
            <it-input-generator [input]="elem" [parentGroup]="parentGroup"></it-input-generator>
        </div>
    </div>
</div>