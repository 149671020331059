<div>
    <div *ngIf="!noDataFound">
    	<div *ngIf="!viewMap">
	        <div *ngIf="!isLoading">
	            <table class="table table-sm mt-3 table-responsive" datatable [dtOptions]="dtOptions">
	                <thead>
	                    <tr>
	                    <!-- 	<th>Driver Name</th> -->
	                        <th>Alert Type</th>
	                        <th>Message</th>
	                        <th  [style.width.px]="82">Date</th>
	                        <th>Location</th>
	                        <th  [style.width.px]="82">Time</th>
	                        <th>Notification</th>
	                        <th>Status</th>
	
	                    </tr>
	                </thead>
	                <tbody>
	                    <tr *ngFor="let dayReport of alertReportListAsync;let i=index;" [hidden]="isLoading">
	                    	<!-- <td data-label="Driver Name">{{dayReport.driverName}}</td> -->
	                        <td data-label="Alert Type">{{dayReport.alertType | emptyToDash}}</td>
	                        <td data-label="Message">{{dayReport.message | emptyToDash}}</td>
	                        <td data-label="Date" [style.width.px]="82">{{dayReport.startTime | date:'MMM,dd yyyy' | emptyToDash}}</td>
	                        <td data-label="Location">{{  (dayReport.location && dayReport.location.length)? dayReport.location: ({'latitude':dayReport.latitude,'longitude':dayReport.longitude} | googleAddress | async )}}</td>
	                        <td data-label="Time" [style.width.px]="82">{{dayReport.startTime | date:'hh:mm:ss a'}}</td>
	                        <td class="nomobile" data-label="Notification">{{dayReport.notificationType | emptyToDash}}</td>
	                        <td class="nomobile" data-label="Status">{{dayReport.status | emptyToDash}}</td>
	                    </tr>
	                </tbody>
	            </table>
	            <!-- <pagination-controls (pageChange)="getPage($event)" id="dayReportList"></pagination-controls> -->
	        </div>
	        <div class="load-container" *ngIf="isLoading">
	            <div class="pin">
	                <div class="loader fa-spin"></div>
	            </div>
	        </div>
        </div>
    </div>
    <div *ngIf="noDataFound" class="mt-10">
        <h5 class="text-center no-data">No data available</h5>
    </div>
</div>