<div>
    <div *ngIf="!noDataFound">
    	<div>
	        <div *ngIf="!isLoading">
	            <table class="table table-sm mt-3 table-responsive" datatable [dtOptions]="dtOptions">
	                <thead>
	                    <tr>
	                        <th>Invoice Number</th>
	                        <th>Invoice Date</th>
	                        <th>Active Device Count</th>
	                        <th>Tariff (in Rs)</th>
	                        <th>Total Amount</th>
	                        <th>Download</th>
	                    </tr>
	                </thead>
	                <tbody>
	                    <tr *ngFor="let billingReport of billingReportListAsync;let i=index;" [hidden]="isLoading">
	                       <td>{{billingReport.invoiceNumber | emptyToDash}} </td>
	                        <td>{{billingReport.invoiceDate | date:'hh:mm:ss a MMM,dd yyyy' | emptyToDash}}</td>
	                        <td>{{billingReport.vehicleCount}}</td>
	                        <td>{{billingReport.perDeviceRate | emptyToDash}}</td>
	                        <td>{{billingReport.totalAmount}}</td>
	                        <td>
                                <a class="btn btn-secondary btn-sm ml-1" (click)="downloadInvoice(billingReport.Url)"> 
                                	<i class="	fa fa-download" aria-hidden="true"></i>
                                </a>
	                        </td>
	                    </tr>
	                </tbody>
	            </table>
	            <!-- <pagination-controls (pageChange)="getPage($event)" id="billingReportList"></pagination-controls> -->
	        </div>
	        <div class="load-container" *ngIf="isLoading">
	            <div class="pin">
	                <div class="loader fa-spin"></div>
	            </div>
	        </div>
        </div>
    </div>
    <div *ngIf="noDataFound" class="mt-10">
        <h5 class="text-center no-data">No data available</h5>
    </div>
</div>