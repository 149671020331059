
<div *ngIf="!isLoading">
	<h1 class="mb-4 text-center mt-3">{{tenant.tenant.name}}</h1>
	<hr />
	<form novalidate (ngSubmit)="verifyMobile(otpForm)" [formGroup]="otpForm">
		<div class="form-group">
			<label>Mobile Number</label>
			<div class="input-group input-group-sm">
				<div class="input-group-addon country-codes">
					<select class="form-control form-control-sm"
						[(ngModel)]="countryCode" [ngModelOptions]="{standalone:true}">
						<option>+91</option>
						<option>+1</option>
					</select>	
				</div>
				<p-inputMask required [style.width.%]="100"
					formControlName="mobile" name="mobile" class="sign-up-mobile"
					mask="(999)-999-9999" [unmask]="true"></p-inputMask>
			</div>
		</div>
		<div class="form-group">
			<label>One Time Passcode (OTP)</label>
			<input type="text" required
				class="form-control form-control-sm" formControlName="otp">

		</div>
		<button class="btn btn-success btn-sm btn-block"
			[disabled]="isSubmitting">
			<i class="fa fa-circle-o-notch fa-spin" *ngIf="isSubmitting"></i>
			Verify
		</button>
	</form>
	<hr />
	<p>
		<a routerLink="/identity/login">Click here to Login</a>
		<!-- 	<span class="pull-right">
			<a (click)="showResend()">Resend Passcode (OTP)</a>
		</span>
	 -->
	</p>
	<hr />
</div>
<div *ngIf="isLoading"></div>
<div class="reverify-shadow animated fadeIn" *ngIf="showResendOtp"></div>
<div class="reverify-mobile-wrapper animated flipInX"
	*ngIf="showResendOtp">
	<form>
		<div class="form-group">
			<label>Mobile Number</label> <input type="text"
				class="form-control form-control-sm">
		</div>
		<div class="row">
			<div class="col">
				<a class="btn btn-outline-danger btn-sm btn-block"
					(click)="hideResend()">Cancel</a>
			</div>
			<div class="col">
				<button class="btn btn-outline-primary btn-sm btn-block">Send
					OTP</button>
			</div>
		</div>

	</form>
</div>
