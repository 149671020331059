<div class="card" *ngIf="!isLoading">
    <form *ngIf="!isShowGraph" class="form-inline d-flex align-items-stretch mb-4" [formGroup]="dailySummaryReportForm" (ngSubmit)="showReport(dailySummaryReportForm)">
        <div class="mr-5">
            <label for="from" class="form-label">From</label>
            <p-calendar [showIcon]="true" formControlName="from"   [minDate]="minFromDate" [maxDate]="maxFromDate" [monthNavigator]="true" [yearNavigator]="true" [yearRange]="yearRange"></p-calendar>
            <span class="text-danger" [hidden]="dailySummaryReportForm.pristine ||  !(dailySummaryReportForm.controls.from.errors && dailySummaryReportForm.controls.from.errors.inValidFromDate)">Invalid From Date</span>
        </div>
        <div class="mr-5">
            <label for="uniqueId" class="form-label">To</label>
            <p-calendar [showIcon]="true" formControlName="to" [maxDate]="maxToDate" [monthNavigator]="true" [yearNavigator]="true" [yearRange]="yearRange"></p-calendar>
            <span class="text-danger" [hidden]="dailySummaryReportForm.pristine ||  !(dailySummaryReportForm.controls.to.errors && dailySummaryReportForm.controls.to.errors.inValidToDate)">Invalid To Date</span>
        </div>
        <div class="mr-3 pt-3 mt-1">
            <button class="btn btn-sm btn-info"  [disabled]="dailySummaryReportForm.invalid" type="submit">Submit</button>
        </div>
    </form>
	<div>
		<div  *ngIf="isShowGraph">
		<i class="fa fa-close" (click)="hideGraph()"></i>
				<it-speed-time-chart [speedGraphData]="speedGraphData"></it-speed-time-chart>
			</div>
	</div>
    <it-daily-summary-report-list 
        *ngIf="reportVisible"
        (showGraph)="showGraph($event)"
        (page)="pageChange($event)"
        [toDate]="summaryReportFilter.to"
        [hidden]="isShowGraph"
        [fromDate]="summaryReportFilter.from">
    </it-daily-summary-report-list>
    <h5 class="text-center mt-4 no-data" *ngIf="!reportVisible">Please select a Date Range to view report</h5>
</div>

<div class="load-container" *ngIf="isLoading">
    <div class="pin">
        <div class="loader fa-spin"></div>
    </div>
</div>
