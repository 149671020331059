<div class="loader-div text-center animated slideInDown	"
	*ngIf="microLoader">
	<i class="fa fa-spin fa-circle-o-notch"></i> <br /> Loading Data
</div>
<div class="card">
	<div>
		<div class="clearfix mb-3">
			<div class="form-group mt-2">
				<form novalidate (ngSubmit)="searchVehicleAndDisplay()"
					class="form-inline form-search-vehicle">
					<input type="text" [(ngModel)]="searchText"
						[ngModelOptions]="{standalone: true}"
						class="col-6 form-control form-control-sm" id="searchText"
						placeholder="Search">
					
						<button class="btn btn-sm btn-secondary">Search</button>
						<button class="btn btn-sm btn-secondary">Refresh</button>
						<strong>Last Refresh: {{refreshTime}}</strong>
					
				</form>
				<!--div class="mt-1">
					<strong>Last Refresh: {{refreshTime}}</strong>
				</div-->
			</div>
		</div>
		<div class="clearfix">
			<pagination-controls (pageChange)="getPage($event)" id="vehicleList"></pagination-controls>
		</div>  
		<div class="clearfix"  [hidden]="isLoading">
			<small class="legend"> <span class="item pointer"><i
					class="far fa-dot-circle running-text" aria-hidden="true" (click)="filterVehicle('all')"></i> All</span><span class="item pointer"><i
					class="fa fa-circle running-text" aria-hidden="true" (click)="filterVehicle('moving')"></i> Running</span> <span
				class="item pointer"><i class="fa fa-circle idle-text"
					aria-hidden="true" (click)="filterVehicle('idle')"></i> Idle</span> <span class="item pointer"><i
					class="fa fa-circle stopped-text" aria-hidden="true" (click)="filterVehicle('stopped')"></i> Stopped</span> <span
				class="item pointer"  (click)="filterVehicle('no-data')"><i class="fa fa-circle noData-text pointer"
					aria-hidden="true"></i> No Data</span>
			</small>
		</div>
		<ul class="list-inline mb-2 mt-1" [hidden]="isLoading">
			<li class="" [hidden]="pages.totalRecords == 0"
				[class]="'list-inline-item device-list ' + (vehicle.ll | vehicleStatus)"
				[ngClass]="{'active':(vehicleId == vehicle.vehicleId)}"
				*ngFor="let vehicle of vehicleListAsync | async | paginate : {id:'vehicleList',itemsPerPage: reqest.pageSize,currentPage: pages.page, totalItems: pages.totalRecords }">
				<div class="row">
					<div class="col">
					<span *ngIf="vehicle.metaData">
					<i class="fas fa-bus-alt" [ngClass]="{'text-warning':(vehicle.metaData.indexOf('url') != -1) ,'text-danger':(vehicle.metaData.indexOf('RedBus') != -1 && vehicle.metaData.indexOf('url') == -1) }"></i>
					</span>
						<strong>{{vehicle.vehicleDisplayName}} </strong> 
						<span class="badge "
							[ngClass]="{'badge-success':('moving' == (vehicle.ll | vehicleStatus)),'badge-warning':('idle' == (vehicle.ll | vehicleStatus)),'badge-danger':('stopped' == (vehicle.ll | vehicleStatus)),'badge-default':('no-data' == (vehicle.ll | vehicleStatus)) }">{{vehicle.vehicleNumber}}&nbsp;
						</span>
						<span class="" *ngIf="vehicle.ll && vehicle.ll.battery">
						<i [class]="vehicle.ll.battery | vehicleBattery"></i>{{((vehicle.ll.battery?vehicle.ll.battery:0) | number:'1.0-0')+ '%'}}&nbsp;
						</span>
						<ng-container *appShowAuthed="'PARKING_GEOFENCE'">
						<div class="device-action-items"
							*ngIf="!isAutoParkingExist( vehicle.deviceId )">
							<div class="col-6 text-right"
								*ngIf="vehicle.deviceId != undefined && vehicle.ll">
								<input type="checkbox"
									[value]="vehicle.deviceId | geofenceExist : vehicleGeo"
									itSwitch (onItChange)="updateGeoFence(vehicle, $event)">
							</div>
						</div>
						<div class="device-action-items"
							*ngIf="isAutoParkingExist( vehicle.deviceId ) && vehicle.deviceId != undefined && vehicle.ll">
							<i class="fab fa-product-hunt"></i>
						</div>
						</ng-container>
						<div class="device-action-item-geo">
							<ng-container *ngIf="vehicle.ll">
							<button *appShowAuthed="'MONITOR_LAST_LOCATION'" type="button"
								class="btn btn-secondary btn-sm"
								[ngClass]="{'btn-outline-success':('moving' == (vehicle.ll | vehicleStatus)),'btn-outline-warning':('idle' == (vehicle.ll | vehicleStatus)),'btn-outline-danger':('stopped' == (vehicle.ll | vehicleStatus)),'btn-secondary':('no-data' == (vehicle.ll | vehicleStatus)) }"
								itRippler (click)="openLiveTrackingModal(vehicle)">
								<i class="fa fa-map-marker" aria-hidden="true"></i>
							</button>
							<button *appShowAuthed="'VEHICLE_TRACKING_STATUS'" type="button"
								class="btn btn-secondary btn-sm"
								[ngClass]="{'btn-outline-success':('moving' == (vehicle.ll | vehicleStatus)),'btn-outline-warning':('idle' == (vehicle.ll | vehicleStatus)),'btn-outline-danger':('stopped' == (vehicle.ll | vehicleStatus)),'btn-secondary':('no-data' == (vehicle.ll | vehicleStatus)) }"
								itRippler (click)="liveTrackingModal(vehicle)">
								<i class="fa fa-map-marker" aria-hidden="true"></i>
							</button>
							<button *appShowAuthed="'SHARE_LOCATION'" type="button"
								class="btn btn-secondary btn-sm" itRippler
								(click)="share(vehicle)"
								[ngClass]="{'btn-outline-success':('moving' == (vehicle.ll | vehicleStatus)),'btn-outline-warning':('idle' == (vehicle.ll | vehicleStatus)),'btn-outline-danger':('stopped' == (vehicle.ll | vehicleStatus)),'btn-secondary':('no-data' == (vehicle.ll | vehicleStatus)) }">
								<i class="fa fa-share-alt" aria-hidden="true"></i>
							</button>
							</ng-container>
							<button *appShowAuthed="'EDIT_VEHICLE'" type="button"
								class="btn btn-secondary btn-sm" itRippler
								(click)="editVehicle(vehicle.vehicleId)"
								[ngClass]="{'btn-outline-success':('moving' == (vehicle.ll | vehicleStatus)),'btn-outline-warning':('idle' == (vehicle.ll | vehicleStatus)),'btn-outline-danger':('stopped' == (vehicle.ll | vehicleStatus)),'btn-secondary':('no-data' == (vehicle.ll | vehicleStatus)) }">
								<i class="fa fa-pencil" aria-hidden="true"></i>
							</button>
						</div>
						<div *ngIf="vehicle.ll">
							<it-vehicle-live-data [ll]="vehicle.ll"
								[deviceId]="vehicle.deviceId"></it-vehicle-live-data>
						</div>
						<div *ngIf="!vehicle.ll" class="live-data-component">
							<span *ngIf="vehicle.deviceId"> <em class="text-warning">Device
									is offline</em>
							</span> <span *ngIf="!vehicle.deviceId"> <em class="text-warning">There
									is no device attached to this vehicle</em>
							</span>
						</div>
					</div>
				</div>
			</li>
			<li class="border-dashed text-center" *ngIf="pages.totalRecords == 0">
				<i class="fa fa-frown-o" aria-hidden="true"></i> <br /> You don't
				have added any vehicles click <a href="javascript:void(0)"
				(click)="addVehicle()">here</a> to add one
			</li>
		</ul>
	</div>
	<div class="load-container mb-2" *ngIf="isLoading">
		<div class="pin">
			<div class="loader fa-spin"></div>
		</div>
	</div>
	<!-- pagination will be hidden for now i'm not removing it in this sprint as this will be a breaking change and can't afford it-->
	<pagination-controls (pageChange)="getPage($event)" id="vehicleList"
		[hidden]="true"></pagination-controls>
	<div class="live-tracking-modal" *ngIf="enableLiveTrackingModal">
		<div class="live-tracking-header d-flex justify-content-between" >
			<span>
				<button type="button" class="close-live-tracking-btn"
					(click)="closeLiveTrackingModal()">
					<i class="fa fa-chevron-left"></i>&nbsp;<span class="nomobile">Back</span>&nbsp;
				</button> <span class="live-tracking-label ml-2 nomobile"
				*ngIf="!isTodayTrip"> Live Tracking -
					{{vehicle.vehicleDisplayName}}&nbsp; <span
					class="badge badge-primary"> {{vehicle.vehicleNumber}} </span>
				</span>
			</span> 
			<span class="" *ngIf="!_lastLocationMqttBroker.status"> <p-calendar
					*ngIf="isTodayTrip && !isFindYouCar" [maxDate]="maxDate"
					class="btn btn-sm" [(ngModel)]="date"
					(onSelect)="onSelect(vehicle, $event)" readonlyInput="true"></p-calendar>
				<span class="btn btn-primary btn-sm nomobile" [style.width.px]="100"
				*ngIf="showOtherParameters">Time:
					{{this.currentTrip[this.index][2] | date : 'HH:mm:ss'}}</span> <span
				class="btn btn-sm onlymobile" [style.width.px]="100"
				*ngIf="showOtherParameters">Time:
					{{this.currentTrip[this.index][2] | date : 'HH:mm:ss'}}</span> <span
				class="btn btn-primary btn-sm nomobile" [style.width.px]="118"
				*ngIf="showOtherParameters">Speed:
					{{this.currentTrip[this.index][3] * 1.852 |
					number:'1.0-2'}}&nbsp;km/hr</span> <span class="btn btn-sm onlymobile"
				[style.width.px]="118" *ngIf="showOtherParameters">Speed:
					{{this.currentTrip[this.index][3] * 1.852 |
					number:'1.0-2'}}&nbsp;km/hr</span> <span
				class="btn btn-primary btn-sm nomobile" [style.width.px]="111"
				*ngIf="showOtherParameters">Distance: {{distance |
					number:'1.0-2'}}&nbsp;km</span> <span class="btn btn-sm onlymobile"
				[style.width.px]="111" *ngIf="showOtherParameters">Distance:
					{{distance | number:'1.0-2'}}&nbsp;km</span>
				<button class="btn btn-primary btn-sm" (click)="setSpeed(1)"
					[ngClass]="{'active':(carSpeed == 1)}" [disabled]="!playing"
					*ngIf="isTodayTrip && isTodayTripPresent && !isFindYouCar">1x</button>
				<button class="btn btn-primary btn-sm" (click)="setSpeed(4)"
					[ngClass]="{'active':(carSpeed == 4)}" [disabled]="!playing"
					*ngIf="isTodayTrip && isTodayTripPresent && !isFindYouCar">4x</button>
				<button class="btn btn-primary btn-sm mr-2" (click)="setSpeed(8)"
					[ngClass]="{'active':(carSpeed == 8)}" [disabled]="!playing"
					*ngIf="isTodayTrip && isTodayTripPresent && !isFindYouCar">8x</button>
				<button class="btn btn-sm btn-outline-primary" (click)="playTrip()"
					[disabled]="playing"
					*ngIf="isTodayTrip && isTodayTripPresent && !isFindYouCar">
					<i class="fa fa-play-circle" aria-hidden="true"></i> <span
						class="nomobile">Play</span>
				</button>
				<button class="btn btn-sm btn-outline-warning" (click)="pause()"
					[disabled]="!playing"
					*ngIf="isTodayTrip && isTodayTripPresent && !isFindYouCar">
					<i class="fa fa-pause-circle" aria-hidden="true"></i> <span
						class="nomobile">Pause</span>
				</button>
				<button class="btn btn-sm btn-outline-danger" (click)="stop()"
					[disabled]="! (playing || paused)"
					*ngIf="isTodayTrip && isTodayTripPresent && !isFindYouCar">
					<i class="fa fa-stop-circle" aria-hidden="true"></i> <span
						class="nomobile">Stop</span>
				</button> 
			<span class="nomobile" *ngIf="!isTodayTrip"> 
				<i class="fas fa-ruler-vertical"></i>&nbsp;
					{{(vehicle.ll.altitude ? vehicle.ll.altitude : 0)}}&nbsp;
			</span>
			<span class="nomobile" *ngIf="!isTodayTrip"> 
				<i [class]="vehicle.ll.battery | vehicleBattery"></i>&nbsp;
					{{((vehicle.ll.battery ? vehicle.ll.battery : 0) | number : '1.0-0' )+'%'}}
			</span>
			 <span class="nomobile" *ngIf="!isTodayTrip">
			  <i class="fa fa-rocket ml-2 " aria-hidden="true"></i>
			  &nbsp; {{((vehicle.ll.speedKPH|| '0') | kontsToKmph )}}&nbsp;KM/h
			</span>
			<span class="nomobile" *ngIf="!isTodayTrip && vehicle.ll.protocol == 'teltonika'">
				<i [ngClass]="{'fa fa-check-circle-o':true}" aria-hidden="true"></i>&nbsp;{{( (vehicle.ll.rpm || '0')) | number : '1.0-0'}}&nbsp;RPM&nbsp;
			</span>
			<span class="nomobile" *ngIf="!isTodayTrip && vehicle.ll.protocol == 'teltonika' || vehicle.ll.protocol == 'iobms'">
				<i class="fas fa-car-battery text-success" aria-hidden="true"></i>{{( (vehicle.ll.voltage || '0')) | number : '1.2-2'}}&nbsp;V
			</span>
			<span *ngIf="!isTodayTrip && vehicle.ll.protocol == 'teltonika'">
			  <i [ngClass]="{'fas fa-power-off  ml-2':true,'text-danger':(vehicle.ll.ignition == 'false'),'text-success':(vehicle.ll.ignition == 'true')}" aria-hidden="true"></i>
			</span> 
			<span class="onlymobile badge badge-default" *ngIf="!isTodayTrip">
					{{(vehicle.ll.altitude || '0')}}&nbsp;ALT 
			</span>
			<span class="onlymobile badge badge-default" *ngIf="!isTodayTrip">
					{{( (vehicle.ll.voltage || '0')) | number : '1.2-2'}}&nbsp;V 
			</span>
			<span class="onlymobile badge badge-default" *ngIf="!isTodayTrip">
					{{( (vehicle.ll.rpm || '0')) | number : '1.0-0'}}&nbsp;RPM 
			</span>
			<span class="onlymobile badge badge-default" *ngIf="!isTodayTrip">
					{{( (vehicle.ll.speedKPH|| '0') | kontsToKmph )}}&nbsp;KM/h 
			</span> 
			<small class="onlymobile" *ngIf="!isTodayTrip">
					<span [itTimeAgo]="vehicle.ll.serverTime"></span>
			</small> 
			<span class="ml-2 nomobile"
				[pTooltip]="vehicle.ll.serverTime | date:'hh:mm a MMM, dd'"
				tooltipPosition="top" *ngIf="!isTodayTrip"> 
				<i class="fa fa-clock-o" aria-hidden="true"></i>&nbsp; 
				<span [itTimeAgo]="vehicle.ll.serverTime"></span>
			</span>
				<button class="close-live-tracking-btn nomobile" type="button"
					(click)="findCar(vehicle)" *ngIf="!isFindYouCar && !isTodayTrip">
					FindYourVehicle</button>
				<button class="btn btn-primary btn-sm onlymobile" type="button"
					(click)="findCar(vehicle)" *ngIf="!isFindYouCar && !isTodayTrip">
					FindYourVehicle</button>
				<button class="close-live-tracking-btn nomobile" type="button"
					(click)="showHistory(vehicle)" *ngIf="!isTodayTrip">
					<i class="fa fa-road" aria-hidden="true"></i>&nbsp; Trip
				</button>
				<button class="btn btn-primary btn-sm onlymobile" type="button"
					(click)="showHistory(vehicle)" *ngIf="!isTodayTrip">
					<i class="fa fa-road" aria-hidden="true"></i>&nbsp; Trip
				</button>
				<button class="close-live-tracking-btn nomobile" type="button"
					(click)="enableTracking(vehicle)" *ngIf="isTodayTrip">
					<i class="fa fa-road" aria-hidden="true"></i>&nbsp; Live Traking
				</button>
				<button class="btn btn-primary btn-sm onlymobile" type="button"
					(click)="enableTracking(vehicle)" *ngIf="isTodayTrip">
					<i class="fa fa-road" aria-hidden="true"></i>&nbsp; Live Traking
				</button>
			</span>
			<h5 *ngIf="_lastLocationMqttBroker.status">
				<!-- <small class="onlymobile" *ngIf="!isTodayTrip">
						<span [itTimeAgo]="vehicle.ll.serverTime"></span>
				</small> 
				<span class="ml-2 nomobile"
					[pTooltip]="vehicle.ll.serverTime | date:'hh:mm a MMM, dd'"
					tooltipPosition="top" *ngIf="!isTodayTrip"> 
					<i class="fa fa-clock-o" aria-hidden="true"></i>&nbsp; 
					<span [itTimeAgo]="vehicle.ll.serverTime"></span>
				</span> -->
				 <span class="badge badge-primary blink-one"> Connecting... </span>
			</h5>
		</div>
		
		<div class="live-battery-header d-flex justify-content-between nomobile" *ngIf="!isTodayTrip && vehicle.batteryLastData && vehicle.batteryLastData.protocol == 'iobms'">
			<span class="">
				<span>
					<span>Battery Cells - {{(vehicle.batteryLastData.betteryCellCount || '0')}}</span>
				</span>
			</span>
			<span class="">
				<span class="nomobile"> 
					B1&nbsp;{{(vehicle.batteryLastData.B1 || '0')}}v&nbsp;
				</span>
				<span class="nomobile"> 
					|&nbsp;B2&nbsp;{{(vehicle.batteryLastData.B2 || '0')}}v&nbsp;
				</span>
				<span class="nomobile"> 
					|&nbsp;B3&nbsp;{{(vehicle.batteryLastData.B3 || '0')}}v&nbsp;
				</span>
				<span class="nomobile"> 
					|&nbsp;B4&nbsp;{{(vehicle.batteryLastData.B4 || '0')}}v&nbsp;
				</span>
				<span class="nomobile"> 
					|&nbsp;B5&nbsp;{{(vehicle.batteryLastData.B5 || '0')}}v&nbsp;
				</span>
				<span class="nomobile"> 
					|&nbsp;B6&nbsp;{{(vehicle.batteryLastData.B6 || '0')}}v&nbsp;
				</span>
				<span class="nomobile"> 
					|&nbsp;B7&nbsp;{{(vehicle.batteryLastData.B7 || '0')}}v&nbsp;
				</span>
				<span class="nomobile"> 
					|&nbsp;B8&nbsp;{{(vehicle.batteryLastData.B8 || '0')}}v&nbsp;
				</span>
				<span class="nomobile"> 
					|&nbsp;B9&nbsp;{{(vehicle.batteryLastData.B9 || '0')}}v&nbsp;
				</span>
				<span class="nomobile"> 
					|&nbsp;B10&nbsp;{{(vehicle.batteryLastData.B10 || '0')}}v&nbsp;
				</span>
				<span class="nomobile"> 
					|&nbsp;B11&nbsp;{{(vehicle.batteryLastData.B11 || '0')}}v&nbsp;
				</span>
				<span class="nomobile"> 
					|&nbsp;B12&nbsp;{{(vehicle.batteryLastData.B12 || '0')}}v&nbsp;
				</span>
				<span class="nomobile"> 
					|&nbsp;B13&nbsp;{{(vehicle.batteryLastData.B13 || '0')}}v&nbsp;
				</span>
				<span class="nomobile"> 
					|&nbsp;B14&nbsp;{{(vehicle.batteryLastData.B14 || '0')}}v&nbsp;
				</span>
				<span class="nomobile"> 
					|&nbsp;B15&nbsp;{{(vehicle.batteryLastData.B15 || '0')}}v&nbsp;
				</span>
				<span class="nomobile"> 
					|&nbsp;B16&nbsp;{{(vehicle.batteryLastData.B16 || '0')}}v&nbsp;
				</span>
			</span>  
		</div>
		
		<div class="live-battery-header d-flex justify-content-between nomobile" *ngIf="!isTodayTrip && vehicle.batteryLastData && vehicle.batteryLastData.protocol == 'iobms'">
			<span class="">
				<span>
					<span>Temperature Sensors - {{(vehicle.batteryLastData.temperatureSensorCount || '0')}}</span>
				</span>
			</span>
			<span class="">
				<span class="nomobile" *ngIf="vehicle.batteryLastData.T1 != 'NC'"> 
					T1&nbsp;{{(vehicle.batteryLastData.T1 || '0')}}<span *ngIf="vehicle.batteryLastData.T1 != 'NC'">&#8451;</span>&nbsp;
				</span>
				<span class="nomobile" *ngIf="vehicle.batteryLastData.T2 != 'NC'"> 
					|&nbsp;T2&nbsp;{{(vehicle.batteryLastData.T2 || '0')}}<span *ngIf="vehicle.batteryLastData.T2 != 'NC'">&#8451;</span>&nbsp;
				</span>
				<span class="nomobile"> 
					|&nbsp;T3&nbsp;{{(vehicle.batteryLastData.T3 || '0')}}<span *ngIf="vehicle.batteryLastData.T3 != 'NC'">&#8451;</span>&nbsp;
				</span>
				<span class="nomobile"> 
					|&nbsp;T4&nbsp;{{(vehicle.batteryLastData.T4 || '0')}}<span *ngIf="vehicle.batteryLastData.T4 != 'NC'">&#8451;</span>&nbsp;
				</span>
				<span class="nomobile"> 
					|&nbsp;T5&nbsp;{{(vehicle.batteryLastData.T5 || '0')}}<span *ngIf="vehicle.batteryLastData.T5 != 'NC'">&#8451;</span>&nbsp;
				</span>
			</span>  
		</div>
		<!--div class="live-battery-header d-flex justify-content-between nomobile" *ngIf="!isTodayTrip && !vehicle.batteryLastData">
			<span class="">
				<span>
					<span>Battery Cells - 16</span>
				</span>
			</span>
			<span class="">
				<span class="nomobile"> 
					B1&nbsp;3.25v&nbsp;
				</span>
				<span class="nomobile"> 
					|&nbsp;B2&nbsp;3.23v&nbsp;
				</span>
				<span class="nomobile"> 
					|&nbsp;B3&nbsp;3.2v&nbsp;
				</span>
				<span class="nomobile"> 
					|&nbsp;B4&nbsp;3.3v&nbsp;
				</span>
				<span class="nomobile"> 
					|&nbsp;B5&nbsp;3.28v&nbsp;
				</span>
				<span class="nomobile"> 
					|&nbsp;B6&nbsp;3.23v&nbsp;
				</span>
				<span class="nomobile"> 
					|&nbsp;B7&nbsp;3.22v&nbsp;
				</span>
				<span class="nomobile"> 
					|&nbsp;B8&nbsp;3.24v&nbsp;
				</span>
				<span class="nomobile"> 
					|&nbsp;B9&nbsp;3.29v&nbsp;
				</span>
				<span class="nomobile"> 
					|&nbsp;B10&nbsp;3.27v&nbsp;
				</span>
				<span class="nomobile"> 
					|&nbsp;B11&nbsp;3.23v&nbsp;
				</span>
				<span class="nomobile"> 
					|&nbsp;B12&nbsp;3.28v&nbsp;
				</span>
				<span class="nomobile"> 
					|&nbsp;B13&nbsp;3.23v&nbsp;
				</span>
				<span class="nomobile"> 
					|&nbsp;B14&nbsp;3.29v&nbsp;
				</span>
				<span class="nomobile"> 
					|&nbsp;B15&nbsp;3.22v&nbsp;
				</span>
				<span class="nomobile"> 
					|&nbsp;B16&nbsp;3.24v&nbsp;
				</span>
			</span>  
		</div>
		<div class="live-battery-header d-flex justify-content-between nomobile" *ngIf="!isTodayTrip && !vehicle.batteryLastData">
			<span class="">
				<span>
					<span>Temperature Sensors - 5</span>
				</span>
			</span>
			<span class="">
				<span class="nomobile" > 
					T1&nbsp;24&#8451;&nbsp;
				</span>
				<span class="nomobile"> 
					|&nbsp;T2&nbsp;23.5&#8451;&nbsp;
				</span>
				<span class="nomobile"> 
					|&nbsp;T3&nbsp;24&#8451;&nbsp;
				</span>
				<span class="nomobile"> 
					|&nbsp;T4&nbsp;25&#8451;&nbsp;
				</span>
				<span class="nomobile"> 
					|&nbsp;T5&nbsp;22.5&#8451;&nbsp;
				</span>
			</span>  
		</div-->
		<div class="map-container">
			<it-live-tracking-map (currentMap)="currentMap($event)"
				(resetMap)="resetMap()" [vehicle]="vehicle"></it-live-tracking-map>
		</div>
	</div>
</div>

