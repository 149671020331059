
<div class="row mb-3">
	<div class="col-md-12">
		<ul class="nav nav-pills">
		  <li class="nav-item">
		    <a class="nav-link" [ngClass]="{'active': showContacts}" (click)="showContact()">Contacts</a>
		  </li>
		  <li class="nav-item">
		    <a class="nav-link" [ngClass]="{'active': !showContacts}" (click)="showGroup()">Groups</a>
		  </li>
		  
		</ul>
	</div>
</div>
<div class="row">
	<div class="col-md-12">
		<div *ngIf='showContacts'><it-contacts-dashboard></it-contacts-dashboard></div>
		<div *ngIf='!showContacts'><it-groups-dashboard></it-groups-dashboard></div>
	</div>
	
</div>
