<div class="mt-2" *ngIf="!isLoading && !isError">
	<a class="p-2" (click)="goBack()">
		<i class="fa fa-chevron-left"></i>
		Back
	</a>
	<div class="col-md-12 mt-3">
		<h5 class="pt-2">Edit Group</h5>
		<div class="">
			<form novalidate [formGroup]="editGroupForm"
				(ngSubmit)="updateGroup(editGroupForm)">
				<div class="row">
					<div class="col-md-12">
						<div class="form-group">
							<label for="name">Group Name</label> <small
								class="pull-right text-danger"
								[hidden]="editGroupForm.controls.groupName.valid || editGroupForm.pristine">*Required</small>
							<input required class="form-control form-control-sm"
								id="groupName" formControlName="groupName" name="groupName">
						</div>
					</div>
					<div class="col-md-12">
						<div class="form-group">
							<label for="name">Group Descriptione</label> <small
								class="pull-right text-danger"
								[hidden]="editGroupForm.controls.groupDescription.valid || editGroupForm.pristine">*Required</small>
							<input required class="form-control form-control-sm"
								id="groupDescription" formControlName="groupDescription" name="groupDescription">
						</div>
					</div>
					<div class="col-md-12">
						<div class="form-group">
							<label for="contact" class='contact'>Contacts</label> <small
								class="pull-right text-danger"
								[hidden]="editGroupForm.controls.contacts.valid || editGroupForm.pristine">*Required</small>
								<br>
							<p-multiSelect [options]="contactList" class="multiselect-box"  formControlName="contacts"></p-multiSelect>
						</div>
					</div>
					<div class="col-md-12">
						<button type="submit" [disabled]="isSubmitting" class="btn btn-sm btn-info">
							<i class="fa fa-spin fa-circle-o-notch" [hidden]="!isSubmitting"></i>
							Save
						</button>
					</div>
				</div>
			</form>
		</div>
	</div>
</div>
<div class="mt-2" *ngIf="isLoading && !isError">
	<div class="load-container">
		<div class="pin">
			<div class="loader fa-spin"></div>
		</div>
	</div>
</div>
<h3 class="text-center text-warning" *ngIf="isError">
	No Data <br /> <img
		src="assets/images/icons/cloud-computing-error.png" />
</h3>
