<div *ngIf="!isLoading && stoppageMap.size > 0">
    <it-steps [resultList]="stoppageMap"></it-steps>
</div>
 <div *ngIf="!isLoading && stoppageMap.size == 0">
        <h5 class="text-center mt-4 no-data">No Data available</h5>
 </div>
<div class="load-container" *ngIf="isLoading">
    <div class="pin">
        <div class="loader fa-spin"></div>
    </div>
</div>