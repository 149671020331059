<div *ngIf="!isLoading" class="card">
	<div class="container routeNamePadding stoppagePoints-left">
		<div class="row">
			<div class="col-2">
				<label>Route Name</label>
			</div>
			<div class="col-10">
				<select [(ngModel)]="selectedRouteId" required
					(change)="selectedRouteIds()" class="form-control">
					<option *ngFor="let routeId of this.routeNameList" [value]="routeId">{{getRouteName(routeId)}}</option>
				</select>
			</div>
		</div>
		<div class="row stoppagetimePadding" *ngIf="isrouteNameSelected">
			<div class="col-2">
					<label>From</label>
			</div>
			<div class="col-2">
				<input type="time" name="from" [(ngModel)]="from">
			</div>
			<div class="col-2" >
					<label>To</label>
			</div>
			<div class="col-2">
				<input type="time" name="to" [(ngModel)]="to">
			</div>
			<div class="col-4">
				<input type="radio" name="shift" value="Morning" [(ngModel)]="shift">Morning 
				<input type="radio" name="shift" value="Evening" [(ngModel)]="shift"> Evening
				<input type="radio" name="shift" value="Gen. Day Shift" [(ngModel)]="shift"> Gen. Day Shift
				<input type="radio" name="shift" value="Gen. Night Shift" [(ngModel)]="shift"> Gen. Night Shift
				<input type="radio" name="shift" value="A Shift" [(ngModel)]="shift"> A Shift
				<input type="radio" name="shift" value="B Shift" [(ngModel)]="shift"> B Shift
				<input type="radio" name="shift" value="C Shift" [(ngModel)]="shift"> C Shift				
			</div>
		</div>
	</div>
	<div
		class="card container stoppagetimePadding form-group stoppagePoints-left"
		*ngFor="let stoppingPointsName of stoppingPointsList;let index = index;trackBy:trackByIndex;">
		<div class="container stoppagePoints-left">
			<div class="row">
				<div class="col-2">
					<label>Stoping Points</label>
				</div>
				<div class="col-10">
					<input [(ngModel)]="stoppingPointsList[index]"
						class="form-control input-lg" readonly />
				</div>
			</div>
		</div>
		<div class="container stoppagetimePadding stoppagePoints-left">
			<div class="row">
				<div class="col-md-3" >
					<div class="row">
						<div class="col-8">
							<label for="time" class="form-label">Stoppage Time</label>
						</div>
						<div class="col-4">
							<input type="time" name="stoppingTime"
								[(ngModel)]="stoppingTime[index]">
						</div>
					</div>
				</div>
				<div class="col-md-9" *appShowAuthed="'AAHATEC_STOPPAGE_METADATA'"  id ="metaData">
					<div class="row">
						<div class="col-1/2">
							<label for="loadingReciept" class="form-label">Loading
								Reciept:</label>
						</div>
						&nbsp;&nbsp;
						<div class="col-1/2">
							<input type="text" name="loadingReciept"
								[(ngModel)]="loadingReciept[index]">
						</div>
						&nbsp;&nbsp;
						<div class="col-1/2">
							<label for="weight" class="form-label">Weight:</label>
						</div>
						&nbsp;&nbsp;
						<div class="col-1/2">
							<input type="number" name="weight" [(ngModel)]="weight[index]">
						</div>
						&nbsp;&nbsp;
						<div class="col-1/2">
							<label for="rate" class="form-label">Rate:</label>
						</div>
						&nbsp;&nbsp;
						<div class="col-1/2">
							<input type="number" name="rate" [(ngModel)]="rate[index]">
						</div>
						&nbsp;&nbsp;
						<div class="col-1/2">
							<label for="advanceGiven" class="form-label">Advance
								Given:</label>
						</div>
						&nbsp;&nbsp;
						<div class="col-1/2">
							<input type="text" name="advanceGiven"
								[(ngModel)]="advanceGiven[index]">
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
	<div class="container stoppagePoints-left" *ngIf="isrouteNameSelected">
		<div class="row">
			<div class="mr-3 pt-3  mt-1">
				<button class="btn btn-sm btn-info" type="button"
					(click)="saveStoppageTime()">Submit</button>
			</div>
		</div>
	</div>
</div>

<div class="load-container" *ngIf="isLoading">
	<div class="pin">
		<div class="loader fa-spin"></div>
	</div>
</div>
