<div class="row">
	<div class="col-md-8">
		<div class="row">
			<div class="col-md-12">
				<div class="row">
					<div class="col-md-12">
							Click <a class="a" (click)="downloadTemplate()">here</a> to download template
							<i class="fa fa-spin fa-circle-o-notch" *ngIf="isLoadingTemplate"></i>
					</div>
					<div class="col-md-12 mt-2">
						<it-upload (onUpload)="onUpload($event)" [accept]="'.xlsx'" (onError)="onUploadError($event)"></it-upload>
					</div>	
				</div>
			</div>
			 
				<div class="col-md-12 mt-3">
					<h5>
						Device Upload History
						<span class="pull-right">
							<button class="btn btn-sm btn-outline-primary" (click)="getAsyncHistory()">
								<i class="fa fa-refresh"></i>
							</button>
						</span>
					</h5>
					<table class="table table-sm table-striped">
						<thead>
							<tr>
								<th>File Name</th>
								<th>Devices</th>
								<th>Success</th>
								<th>Errors</th>
								<th>Date</th>
							</tr>
						</thead>
						<tbody>
							<tr [hidden]="isLoading" *ngFor="let item of asyncResult | async | paginate : {id:'uploadHistory',itemsPerPage:paginationRequest.pageSize,currentPage: pages.page, totalItems: pages.totalRecords }">
								<td>{{item.fileName}}</td>
								<td>{{item.totalCount}}</td>
								<td class="text-success">{{item.successCount}}</td>
								<td class="text-danger"><a class="a" (click)="downloadFile(item.errorFilePath)">{{item.failCount}}</a></td>
								<td>{{item.uploadDate | date:'hh:mm:ss a MMM,dd yyyy'}}</td>
							</tr>
						</tbody>
					</table>
					<div class="load-container mb-2" *ngIf="isLoading">
						<div class="pin">
							<div class="loader fa-spin"></div>
						</div>
					</div>
					<pagination-controls [hidden]="isLoading" (pageChange)="getPage($event)" id="uploadHistory"></pagination-controls>
				</div>
			 
		</div>
	</div>
</div>
