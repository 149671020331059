<div class="row" *ngIf="!isLoading">
	<div class="col-12">
		<div class="row">
			<div class="col-6">Engine</div>
			<div class="col-6 text-right">
			    <button  class = 'btn btn-sm btn-primary'  (click)="updateEngine( true ) " [disabled]="engineStart"><span>ON</span></button>
				<button  class = 'btn btn-sm btn-danger'   (click)="updateEngine( false )" [disabled]="!engineStart"><span>OFF</span></button>
			</div>
		</div>
	<!-- 	<div class="row mt-2">
			<div class="col-6">Engine OFF</div>
			<div class="col-6 text-right">
				<input type="checkbox" [value]="engineStop" itSwitch (onItChange)="updateEngine( false )">
			</div>
		</div> -->
	</div>
</div>
<div class="row" *ngIf="isLoading">
	<div class="col-12 text-center">
		<div class="load-container">
			<div class="pin">
				<div class="loader fa-spin"></div>
			</div>
		</div>
	</div>
</div>