<div *ngIf="!isLoading">
	<it-generate-template [confg]="state.layout" *ngIf="state.layout"></it-generate-template>
	<div class="row  pt-5" *ngIf="!state.layout">
		<div class="col-md-4 text-right">
			<img src="assets/images/icons/warning.png"/>
		</div>
		<div class="col-md-8 pt-4">
			<h3 class="text-danger">Error 404 - Page not found</h3>
			<p>The page you requested can not be found</p>
		</div>
	</div>
</div>