<div class="row" *ngIf="!isLoading">
	<div class="col-md-6">
		<div *appShowAuthed="'FETCH_ONE_VEHICLE'">
			<h5>Vehicle Details</h5>
			<div class="card">
				<form novalidate [formGroup]="vehicleForm"
					(ngSubmit)="updateVehicleDetails(vehicleForm)">
					<div class="row">
						<div class="col-6">
							<div class="form-group">
								<label>Vehicle Name</label> <small
									class="pull-right text-danger"
									[hidden]="!(vehicleForm.controls.vehicleDisplayName.errors && vehicleForm.controls.vehicleDisplayName.errors.required)">*Required</small>
								<input class="form-control form-control-sm" type="text"
									formControlName="vehicleDisplayName"> <span
									class="text-danger"
									[hidden]="!(vehicleForm.controls.vehicleDisplayName.errors && vehicleForm.controls.vehicleDisplayName.errors.pattern)">
									<i class="fa fa-exclamation-triangle" aria-hidden="true"></i>&nbsp;Please
									enter a valid name
								</span>
							</div>
						</div>
						<div class="col-6">
							<div class="form-group">
								<label>Vehicle Type</label> <small
									class="pull-right text-danger"
									[hidden]="!(vehicleForm.controls.vehicleType.errors && vehicleForm.controls.VehicleType.errors.required)">*Required</small>
								<select class="form-control form-control-sm"
									formControlName="vehicleType" (change)="vehicleTypeChanged()">
									<option value="car">Car</option>
									<option value="bus">Bus</option>
								</select>
							</div>
						</div>
						<div class="col-6">
							<div class="form-group">
								<label>Model</label> <small class="pull-right text-danger"
									[hidden]="!(vehicleForm.controls.vehicleModel.errors && vehicleForm.controls.vehicleModel.errors.required)">*Required</small>
								<select class="form-control form-control-sm"
									formControlName="vehicleModel">
									<option *ngFor="let model of modelList" [value]="model.value">{{model.label}}</option>
								</select>
							</div>
						</div>
						<div class="col-6">
							<div class="form-group">
								<label>Registration Number</label> <small
									class="pull-right text-danger"
									[hidden]="!(vehicleForm.controls.vehicleNumber.errors && vehicleForm.controls.vehicleNumber.errors.required)">*Required</small>
								<input class="form-control form-control-sm" type="text"
									formControlName="vehicleNumber"> <span
									class="text-danger"
									[hidden]="!(vehicleForm.controls.vehicleNumber.errors && vehicleForm.controls.vehicleNumber.errors.pattern)">
									<i class="fa fa-exclamation-triangle" aria-hidden="true"></i>&nbsp;Please
									enter a valid registration number
								</span>
							</div>
						</div>
						<div class="col-12" [hidden]="true">
							<div class="form-group">
								<label>Description</label>
								<textarea class="form-control form-control-sm" type="text"
									formControlName="vehicleDescription">
								</textarea>
								<span class="text-danger"
									[hidden]="!(vehicleForm.controls.vehicleDescription.errors && vehicleForm.controls.vehicleDescription.errors.pattern)">
									<i class="fa fa-exclamation-triangle" aria-hidden="true"></i>&nbsp;Please
									enter a valid description
								</span>
							</div>
						</div>
						<div class="col-12">
							<button class="btn btn-info btn-sm" type="submit"
								[disabled]="!vehicleForm.valid || vehicleForm.pristine || isSubmitting">
								<i class="fa fa-spin fa-circle-o-notch" [hidden]="!isSubmitting"></i>&nbsp;Update
							</button>

							<button class="btn btn-sm btn-danger pull-right" type="button"
								(click)="removeVehicle()">
								<i class="fa fa-trash" aria-hidden="true"></i> Delete
							</button>
						</div>
					</div>
				</form>
			</div>
		</div>
		<div *appShowAuthed="'FETCH_ONE_DEVICE'">
			<h5 class="mt-5">Device Manager</h5>
			<div class="card">
				<table class="table table-responsive" *ngIf="mappedDevice">
					<thead class="bg-gray">
						<tr>
							<th>Device Name</th>
							<th>IMEI</th>
							<th>Sim Number</th>
							<th>Mobile Number</th>
						</tr>
					</thead>
					<tbody>
						<tr>
							<td>{{mappedDevice.name}}</td>
							<td>{{mappedDevice.imei}}</td>
							<td>{{mappedDevice.simNumber}}</td>
							<td>{{mappedDevice.mobileNumber}}</td>
						</tr>
					</tbody>
				</table>
				<div class="no-alert-data text-center" *ngIf="!mappedDevice">
					<h3>
						<i class="fa fa-frown-o" aria-hidden="true"></i>
					</h3>
					No Device is attached to this vehicle
				</div>
			</div>
		</div>
		<div *appShowAuthed="'AUTOPARKING_GEOFENCE'">
			<h5 class="mt-3">Auto Parking</h5>
			<div class="card">
				<it-autoparking-alert *ngIf="deviceId" [vehicle]="vehicle"></it-autoparking-alert>
				<div class="no-alert-data text-center" *ngIf="!deviceId">
					<h3>
						<i class="fa fa-frown-o" aria-hidden="true"></i>
					</h3>
					Please Attach a device with this vehicle
				</div>
			</div>
		</div>
		<div *appShowAuthed="'MAP_UNMAP_ROUTE'">
			<h5 class="mt-3">Routes</h5>
			<div class="card">
				<it-vehicle-route-mapping [vehicleId]="vehicle.vehicleId"
					[deviceId]="deviceId"></it-vehicle-route-mapping>
			</div>
		</div>
	</div>
	<div class="col-md-6">
		<div *appShowAuthed="'FETCH_DEVICE_ALERT_PREFERENCES'">
			<h5>Alerts</h5>
			<div class="card">
				<it-vehicle-alert *ngIf="deviceId" [vehicle]="vehicle"
					[mappedDevice]="mappedDevice"></it-vehicle-alert>
				<div class="no-alert-data text-center" *ngIf="!deviceId">
					<h3>
						<i class="fa fa-frown-o" aria-hidden="true"></i>
					</h3>
					Please Attach a device with this vehicle
				</div>
			</div>
		</div>
		<div *appShowAuthed="'IMMOBILIZER'">
			<h5 class="mt-3">Immobilizer</h5>
			<div class="card">
				<it-mobilizer-alert *ngIf="deviceId" [deviceId]="deviceId"
					[mobilizer]="mappedDevice.immobilizer"></it-mobilizer-alert>
				<div class="no-alert-data text-center" *ngIf="!deviceId">
					<h3>
						<i class="fa fa-frown-o" aria-hidden="true"></i>
					</h3>
					Please Attach a device with this vehicle
				</div>
			</div>
		</div>
		<div *appShowAuthed="'ROUTE_GEOFENCE'">
			<h5 class="mt-3">Route Geofence</h5>
			<div class="card">
				<it-route-geofence-alert *ngIf="deviceId" [deviceId]="deviceId"></it-route-geofence-alert>
				<div class="no-alert-data text-center" *ngIf="!deviceId">
					<h3>
						<i class="fa fa-frown-o" aria-hidden="true"></i>
					</h3>
					Please Attach a device with this vehicle
				</div>
			</div>
		</div>
	</div>
</div>
<div *ngIf="isLoading">
	<div class="load-container">
		<div class="pin">
			<div class="loader fa-spin"></div>
		</div>
	</div>
</div>