<div *ngIf="isLoading">
	<div class="load-container">
		<div class="pin">
			<div class="loader fa-spin"></div>
		</div>
	</div>
</div>
<div *ngIf="!isLoading">
	<ul class="list-group">
		<li class="list-group-item" *ngFor="let fence of mappedFences">
			{{fence.name}}<strong class="badge badge-info ml-1">Geo-Alert</strong> 
			<span class="pull-right">
				<a><img src="assets/images/icons/garbage.png" width="20" (click)="removeMapping(fence)"/></a>
			</span>
		</li>
	</ul>
	<div class="add-alert-form">
		<div class="input-group mt-2" *ngIf="!formLoading">
			<p-dropdown [options]="fences | filterGeofenceList:mappedFences" [style.width.%]="100" [(ngModel)]="selectedFence" placeholder="Select a Fence" optionField="name"></p-dropdown>
			<div class="input-group-btn">
				<button class="btn btn-sm btn-secondary p-btn-sm" (click)="addFence()" [disabled]="(selectedFence == null) || isSubmitting	">
					<i class="fa fa-spin fa-circle-o-notch" [hidden]="!isSubmitting"></i>
					Add
				</button>
			</div>
		</div>
		
	</div>
</div>