<h5>Notifications</h5>
<div class="card">
	<div >
		 <table datatable class="table table-sm  table-responsive mt-3">
		 	<thead>
		 		<tr>
		 			<th *appShowAuthed="'INAPP_NOTIFICATION_ALERT_TYPE'">Alert Type</th>
		 			<th *appShowAuthed="'INAPP_NOTIFICATION_MESSAGE'">Message</th>
		 			<th *appShowAuthed="'INAPP_NOTIFICATION_START_TIME'">Start Time</th>
		 			<th *appShowAuthed="'INAPP_NOTIFICATION_LATITUDE'">Latitude</th>
		 			<th *appShowAuthed="'INAPP_NOTIFICATION_LONGITUDE'">Longitude</th>
		 			<!-- <th *appShowAuthed="'INAPP_NOTIFICATION_VEHICLE_NAME'">Vehicle Name</th> -->
 		</tr>
		 	</thead>
		 	<tbody>
		 		<tr [hidden]="isLoading"  *ngFor="let notification of asyncNotifications | async | paginate : {id:'notificationList',itemsPerPage:pageRequest.pageSize,currentPage: pages.page, totalItems: pages.totalRecords }">
		 			<td data-label="Alert Type"  *appShowAuthed="'INAPP_NOTIFICATION_ALERT_TYPE'">{{notification.alertType }}</td>
		 			<td data-label  *appShowAuthed="'INAPP_NOTIFICATION_M1xESSAGE'">{{notification.message }}</td>
	                <td  data-label="Start Time"  *appShowAuthed="'INAPP_NOTIFICATION_START_TIME'">{{notification.startTime | date:'hh:mm:ss a dd,MMM yyyy' | emptyToDash}}</td>
		 			<td  data-label="Latitude" *appShowAuthed="'INAPP_NOTIFICATION_LATITUDE'">{{(notification.latitude || '')}}</td>
		 			<td  data-label="Longitude"  *appShowAuthed="'INAPP_NOTIFICATION_LONGITUDE'">{{(notification.longitude || '')}}</td>
		 			<!-- <td  data-label="Vehicle Name"  *appShowAuthed="'INAPP_NOTIFICATION_VEHICLE_NAME'">{{(notification.vehicleDisplayName + ' ' + notification.vehicleNumber || '')}}</td> -->
		 		</tr>
		 	</tbody>
		 </table>
		 <div *ngIf="!(pages.records && pages.records.length > 0 )" [hidden]="isLoading" class="border-dashed text-center">
		 	No Notification found
		 </div>
	</div>
	<div class="load-container mb-2" *ngIf="isLoading">
		<div class="pin">
			<div class="loader fa-spin"></div>
		</div>
	</div>
	<pagination-controls (pageChange)="getPage($event)" id="notificationList"></pagination-controls>

</div>
