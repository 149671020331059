<div class="card" *ngIf="!isLoading">

   <form class="form-inline d-flex align-items-stretch mb-3" [formGroup]="dayReportForm" (ngSubmit)="showReport(dayReportForm)">
        <div class="mr-3">
            <label for="uniqueId" class="form-label">Vehicle</label>
            <select class="form-control form-control-sm" id="uniqueId" formControlName="uniqueId" [value]="uniqueId" >
                <option *ngFor="let vehicle of vehicleList" [value]="vehicle.deviceId" >
                    {{vehicle.vehicleDisplayName}}&nbsp;<span class="badge bade-default">{{vehicle.vehicleNumber}}</span>
                </option>
            </select>
            <span class="text-danger" [style.display]="'block'" [hidden]="dayReportForm.pristine ||  !(dayReportForm.controls.uniqueId.errors && dayReportForm.controls.uniqueId.errors.required)">This field is required</span>
        </div>
        <div class="mr-5">
            <label for="from" class="form-label">From</label>
            <p-calendar [showIcon]="true" formControlName="from" [minDate]="minFromDate" [maxDate]="maxFromDate" [monthNavigator]="true" [yearNavigator]="true" [yearRange]="yearRange"></p-calendar>
            <span class="text-danger" [hidden]="dayReportForm.pristine ||  !(dayReportForm.controls.from.errors && dayReportForm.controls.from.errors.inValidFromDate)">Invalid From Date</span>
        </div>
        <div class="mr-5">
            <label for="uniqueId" class="form-label">To</label>
            <p-calendar [showIcon]="true" formControlName="to" [maxDate]="maxToDate" [monthNavigator]="true" [yearNavigator]="true" [yearRange]="yearRange"></p-calendar>
            <span class="text-danger" [hidden]="dayReportForm.pristine ||  !(dayReportForm.controls.to.errors && dayReportForm.controls.to.errors.inValidToDate)">Invalid To Date</span>
        </div>
        <div class="mr-3 pt-3  mt-1">
            <button class="btn btn-sm btn-info"  [disabled]="dayReportForm.invalid" type="submit">Submit</button>
        </div>
    </form>

    <it-stops-report-list
        class="mt-2"
        (page)="pageChange($event)"
        [uniqueId]="dayReportFilter.uniqueId"
        [toDate]="dayReportFilter.to"
        [fromDate]="dayReportFilter.from"
        *ngIf="reportVisible">
    </it-stops-report-list>

    <h5 class="text-center mt-4 no-data" *ngIf="!reportVisible">Please select a vehicle to view report</h5>
</div>
<div class="load-container" *ngIf="isLoading">
    <div class="pin">
        <div class="loader fa-spin"></div>
    </div>
</div>
