<div>
    <div *ngIf="!noDataFound">
    	<div *ngIf="!viewMap">
	        <div *ngIf="!isLoading">
	            <table class="table table-sm mt-3 table-responsive"  datatable [dtOptions]="dtOptions">
	                <thead>
	                    <tr>
	                        <th>S No.</th>
	                        <th>Bus Reg. No.</th>
	                        <th>Route</th>
	                        <th>Latitude</th>
	                        <th>Longitude</th>
	                        <th>Address</th>
	                        <th>Date & Time</th>
	                        <th>Card UID</th>
	                        <th>Student Name</th>
	                        <th>Admission Number</th>
	                        <th>class</th>
	                        <th>Contact No.</th>
	                        <th>Shared Vehicle</th>
	                        <th>Shared Route</th>
	                    </tr>
	                </thead>
	                <tbody>
	                    <tr *ngFor="let attendanceReport of attendanceReportListSync;let i=index;" [hidden]="isLoading">
	                        <td>{{i + 1}}</td>
	                        <td>{{attendanceReport.vehicleNumber}}</td>
	                        <td>{{attendanceReport.routeName}}</td>
	                        <td>{{attendanceReport.lat}}</td>
	                        <td>{{attendanceReport.lng}}</td>
	                        <td>{{attendanceReport.address}}</td>
	                        <td>{{attendanceReport.date}}</td>
	                        <td>{{attendanceReport.rfid}}</td>
	                        <td>{{attendanceReport.studentName}}</td>
	                        <td>{{attendanceReport.admissionNumber}}</td>
	                        <td>{{attendanceReport.studentClass}}</td>
	                        <td>{{attendanceReport.parentContactNumber}}</td>
	                        <td>{{attendanceReport.sharedVehicle}}</td>
	                        <td>{{attendanceReport.sharedRoute}}</td>
	                    </tr>
	                </tbody>
	            </table>
	        </div>
	        <div class="load-container" *ngIf="isLoading">
	            <div class="pin">
	                <div class="loader fa-spin"></div>
	            </div>
	        </div>
	    </div>
    </div>
    <div *ngIf="noDataFound" class="mt-10">
        <h5 class="text-center mt-4 no-data">No Data available</h5>
    </div>
</div>