<div class="container-fluid p-0" *ngIf="!isLoading">
	<div class="wrapper pt-2 pr-3 pl-3">
		<it-toolbar></it-toolbar>
	<!-- 	<it-bread-crumb></it-bread-crumb> -->
		<div class="row">
			<span *ngIf="showMap && !hideMapOnMobile" class="only-on-mobile">
				<button class="btn btn-danger btn-sm button-close-mobile-map" (click)="hideMapMobile()">
					<i class="fa fa-window-close" aria-hidden="true"></i>
					Hide Map
				</button>
			</span>
			<div [ngClass]="{'content-has-map':showMap && !isBMS,'content-no-map':!showMap}" class="col">
				<div class="row application-content"
					[style.height.px]="contentHeight">
					<div class="col-md-12  mb-1 mt-2 text-right">
						<div class="btn-group btn-group-sm ml-auto" role="group"
							aria-label="">
							<span *ngFor="let tool of toolBarButtons">
								<button type="button" class="btn btn-secondary btn-sm"
									*ngIf="tool.type != 'flap'"
									[routerLink]=" ('/app'+tool.link) | urlModifier" routerLinkActive="active"
									[routerLinkActiveOptions]="{exact:true}">
									<i [class]="'fa '+tool.icon"></i> {{tool.label}}
								</button>
								<button type="button" class="btn btn-secondary btn-sm" *ngIf="tool.type === 'flap'" (click)="openFlap(tool.link)" >
									<i [class]="'fa '+tool.icon"></i> {{tool.label}}
								</button>
							</span>
							<span *ngIf="showMap" class="only-on-mobile">
								<button class="btn btn-secondary btn-sm" (click)="showMapMobile()">
									<i class="fa fa-map" aria-hidden="true"></i>
									Show Map
								</button>
							</span>
						</div>
					</div>
					<div class="col-md-12">
						<router-outlet></router-outlet>
					</div>
				</div>
			</div>
			<div class="application-map-wrapper col pl-0 pr-0" *ngIf="showMap && !isBMS" [ngClass]="{'mobile-show':!hideMapOnMobile}">
				<it-application-map></it-application-map>
			</div>
		</div>
		<div class="left-flap animated bounceInLeft" *ngIf="leftFlap">
			<div class="flap-header clearfix">
				<button type="button"
					class="btn close-flap btn-sm btn-outline-dark bg-white-btn"
					(click)="hide()">
					<i class="fa fa-times"></i>
				</button>
			</div>
			<it-generate-view [key]="widget"></it-generate-view>
		</div>
		<div class="left-flap-shade" *ngIf="leftFlap" (click)="hide()"></div>
		<it-footer></it-footer>
	</div>
</div>
<div class="container-fluid p-0" *ngIf="isLoading">
	<div class="load-container">
		<div class="pin">
			<div class="loader fa-spin"></div>
		</div>
	</div>
</div>
<div class="acting-as" *ngIf="actingAs">
	<i class="fa fa-user-secret" aria-hidden="true"></i>
</div>
