<div class="card" *ngIf="!isLoading">
	<table class="table table-sm table-responsive" datatable>
		<thead>
			<tr>
				<th>ID</th>
				<th>Name</th>
				<th>Description</th>
				<th>Type</th>
				<th class="cw-50">Edit</th>
				<th class="cw-50">Remove</th>
			</tr>
		</thead>
		<tbody>
			<tr *ngFor="let fence of geoFenceList">
				<td>{{fence.geofenceid}}</td>
				<td>{{fence.name}}</td>
				<td class="description">{{fence.description}}</td>
				<td>{{fence.geofenceType | titlecase}}</td>
				<td><a class="btn" (click)="editFence(fence.geofenceid)"> <img
						src="assets/images/icons/edit.png" width="25" />
				</a></td>
				<td><a class="btn" (click)="removeFence(fence)"> <img
						src="assets/images/icons/garbage.png" width="25" />
				</a></td>
			</tr>
		</tbody>
	</table>
</div>
<div *ngIf="isLoading">
	<div class="load-container">
		<div class="pin">
			<div class="loader fa-spin"></div>
		</div>
	</div>
</div>